import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import { ReactComponent as ArrowNext } from "../../../assets/images/icons/arrow-circle-right.svg";
import { ReactComponent as Reset } from "../../../assets/images/icons/reset.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import HeaderListing from "../../../components/headerListing";
import { useOrderDashboardAPI } from "../../../shared/api/userOrdersDashboardSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style } from "../../listingScreens/superAdminDashboard/index";
import Pagination from "../paginationListing";
import Header from "./header";
import styles from "./index.module.scss";
import Table from "./table";
import { cursorTo } from "readline";
import FilterComponent from "./Filter";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";

const Index = () => {
  const { orderDashboardData, exportOrdersAPI } = useOrderDashboardAPI();
  const { setSelectedIndex } = useAuth();
  const [prefix, setPrefix] = useState<string>("");
  const [ordersData, setOrdersData] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [status, setStatus] = useState<any>(8);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isTablet = useMediaQuery("(max-width:992px)");
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [dateFilter, setDateFilter] = useState<any>([firstDay, new Date()]);

  const initialPayload = {
    startDate: dateFilter?.length
      ? moment(dateFilter[0]).format("YYYY-MM-DDT00:00:00.000[Z]")
      : moment(firstDay).format("YYYY-MM-DDT00:00:00.000[Z]"),
    endDate: dateFilter?.length
      ? moment(dateFilter[1]).format("YYYY-MM-DDT00:00:00.000[Z]")
      : moment().format("YYYY-MM-DDT00:00:00.000[Z]"),
    limit: 50,
    offset: page,
    status: null,
  };

  const loadInitialData = async (prefix?: any) => {
    console.log(status, "status");
    try {
      const res: any = await orderDashboardData({
        ...initialPayload,
        Prefix: prefix ? prefix : "",
        status:
          status === 0
            ? "Shipped"
            : status === 1
              ? "Canceled"
              : status === 2
                ? "Unshipped"
                : null,
      });
      if (res?.status === 200) {
        setOrdersData(res?.data.results);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (page === 0 || page || status) {
      loadInitialData();
    }
    setSelectedIndex(10);
  }, [page, dateFilter]);

  useEffect(() => {
    setPage(0);
    loadInitialData();
  }, [status]);

  const changeStatus = (status: any) => {
    setStatus(status);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      loadInitialData(prefix);
    }
  };

  const headerNames = [
    {
      name: "All Orders",
      data: ordersData.ordersCount,
      color: "#E3EAFB",
      fontSize: 18,
      fontColor: "#000",
    },
    {
      name: "Ordered Items",
      data: ordersData.totalItems,
      color: "#EFEFEF",
      fontSize: 14,
    },
    {
      name: "Returned Items",
      data: ordersData.returnedOrders,
      color: "#FFF1D4",
      fontSize: 14,
    },
    {
      name: "Fullfilled Orders",
      data: ordersData.totalFulfillments,
      color: "#C8D6F882",
      fontSize: 14,
    },
  ];
  const exportOrders = async () => {
    const payload = {
      ...initialPayload,
      Prefix: prefix ? prefix : "",
      status:
        status === 0
          ? "Shipped"
          : status === 1
            ? "Canceled"
            : status === 2
              ? "Unshipped"
              : null,
      limit: ordersData.count,
      offset: 0,
    };
    const res = await exportOrdersAPI(payload);
    if (res.status === 200) {
      var data = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });
      var csvURL = window.URL.createObjectURL(data);
      let tempLink;
      tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "OrderPayoutReports.xlsx");
      tempLink.click();
    }
  };


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <HeaderListing />

      {(isMobile || isTablet) && <FilterComponent
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={openFilter}
        loadInitialData={loadInitialData}
        setStatus={setStatus}
        changeStatus={changeStatus}
        setPage={setPage}
        status={status}
      />
      }

      <Box
        className={styles.contentMain}
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
          py: 2.5,
          px: isMobile ? 2 : isTablet ? 3 : 5,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={1.5}
          alignItems="center"
        >
          <Typography variant="h4" fontSize={20} fontWeight={500} color="#000">
            Orders
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{
                ...style.fiiledBtn,
                ...style.outlineNewBtn,
                color: "#68737D",
              }}
              onClick={() => exportOrders()}
            >
              <FileUploadOutlinedIcon style={{ marginRight: "10px" }} />
              Export
            </Button>
            <Button variant="contained" sx={{ ...style.fiiledBtn }}>
              <AddIcon style={{ marginRight: "6px" }} />
              Create Order
            </Button>
          </Box>
        </Box>

        <Header headerNames={headerNames} />

        <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", mt: 3, }}>

          <Stack flexDirection="row" alignItems="center" mb={1.5} >
            {(isMobile || isTablet) ? <Stack
              alignItems="center"
              sx={{

                flexDirection: "row",
                ml: isMobile || isTablet ? 0 : 2,
                mr: isMobile ? 1.5 : isTablet ? 2 : 0,
                px: "10px",
                py: "10px",
                border: "1px solid #E0E0E0"
              }}
              onClick={(e: any) => handleClick(e)}
            >
              <Filter
                id="basic-button"
                aria-controls={openFilter ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? "true" : undefined}

              />
            </Stack> : null}

            <Stack
              // display={isTablet? "none":"flex"}
              direction="row"
              alignItems="center"
              className={`${styles.searchInput}`}
              width={isMobile ? "100%" : "156px"}
            >
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyDown={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
                className={styles.customSearchInput}
              />
              {prefix && (
                <CloseIcon
                  onClick={async () => {
                    setPrefix("");
                    loadInitialData("");
                  }}
                />
              )}
            </Stack>
          </Stack>

          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: isMobile ? 0 : isTablet ? 2 : 2.5, px: "16px" }}
          >
            <DateRangePicker
              size="md"
              cleanable={false}
              format="MM-dd-yyyy"
              value={dateFilter}
              placeholder={["Start Date", "-", "End Date"]}
              onChange={(e) => setDateFilter(e)}
              className={styles.dateRangePicker}
              showOneCalendar={isMobile ? true : false}
            />
          </Stack>

        </Box>

        <Divider sx={{ borderColor: "#E0E0E0", opacity: 1, mt: 2.5 }}></Divider>

        <Stack direction="row">
          {!isMobile && <Box sx={{ pr: 2.5 }} display={isTablet ? "none" : "flex"}>
            {drawerOpen ? (
              <Paper elevation={0} sx={{ width: "100%", minWidth: 240 }}>
                <Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={0.5}
                  >
                    <Typography
                      variant="h6"
                      fontSize={18}
                      fontWeight={500}
                      color="#1A1918"
                    >
                      Filter
                    </Typography>
                    <Stack direction="row" spacing={1.5}>
                      <IconButton
                        aria-label="reset"
                        sx={{ width: 34, height: 34 }}
                      >
                        <Reset
                          onClick={() => {
                            setPage(0);
                            setStatus(8);
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="toggle"
                        sx={{ width: 34, height: 34 }}
                      >
                        <ArrowNext onClick={handleDrawerClose} />
                      </IconButton>
                    </Stack>
                  </Box>
                  <Divider sx={{ mt: 1, mb: 2, opacity: 1 }} />
                  <ListItemButton
                    sx={{
                      py: 0,
                      px: 1,
                      minHeight: 32,
                      justifyContent: "space-between",
                      backgroundColor:
                        status === 0 ? "rgba(0, 0, 0, 0.04)" : "",
                    }}
                    onClick={() => {
                      setPage(0);
                      changeStatus(0);
                    }}
                  >
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primary="Shipped"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    />
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    ></ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      py: 0,
                      px: 1,
                      minHeight: 32,
                      justifyContent: "space-between",
                      backgroundColor:
                        status === 1 ? "rgba(0, 0, 0, 0.04)" : "",
                    }}
                    onClick={() => {
                      setPage(0);
                      changeStatus(1);
                    }}
                  >
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primary="Canceled "
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    />
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    ></ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      py: 0,
                      px: 1,
                      minHeight: 32,
                      justifyContent: "space-between",
                      backgroundColor:
                        status === 2 ? "rgba(0, 0, 0, 0.04)" : "",
                    }}
                    onClick={() => {
                      setPage(0);
                      changeStatus(2);
                    }}
                  >
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primary="Unshipped"
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    />
                    <ListItemText
                      sx={{ flexGrow: 0 }}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    ></ListItemText>
                  </ListItemButton>
                </Box>
              </Paper>
            ) : (
              <Stack mt={0.5}>
                <IconButton aria-label="toggle">
                  <ArrowNext onClick={handleDrawerOpen} />
                </IconButton>
                <Divider sx={{ mt: 1, mb: 2, opacity: 1 }} />
              </Stack>
            )}
          </Box>}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#E0E0E0", opacity: 1, display: isMobile || isTablet ? "none" : "flex" }}
          ></Divider>

          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#fff",
              pt: 1,
              pl: isTablet || isMobile ? 0 : 5,
              overflowX: isMobile ? "hidden" : "auto",
            }}
          >
            <Box>
              <Stack
                mb={1.5}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                ></Typography>
                <Pagination
                  page={page}
                  totalCount={ordersData.count ? ordersData.count : 0}
                  setPage={setPage}
                />
              </Stack>
              <Table page={page} data={ordersData.orderPayoutResponseDto} />
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
export default Index;
