import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as ReloadHeader } from "../../../../assets/images/icons/reloadHeader.svg";
import { ReactComponent as ThreeDot } from "../../../../assets/images/icons/threeDot.svg";
import CustomBreadcrumbs from "../../../../components/customBreadcrumbs";
import { useAnalyticsDashboardSAAS } from "../../../../shared/api/useAnalyticsDashboardSAAS";
import DetailedReportTable from "../../PayoutReports/PayoutReportTable/DetailedReportTable";
import Pagination from "../../paginationListing";
import UnitPerOrderChart from "../charts/UnitPerOrder/UnitPerOrderChart";

const UnitPerOrderDetailed = () => {
  const [page, setPage] = useState<any>(0);
  const [data, setData] = useState<any>();
  console.log(data, ",,,,,,,,,,,,,");
  const { getTotalSalesData } = useAnalyticsDashboardSAAS();

  const getData = async () => {
    const res = await getTotalSalesData();
    setData(res);
  };
  useEffect(() => {
    getData();
  }, []);

  const salesReturnsTableColumns = [
    { header: "Date", apiKey: "date" },
    { header: "Orders", apiKey: "orders" },
    { header: "Gross", apiKey: "gross", symbol: "$" },
    { header: "Net", apiKey: "net", symbol: "$" },
    { header: "Discount", apiKey: "discount", symbol: "$" },
    { header: "Shipping", apiKey: "shipping", symbol: "$" },
    { header: "Tsx ($)", apiKey: "tax", symbol: "$" },
    { header: "Total ($)", apiKey: "total", symbol: "$" },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#fff",
      }}
    >
      <Stack
        px={1.5}
        py={2}
        pr={5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: "#FAFBFC",
          borderBottom: "1px solid #E0E0E0",
          height: 44,
        }}
      >
        <CustomBreadcrumbs
          firstName={"Sales Report"}
          firstFunction={-1}
        />
        <Stack direction="row" spacing={1}>
          <IconButton
            aria-label="reloadHeader"
            sx={{
              width: 34,
              height: 34,
            }}
          >
            <ReloadHeader />
          </IconButton>
          <IconButton aria-label="threeDot" sx={{ width: 34, height: 34 }}>
            <ThreeDot />
          </IconButton>
        </Stack>
      </Stack>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
          py: 2.5,
          px: 5,
          mt: 0,
        }}
      >
        <Stack>
          <Typography
            variant="body1"
            fontSize={20}
            fontWeight={500}
            color="#000"
            mb={3.5}
            // mt = {isMobile ? "30px":"0px"}
          >
            Unit Per Order
          </Typography>
        </Stack>
        <Stack
          sx={{
            height: 400,
            border: "1px solid #E0E0E0",
            px: 2,
             pt: 3,
             pb:10,
            borderRadius: 0.5,
            mb: 5,  
          
                        
          }}
        >
          
            <Typography
            variant="body1"
            fontSize={20}
            fontWeight={500}
            color="#1A1918" 
          >
            $123,881.96
           
          </Typography>
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={400}
            color="#68737D" 
          >
            Avg. Weekly sales (Jun  13, 2022 - July 13, 2022) = $5,4086.56
           
          </Typography>
          <UnitPerOrderChart />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1.5}
        >
          <Typography
            variant="body2"
            fontSize={18}
            fontWeight={500}
            color="#1E1F21"
          >
            Report
          </Typography>
          <Pagination page={page} totalCount={0} setPage={setPage} />
        </Stack>

        <Stack>
          <DetailedReportTable
            page={page}
            data={data}
            columns={salesReturnsTableColumns}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default UnitPerOrderDetailed;
