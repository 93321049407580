import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Field, FormikProvider } from "formik";
import { style } from ".";


const Index = (props: any) => {
    const { formik, show, modalTitle, setShow, userData, putRejectUserRequest, setType } = props;

    return (<>
        <Dialog
            open={show}
            onClose={() => { formik.resetForm(); setShow(false) }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
        >
            <DialogTitle sx={{ m: 0, p: 2.5, fontSize: 18, fontWeight: 300 }}>
                <Typography variant="body2" fontWeight={300} fontSize={14}>
                    {modalTitle}{" "}
                    <span style={{ fontWeight: "500" }}>
                        {userData?.firstName}{" "}
                    </span>
                    Registartion Request from {userData.company}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => { formik.resetForm(); setShow(false) }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
                <FormikProvider value={formik}>
                    <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputLabel>DCID*</InputLabel>
                                <Field name="dcid">
                                    {({ field, meta, form }: any) => (
                                        <>
                                            <TextField
                                                {...field}
                                                id="standard-required"
                                                autoComplete="off"
                                                variant="standard"
                                                type="number"
                                                fullWidth
                                                placeholder="Enter DCID"
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={
                                                    meta.touched && meta.error && meta.error
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputLabel>DCID Code</InputLabel>
                                <Field name="classificationCode">
                                    {({ field, meta, form }: any) => (
                                        <>
                                            <TextField
                                                {...field}
                                                id="standard-required"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                placeholder="Enter DCID Code"
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={
                                                    meta.touched && meta.error && meta.error
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                    <InputLabel required>Resale Fee</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={formik.values.resaleFee}
                                        fullWidth
                                        onChange={(e) => { setType(e.target.value); formik.setFieldValue("resaleFee", e.target.value) }}
                                        variant="standard"
                                        sx={{
                                            'MuiSelect-select': {
                                                '&.MuiInputBase-input': {
                                                    '&.MuiInput-input:focus': {
                                                        backgroundColor: '#fff',
                                                    }
                                                }

                                            }
                                        }}
                                    >
                                        <MenuItem value={0}>Direct Percentage Share</MenuItem>
                                        <MenuItem value={1}>Fixed Dollar Amount</MenuItem>
                                        <MenuItem value={2}>Percentage of GMV</MenuItem>
                                    </Select>
                                    <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formik.errors.resaleFee} </FormHelperText>
                                </Box>
                            </Grid>

                            {formik.values.resaleFee === 2 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Marketplace Fee</InputLabel>
                                            <Field name="marketPlaceFee">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            placeholder="Enter Marketplace Fee"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Fixed Dollar Amount</InputLabel>
                                            <Field name="fixedFee">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            placeholder="Enter Fixed Dollar Amount"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel>Percentage of GMV*</InputLabel>
                                            <Field name="percentage">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            placeholder="Enter Percentage of GMV"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel>Higher Value (will be used)*</InputLabel>
                                        <Switch checked={formik.values.isHigher}
                                            onChange={() => formik.setFieldValue("isHigher", !formik.values.isHigher)}
                                        />
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee === 1 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel>Direct Percentage Share*</InputLabel>
                                            <Field name="directPercentageShare">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            placeholder="Enter Direct Percentage Share"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee === 0 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Direct Percentage Share</InputLabel>
                                            <Field name="directPercentageShare">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            placeholder="Enter Direct Percentage Share"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee !== "" &&
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Share In NetProceeds</InputLabel>
                                            <Field name="revShareDropShipper">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            fullWidth
                                                            placeholder="Enter Share In NetProceeds"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Payroll Period</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={formik.values.payoutReport}
                                                placeholder="Select Payroll Period"
                                                fullWidth
                                                onChange={(e) => formik.setFieldValue("payoutReport", e.target.value)}
                                                variant="standard"
                                            >
                                                <MenuItem value={0}>Weekly</MenuItem>
                                                <MenuItem value={1}>Bi-Weekly</MenuItem>
                                                <MenuItem value={2}>Monthly</MenuItem>
                                                <MenuItem value={3}>Bi-Monthly</MenuItem>
                                            </Select>
                                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formik.errors.payoutReport} </FormHelperText>
                                        </Box>
                                    </Grid>
                                </>}
                        </Grid>

                    </Box>
                </FormikProvider>
            </DialogContent>

            <DialogActions
                sx={{
                    justifyContent: "center",
                    pb: 3,
                    "&>:not(:first-of-type)": { ml: 2.5 },
                }}
            >
                <Button
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                    sx={{
                        ...style.modalBtn,
                        background:
                            "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                        color: "#fff",
                    }}
                >
                    Approve
                </Button>
                <Button
                    sx={{
                        ...style.modalBtn,
                        ml: 2,
                        border: "1px solid rgba(104, 115, 125, 0.48)",
                        color: "#68737D",
                    }}
                    onClick={() => {
                        putRejectUserRequest(userData?.id);
                    }}
                >
                    Reject
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}
export default Index;