import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/provider/authprovider";

//my imports
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as Close } from "../../../src/assets/images/icons/cross.svg";
import {
  Avatar,
  Button,
  CSSObject,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  createTheme,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as Logo } from "../../../src/assets/images/big-logo-icon.svg";
import { ReactComponent as Dashboard } from "../../../src/assets/images/icons/dashboard.svg";
import { ReactComponent as Analytics } from "../../../src/assets/images/icons/analytics.svg";
import { ReactComponent as Sales } from "../../../src/assets/images/icons/sales.svg";
import { ReactComponent as Finance } from "../../../src/assets/images/icons/finance.svg";
import { ReactComponent as Integration } from "../../../src/assets/images/icons/integration.svg";
import { ReactComponent as Bell } from "../../assets/images/icons/bell.svg";
import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as Settings } from "../../assets/images/icons/settings.svg";
import { ReactComponent as Hamburger } from "../../assets/images/icons/hamburger.svg";
import { ReactComponent as Order } from "../../assets/images/icons/my-orders-icon.svg";
import { ReactComponent as FinancePayout } from "../../assets/images/icons/finance-2.svg";
import { ReactComponent as Form } from "../../assets/images/icons/tdesign_form.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./index.module.scss";
import DetailScreenComponent from "../../screens/detailScreenComponent";

export const style = {
  sidebarDrawer: {
    ".MuiDrawer-paper": {
      overflow: "unset",
      height: "calc(100vh - 50px)",
      marginTop: "50px",
      borderTop: "1px solid #dcdcdc",
      ".MuiListItem-root": {
        ".MuiButtonBase-root": {
          height: 44,
          borderLeft: "5px solid transparent",
          "&:hover, &.Mui-selected": {
            backgroundColor: "#C8D6F8",
            borderLeft: "5px solid #4079FC",
          },
          ".MuiTypography-root": {
            fontSize: 14,
            fontWeight: 500,
          },
        },
        ".MuiCollapse-root": {
          ".MuiCollapse-wrapper": {
            ".MuiListItemButton-root": {
              pl: 6,
              ".MuiListItemIcon-root": {
                minWidth: 0,
                mr: 2,
              },
              "&:hover": {
                backgroundColor: "#E5EBFA",
                borderLeftColor: "transparent",
              },
            },
          },
        },
      },
    },
  },

  drawerHeader: {
    position: "absolute",
    top: "10px",
    zIndex: 999,
    minHeight: "24px !important",
    p: 0,
  },
  rightIconBtn: {
    left: "50px",
  },
  topTabs: {
    ".MuiTabs-indicator": {
      display: "none",
    },
  },
};
// const drawerWidth = 60;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HeaderListing = (props: any) => {
  const {
    signout,
    firstName,
    lastName,
    selectedIndex,
    setSelectedIndex,
    tabsSubData,
    setTabsSubData,
    value,
    setValue,
    setIntegrationTabIndex,
    tabsAdded,
    setTabsAdded,
  } = useAuth();
  const roles = localStorage.getItem("role");

  let navigate = useNavigate();
  const logout = () => {
    setTabsSubData([]);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/#/listing-login";
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const hanldeCLoseTabs = () => {
    setTabsAdded(false);
  };

  // profile
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //drawer function
  const drawerWidth = 240;
  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 5px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7)} + 5px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const theme = useTheme();
  //drawer state
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down(769));
  const isTablet = useMediaQuery("(max-width:992px)");

  const handleHideDrawerCustom = () => {
    setOpenDrawer(false);
  };
  const handleShowDrawerCustom = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    openDrawer == true ? handleHideDrawerCustom() : handleShowDrawerCustom();
    setOpenIntegration(false);
    setAnalyticsOpen(false);
  };
  const matches = useMediaQuery("(min-width:600px)");
  const [openIntegration, setOpenIntegration] = React.useState(false);
  const handleClickIntegration = () => {
    setOpenIntegration(!openIntegration);
  };
  const [openFinance, setOpenFinance] = React.useState(false);
  const handleClickFinance = () => {
    setOpenFinance(!openFinance);
    setOpenDrawer(true);
  };
  //mobile
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [analyticsOpen, setAnalyticsOpen] = React.useState(false);
  const handleClickAnalytics = () => {
    setAnalyticsOpen(!analyticsOpen);
    setOpenDrawer(true);
  };
  const toggleDrawer =
    (anchor: any, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };
  const headerName = (ele: any) => {
    if (ele.key === "PayoutReport") {
      return (
        <Stack alignItems="flex-start">
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={500}
            textTransform="capitalize"
          >
            {ele.title}
          </Typography>
          <Typography
            variant="body2"
            fontSize={14}
            fontWeight={300}
            textTransform="capitalize"
          >
            Payout Report
          </Typography>
        </Stack>
      );
    } else if (ele.key === "SalesDashboard") {
      return (
        <Typography variant="body2" fontSize={14} fontWeight={500}>
          {ele.title}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" fontSize={14} fontWeight={500}>
          SKU {ele.inventoryNumber}
        </Typography>
      );
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTabsAdded(true);
  };
  const removePanels = (item: any) => {
    setTabsSubData(tabsSubData.filter((ele, index) => index !== item));
    if (item && item <= value) {
      setValue(value - 1);
    } else {
      setValue(item);
    }
  };

  window.onhashchange = function (e) {
    if (tabsAdded) {
      window.history.go(1);
      hanldeCLoseTabs();
    }
  };

  const handleClickLogo = () => {
    hanldeCLoseTabs();
    if (roles == "SuperAdmin") {
      navigate("/listing-saas-main-dashboard");
    } else {
      navigate("/listing-customer-dashboard");
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <div className={styles.sidebarNav}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed">
              <Toolbar sx={{ justifyContent: "space-between" }}>
                <Stack flexDirection="row" alignItems="center">
                  <Box
                    sx={{
                      width: 36,
                      textAlign: "left",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    className={`${styles.logoDiv} ${styles.pointer}`}
                  >
                    <Logo
                      className={styles.resaleLogo}
                      onClick={() => handleClickLogo()}
                    />
                  </Box>
                  <IconButton
                    aria-label="hamburger"
                    onClick={toggleDrawer("left", true)}
                    sx={{ display: isTablet || isMobile ? "flex" : "none" }}

                  >
                    <Hamburger />
                  </IconButton>

                  <Box sx={{ ...style.topTabs }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      sx={{
                        minHeight: 50,
                        "& .MuiButtonBase-root": {
                          borderRight: "1px solid #E0E0E0",
                          px: 1.5,
                          fontSize: 14,
                          fontWeight: 500,
                          minHeight: 50,
                          "&.Mui-selected": {
                            color: "#1A1918",
                            backgroundColor: "#FAFBFC",
                          },
                        },
                      }}
                    >
                      {tabsSubData?.map((ele: any, index: any) => (
                        <Tab
                          sx={{ p: 0 }}
                          onClick={() => setTabsAdded(true)}
                          label={
                            <Stack
                              flexDirection="row"
                              gap={3}
                              alignItems="center"
                            >
                              {headerName(ele)}
                              <span onClick={(e) => e.stopPropagation()}>
                                <Close onClick={() => removePanels(index)} />
                              </span>
                            </Stack>
                          }
                          {...a11yProps(index)}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Stack>

                <Box sx={{ flexGrow: 0 }}>
                  <IconButton aria-label="search">
                    <Search />
                  </IconButton>
                  <IconButton aria-label="settings" sx={{ ml: 1.5 }}>
                    <Settings />
                  </IconButton>
                  <IconButton aria-label="notifications" sx={{ ml: 1.5 }}>
                    <Bell />
                  </IconButton>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, ml: 1.5 }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/2.jpg"
                      sx={{
                        height: 24,
                        width: 24,
                        fontSize: 12,
                        backgroundColor: "#0178D7",
                      }}
                    />
                  </IconButton>
                  {/* <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="Manage Account">
                    <Typography
                      textAlign="center"
                      onClick={() => navigate('/account-management')}>
                      Manage Account
                    </Typography>
                  </MenuItem>
                  <MenuItem key="Admin Console">
                    <Typography
                      textAlign="center"
                    >
                      Admin Console
                    </Typography>
                  </MenuItem>
                  <MenuItem key="Sign out">
                    <Typography
                      textAlign="center"
                      onClick={() => { logout() }}
                    >
                      Sign out
                    </Typography>
                  </MenuItem>
                </Menu> */}

                  <Menu
                    anchorEl={anchorElUser}
                    id="account-menu"
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    // onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: "34px",
                        left: "unset !important",
                        right: 16,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>
                      <Avatar /> Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      key="Manage Account"
                      onClick={() => navigate("/account-management")}
                    >
                      <Typography textAlign="center">Manage Account</Typography>
                    </MenuItem>
                    <MenuItem key="Admin Console">
                      <Typography textAlign="center">Admin Console</Typography>
                    </MenuItem>
                    <MenuItem
                      key="Sign out"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <Typography textAlign="center">Sign out</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>

            <DrawerDesktop
              variant="permanent"
              open={openDrawer}
              sx={{
                ...style.sidebarDrawer,
                position: "relative",
                display: isTablet ? "none" : isMobile ? "none" : "flex",
                transition: " 0.3s ease-in",
              }}
              onMouseLeave={() => setOpenDrawer(false)}
              onMouseOver={() => setOpenDrawer(true)}
            >
              {openDrawer == true ? (
                <DrawerHeader sx={{ ...style.drawerHeader, left: "230px" }}>
                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      height: 20,
                      width: 20,
                      border: "1px solid #4079FC",
                      background: "#4079FC",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#4079FC",
                        border: "1px solid #4079FC",
                      },
                    }}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </DrawerHeader>
              ) : (
                <DrawerHeader
                  sx={{ ...style.drawerHeader, ...style.rightIconBtn }}
                >
                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      height: 20,
                      width: 20,
                      border: "1px solid #AFB0B1",
                      background: "#fff",
                      color: "#AFB0B1",
                      "&:hover": {
                        backgroundColor: "#4079FC",
                        border: "1px solid #4079FC",
                        color: "#fff",
                      },
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </DrawerHeader>
              )}

              {/* <Toolbar /> */}

              {roles === "SuperAdmin" ? (
                <List sx={{ p: 0 }}>
                  {/* dashboard */}
                  <ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        navigate("/listing-saas-main-dashboard");
                        handleListItemClick(event, 0);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Dashboard className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Dashboard
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key={"payoutReport"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 11}
                      onClick={(event) => {
                        handleListItemClick(event, 11);
                        setSelectedIndex(11);
                        handleClickFinance();
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <FinancePayout className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                          ".MuiTypography-root": {
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        Finance
                        {openFinance ? <ExpandLess /> : <ExpandMore />}
                      </ListItemText>
                      {/* {openDrawer ? (openIntegration ? <ExpandLess /> : <ExpandMore />) : null} */}
                    </ListItemButton>
                    <Collapse in={openFinance} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 11}
                          onClick={(event) => {
                            navigate("/payout-reports");
                            handleListItemClick(event, 11);
                            setSelectedIndex(6);
                            if (tabsAdded) {
                              hanldeCLoseTabs();
                            }
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Sales />
                        </ListItemIcon> */}
                          <ListItemText>Payout</ListItemText>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                </List>
              ) : null}

              {roles === "Customer" ? (
                <List sx={{ p: 0 }}>
                  {/* User Dashboard */}
                  <ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        navigate("/listing-customer-dashboard");
                        handleListItemClick(event, 0);
                        setSelectedIndex(0);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Dashboard className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Dashboard
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key={"sales"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={
                        selectedIndex === 3 ||
                        selectedIndex === 4 ||
                        selectedIndex == 11
                      }
                      onClick={() => handleClickAnalytics()}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    // onClick={handleClick}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Analytics className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                          ".MuiTypography-root": {
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        Analytics
                        {analyticsOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemText>
                    </ListItemButton>
                    <Collapse in={analyticsOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 3}
                          onClick={(event) => {
                            navigate("/listing-sales");
                            handleListItemClick(event, 3);
                            setSelectedIndex(3);
                            if (tabsAdded) {
                              hanldeCLoseTabs();
                            }
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Sales />
                        </ListItemIcon> */}
                          <ListItemText>Sales</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 4}
                          onClick={(event) => {
                            navigate("/finance-dashboard");
                            handleListItemClick(event, 4);
                            setSelectedIndex(4);
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Finance />
                        </ListItemIcon> */}
                          <ListItemText>Finance</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 11}
                          onClick={(event) => {
                            navigate("/client/payout-report");
                            handleListItemClick(event, 11);
                            setSelectedIndex(11);
                            if (tabsAdded) {
                              hanldeCLoseTabs();
                            }
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Sales />
                        </ListItemIcon> */}
                          <ListItemText>Payout Reports</ListItemText>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                  {/* integration */}
                  <ListItem
                    key={"integration"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={
                        selectedIndex === 5 ||
                        selectedIndex === 6 ||
                        selectedIndex === 7 ||
                        selectedIndex === 8
                      }
                      onClick={(event) => {
                        navigate("/integration-overview");
                        handleListItemClick(event, 5);
                        setSelectedIndex(5);
                        setOpenDrawer(true);
                        // handleClickIntegration()
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Integration className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                          ".MuiTypography-root": {
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        Integration
                        <span onClick={(e) => e.stopPropagation()}>
                          {openDrawer ? (
                            openIntegration ? (
                              <ExpandLess
                                onClick={() => handleClickIntegration()}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => handleClickIntegration()}
                              />
                            )
                          ) : null}
                        </span>
                      </ListItemText>
                      {/* {openDrawer ? (openIntegration ? <ExpandLess /> : <ExpandMore />) : null} */}
                    </ListItemButton>
                    <Collapse in={openIntegration} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 6}
                          onClick={(event) => {
                            navigate("/source-dashboard");
                            handleListItemClick(event, 6);
                            setSelectedIndex(6);
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Sales />
                        </ListItemIcon> */}
                          <ListItemText>Source</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 7}
                          onClick={(event) => {
                            navigate("/destination-dashboard");
                            handleListItemClick(event, 7);
                            setSelectedIndex(7);
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Finance />
                        </ListItemIcon> */}
                          <ListItemText>Destination</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 8}
                          onClick={(event) => {
                            navigate("/listing-integration");
                            handleListItemClick(event, 8);
                            setSelectedIndex(8);
                            setIntegrationTabIndex("1");
                          }}
                          sx={{
                            pl: 4,
                            opacity: openDrawer ? 1 : 0,
                            ml: 0,
                            flex: openDrawer ? 1 : 0,
                            my: 0,
                          }}
                        >
                          {/* <ListItemIcon>
                          <Finance />
                        </ListItemIcon> */}
                          <ListItemText>Catalog</ListItemText>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                  <ListItem
                    key={"order"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 10}
                      onClick={(event) => {
                        navigate("/order");
                        handleListItemClick(event, 10);
                        setSelectedIndex(10);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Order className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Order
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key={"SkuForm"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 15}
                      onClick={(event) => {
                        navigate("/sku-form");
                        handleListItemClick(event, 15);
                        setSelectedIndex(15);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Form className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          ml: openDrawer ? 2 : 0,
                          flex: openDrawer ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Sku Form
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              ) : null}
            </DrawerDesktop>

            {/* mobile drawer */}
            <Drawer
              anchor="left"
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
              PaperProps={{
                sx: { width: 250 },
              }}
            >
              {roles === "SuperAdmin" ? (
                <List sx={{ p: 0, mt: "50px" }}>
                  {/* dashboard */}
                  <ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        navigate("/listing-saas-main-dashboard");
                        handleListItemClick(event, 0);
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Dashboard className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          // opacity: open ? 1 : 0,
                          ml: 2,
                          // flex: open ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Dashboard
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              ) : null}
              {roles === "Customer" ? (
                <List sx={{ p: 0, mt: "50px" }}>
                  {/* User Dashboard */}
                  <ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        navigate("/listing-customer-dashboard");
                        handleListItemClick(event, 0);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Dashboard className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          // opacity: open ? 1 : 0,
                          ml: 2,
                          // flex: open ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Dashboard
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key={"sales"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 3 || selectedIndex === 4}
                      onClick={() => handleClickAnalytics()}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    // onClick={handleClick}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Analytics className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          // opacity: open ? 1 : 0,
                          ml: 2,
                          // flex: open ? 1 : 0,
                          my: 0,
                          ".MuiTypography-root": {
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        Analytics
                        {analyticsOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemText>
                    </ListItemButton>
                    <Collapse in={analyticsOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 3}
                          onClick={(event) => {
                            navigate("/listing-sales");
                            handleListItemClick(event, 3);
                            setSelectedIndex(3);
                          }}
                          sx={{
                            pl: 4,
                            // opacity: open ? 1 : 0,
                            ml: 0,
                            // flex: open ? 1 : 0,
                            my: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 2,
                            }}
                          >
                            <Sales />
                          </ListItemIcon>
                          <ListItemText>Sales</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 4}
                          onClick={(event) => {
                            navigate("/finance-dashboard");
                            handleListItemClick(event, 4);
                            setSelectedIndex(4);
                          }}
                          sx={{
                            pl: 4,
                            // opacity: open ? 1 : 0,
                            ml: 0,
                            // flex: open ? 1 : 0,
                            my: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 2,
                            }}
                          >
                            <Finance />
                          </ListItemIcon>
                          <ListItemText>Finance</ListItemText>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                  {/* integration */}
                  <ListItem
                    key={"integration"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={
                        selectedIndex === 5 ||
                        selectedIndex === 6 ||
                        selectedIndex === 7 ||
                        selectedIndex === 8
                      }
                      onClick={(event) => {
                        navigate("/integration-overview");
                        handleListItemClick(event, 5);
                        setSelectedIndex(5);
                        // handleClickIntegration()
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Integration className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          // opacity: open ? 1 : 0,
                          ml: 2,
                          // flex: open ? 1 : 0,
                          my: 0,
                          ".MuiTypography-root": {
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        Integration
                        <span onClick={(e) => e.stopPropagation()}>
                          {openIntegration ? (
                            <ExpandLess
                              onClick={() => handleClickIntegration()}
                            />
                          ) : (
                            <ExpandMore
                              onClick={() => handleClickIntegration()}
                            />
                          )}
                        </span>
                      </ListItemText>
                      {/* {openDrawer ? (openIntegration ? <ExpandLess /> : <ExpandMore />) : null} */}
                    </ListItemButton>
                    <Collapse in={openIntegration} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 6}
                          onClick={(event) => {
                            navigate("/source-dashboard");
                            handleListItemClick(event, 6);
                            setSelectedIndex(6);
                          }}
                          sx={{
                            pl: 4,
                            // opacity: open ? 1 : 0,
                            ml: 0,
                            // flex: open ? 1 : 0,
                            my: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 2,
                            }}
                          >
                            <Sales />
                          </ListItemIcon>
                          <ListItemText>Source</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 7}
                          onClick={(event) => {
                            navigate("/destination-dashboard");
                            handleListItemClick(event, 7);
                            setSelectedIndex(7);
                          }}
                          sx={{
                            pl: 4,
                            // opacity: open ? 1 : 0,
                            ml: 0,
                            // flex: open ? 1 : 0,
                            my: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 2,
                            }}
                          >
                            <Finance />
                          </ListItemIcon>
                          <ListItemText>Destination</ListItemText>
                        </ListItemButton>
                        <ListItemButton
                          selected={selectedIndex === 8}
                          onClick={(event) => {
                            navigate("/listing-integration");
                            handleListItemClick(event, 8);
                            setSelectedIndex(8);
                            setIntegrationTabIndex("1");
                          }}
                          sx={{
                            pl: 4,
                            // opacity: open ? 1 : 0,
                            ml: 0,
                            // flex: open ? 1 : 0,
                            my: 0,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 2,
                            }}
                          >
                            <Finance />
                          </ListItemIcon>
                          <ListItemText>Catalog</ListItemText>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                  {/* Order */}
                  <ListItem
                    key={"order"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 10}
                      onClick={(event) => {
                        navigate("/order");
                        handleListItemClick(event, 10);
                        if (tabsAdded) {
                          hanldeCLoseTabs();
                        }
                      }}
                      sx={{ justifyContent: openDrawer ? "start" : "center" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Order className={styles.hoverColor} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          // opacity: open ? 1 : 0,
                          ml: 2,
                          // flex: open ? 1 : 0,
                          my: 0,
                        }}
                      >
                        Order
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              ) : null}
            </Drawer>
          </Box>
        </div>
      </Box>
      {tabsSubData.length && tabsAdded ? (
        <>
          <DetailScreenComponent />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderListing;
