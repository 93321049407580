import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import { ReactComponent as Active } from "../../../assets/images/icons/active.svg";
import { ReactComponent as Await } from "../../../assets/images/icons/await.svg";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as Due } from "../../../assets/images/icons/due.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import { ReactComponent as Visibility } from "../../../assets/images/icons/open-eye.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style, style as styless } from "../../listingScreens/superAdminDashboard/index";
import FilterComponent from "../Filter";
import Pagination from "../paginationListing";
import EditAndViewUserModal from "./editAndViewUserModal";
import styles from "./index.module.scss";

export const tableStyles = {
  tableData: {
    fontSize: 14,
    fontWeight: 300,
  },
};

const AproovedUserManagement = (props: any) => {
  const { fetchLatestCount } = props;
  const { isLoading, setOpen, setMessageText } = useAuth();
  const { getListingUserList, deleteListingUser } = useClientAPI();
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [listingUserCount, setListingUserCount] = useState<any>([]);
  const [listingUserList, setListingUserList] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [valuetoDelete, setValuetoDelete] = useState<any>("");
  const [data, setData] = useState<any>(null);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [prefix, setPrefix] = useState("");
  const [filterCheckBox, setfilterCheckBox] = useState<any>([true, false]);

  const initialPayload = {
    fromDate: dateFilter?.length ? moment(dateFilter[0]).format("MM-DD-YYYY") : null,
    toDate: dateFilter?.length ? moment(dateFilter[1]).format("MM-DD-YYYY") : null,
    limit: limit,
    isActivate: filterCheckBox,
    offset: page,
    userApprovalStatusList: [1],
    prefix: prefix,
  };

  useEffect(() => {
    loadInitialData()
  }, [filterCheckBox]);

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      setPrefix(e.target.value)
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    const resultUser = await getListingUserList(
      initialPayload
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
    setPage(0);
  };

  const loadInitialData = async () => {
    const resultUser = await getListingUserList(
      initialPayload
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
  };
  const setDeleteFun = async (value: any) => {
    setDeleteModal(true);
    setValuetoDelete(value);
  };
  const handleDeleteUser = async () => {
    const result = await deleteListingUser(valuetoDelete);
    if (result?.data?.status == 200) {
      setDeleteModal(false);
      const resultUser = await getListingUserList(initialPayload);
      await fetchLatestCount();
      setListingUserCount(resultUser?.data?.result?.count);
      setListingUserList(resultUser?.data?.result);
      setMessageText("User Deleted Successfully");
      setOpen(true);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadInitialData();
  }, [dateFilter, page]);
  return (
    <>
      {show && (
        <EditAndViewUserModal
          show={show}
          setShow={setShow}
          userData={data}
          modalTitle={modalTitle}
          isView={isView}
          fetchListData={loadInitialData}
        />
      )}

      <FilterComponent
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={openFilter}
        items={filterCheckBox}
        setItems={setfilterCheckBox}
        loadInitialData={loadInitialData}
      />

      <Stack direction="row" justifyContent="space-between" mb={1.5}>
        <Stack direction="row">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          </div>

          {/* {/ new code /} */}
          <Stack
            alignItems="center"
            sx={{
              ...style.borderStack,
              flexDirection: "row",
              ml: 2,
              px: "10px",
            }}
            onClick={(e: any) => handleClick(e)}
          >
            <Filter
              id="basic-button"
              aria-controls={openFilter ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openFilter ? "true" : undefined}
              style={{ marginRight: "10px" }}
            />
            {/* <span className={styles.numberWithBg}>4</span> */}
          </Stack>
          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...styless.borderStack, ml: 2, px: "10px" }}
          >
            <DateRangePicker
              size="md"
              cleanable={false}
              format="MM-dd-yyyy"
              value={dateFilter}
              placeholder={["Start Date", "-", "End Date"]}
              onChange={(e) => setDateFilter(e)}
              className={styles.dateRangePicker}
            />
          </Stack>
        </Stack>

        <Pagination
          page={page}
          totalCount={
            listingUserCount ? listingUserCount : 0
          }
          setPage={setPage}
        />

        {/* new code end*/}
      </Stack>
      <div className={styles.heightG}>
        <table className={styles.tableH}>
          <thead className={`${styles.thead} rounded`}>
            <tr>
              <td>Sr. No.</td>
              <td>Name</td>
              <td>Company</td>
              <td>Email</td>
              <td>Phone</td>
              <td>Acc. Status</td>
              <td>Subscription Type</td>
              <td>Subscription Status</td>
              <td className={styles.actionCol}>Action</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!isLoading ? (
              <>
                {
                  listingUserList &&
                  listingUserList?.items?.map((value: any, index: any) => {
                    return (
                      <tr>
                        <td>{(page) + (index + 1)}</td>
                        <td>{value?.firstName + " " + value?.lastName}</td>
                        <td>{value?.company}</td>
                        <td>{value?.email}</td>
                        <td>{value?.mobileNumber}</td>
                        <td>
                          {value?.isActivate == true ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Active />
                              <Typography>Active</Typography>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Due />
                              <Typography>Deactive</Typography>
                            </Stack>
                          )}
                        </td>
                        <td>SubscriptionType</td>
                        <td>
                          {value?.isActivate == true ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Active />
                              <Typography>Subscribed</Typography>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Await />
                              <Typography>Awaiting Payment</Typography>
                            </Stack>
                          )}
                        </td>
                        <td>
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              aria-label="edit"
                              sx={{ height: 34, width: 34 }}
                              onClick={() => {
                                setIsView(false);
                                setModalTitle("Edit");
                                setData(value);
                                setShow(true);
                              }}
                            >
                              <Edit className={styles.tBtn} />
                            </IconButton>

                            <IconButton
                              aria-label="view"
                              sx={{ height: 34, width: 34 }}
                              onClick={() => {
                                setIsView(true);
                                setModalTitle("View");
                                setData(value);
                                setShow(true);
                              }}
                            >
                              <Visibility className={styles.tBtn} />
                            </IconButton>

                            <IconButton
                              aria-label="delete"
                              onClick={async () => {
                                setDeleteFun(value?.id);
                              }}
                            >
                              <Delete className={styles.tBtn} />
                            </IconButton>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                }
              </>
            ) : (
              ""
            )}
          </tbody>

          {deleteModal && <Dialog
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
              ...style.dialogContainer,
              ".MuiPaper-root": { minWidth: 400 },
            }}
          >
            <DialogTitle
              sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
            >
              Delete User
              <IconButton
                aria-label="close"
                onClick={() => setDeleteModal(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
              <Box sx={{ borderRadius: 0.5, p: 1.5 }}>
                <Typography variant="h6">Are you sure to delete ?</Typography>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                pb: 3,
                "&>:not(:first-of-type)": { ml: 2.5 },
              }}
            >
              <Button
                onClick={() => {
                  handleDeleteUser();
                }}
                sx={{
                  ...style.modalBtn,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                  color: "#fff",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                sx={{
                  ...style.modalBtn,
                  ml: 2,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>}
        </table>
      </div>
    </>
  );
};
export default AproovedUserManagement;
