import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputLabel, TextField, Typography } from "@mui/material"
import moment from "moment"
import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import Loader from "../../../loader";
import { useState } from "react";
import { style } from "../superAdminDashboard/index";
import CloseIcon from "@mui/icons-material/Close";

const ManageSubscriptionUser = (props: any) => {
    const { isLoading } = useAuth()
    const [showCancelSubscription, setShowCancelSubscription] = useState<any>(false)
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <>
            <Typography
                variant="subtitle1"
                fontWeight={300}
                fontSize={16}
                sx={{ mt: 0.5 }}
            >
                Your Account is billed monthly and the next payment is due on {moment(new Date(props?.subscriptionList[0]?.subscriptionEndDate), "en-us").format("MMMM DD, YYYY")}.
            </Typography>
            <div className={styles.heightG}>
                <table className={styles.tableH}>
                    <thead className={`${styles.thead} rounded`}>
                        <tr>
                            <td>Subscription</td>
                            <td>Details</td>
                            <td>Monthly Cost</td>
                            <td>Updated</td>
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {/* {JSON.stringify(props.subscriptionList)} */}
                        {!isLoading ? (
                            <>
                                {
                                    props?.subscriptionList?.map((value: any) => {

                                        return (
                                            <tr>
                                                <td>{value?.subscriptionName}</td>
                                                <td>{value?.description}</td>

                                                <td>{formatter.format(value?.price)}</td>
                                                <td>{moment(new Date(value?.updateTime), "en-us").format("MM-DD-YYYY")}</td>
                                            </tr>
                                        )
                                    })

                                }
                            </>
                        ) : (
                            // <Loader />
                            ""
                        )}
                    </tbody>


                </table>
                <button onClick={() => setShowCancelSubscription(true)}>Cancel Subscription</button>
                <Dialog
                    open={showCancelSubscription}
                    onClose={() => setShowCancelSubscription(false)}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    sx={style.dialogContainer}
                >
                    <DialogTitle sx={{ m: 0, p: 2.5, fontSize: 18, fontWeight: 300 }}>
                        {/* {children} */}
                        {/* {onClose ? ( */}
                        <Typography variant="body2" fontWeight={300} fontSize={14}>
                            Are you sure you want to cancel the subscription ?
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => setShowCancelSubscription(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {/* ) : null} */}
                    </DialogTitle>

                    <DialogContent sx={{ p: 2.5 }}>
                        <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <InputLabel>Plan Support{props?.subscriptionList[0]?.price}</InputLabel>

                                </Grid>

                                <Grid item xs={12}>
                                    <Box>
                                        <InputLabel>End Date : {moment(new Date(props?.subscriptionList[0]?.subscriptionEndDate), "en-us").format("MM/DD/YYYY")}</InputLabel>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            justifyContent: "center",
                            pb: 3,
                            "&>:not(:first-of-type)": { ml: 2.5 },
                        }}
                    >
                        <Button
                            onClick={() => {
                                // putAcceptUserRequest(userData?.id);
                            }}
                            sx={{
                                ...style.modalBtn,
                                background:
                                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                                color: "#fff",
                            }}
                        >
                            Keep Subscription
                        </Button>
                        <Button
                            sx={{
                                ...style.modalBtn,
                                ml: 2,
                                border: "1px solid rgba(104, 115, 125, 0.48)",
                                color: "#68737D",
                            }}
                            onClick={() => {
                                // putRejectUserRequest(userData?.id);
                            }}
                        >
                            Cancel Subscription
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}
export default ManageSubscriptionUser