import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import "rsuite/dist/rsuite.css";
import HeaderListing from "../../../components/headerListing";
import { useAnalyticsDashboardSAAS } from "../../../shared/api/useAnalyticsDashboardSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import Pagination from "../paginationListing";
import PayoutReportTable from "./PayoutReportTable";
import styles from "./index.module.scss";

const PayoutReports = () => {
  const { getClientsListForPayoutReport, clientData } =
    useAnalyticsDashboardSAAS();

  const {
    setSelectedIndex,
    setTabsSubData,
    tabsSubData,
    tabsAdded,
    setTabsAdded,
    value,
    setValue,
  } = useAuth();

  const theme = useTheme();
  const date = new Date();
  const [page, setPage] = useState<any>(0);

  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280));
  const isTablet = !isMobile && !isDesktop;

  useEffect(() => {
    setSelectedIndex(11);
  }, []);

  useEffect(() => {
    getClientsListForPayoutReport();
  }, []);

  const addPanels = async (item: any) => {
    setTabsSubData([
      ...tabsSubData,
      { ...item, title: item?.companyName, key: "PayoutReport" },
    ]);
    setValue(tabsSubData.length);
    setTabsAdded(true);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTabsAdded(true);
  };

  const hanldeCLoseTabs = () => {
    setTabsAdded(false);
  };
  return (
    <>
      <HeaderListing
        tabsAdded={tabsAdded}
        handleChange={handleChange}
        hanldeCLoseTabs={hanldeCLoseTabs}
        setTabsAdded={setTabsAdded}
      />
      {tabsSubData.length && tabsAdded ? (
        <></>
      ) : (
        <Box
          className={styles.contentMain}
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
          }}
        >
          <Stack
            sx={{
              justifyContent: "center",
              px: isMobile ? 0 : 1.5,
              py: isMobile ? 0 : 2,
              backgroundColor: "#FAFBFC",
              borderBottom: "1px solid #E0E0E0",
              height: isMobile ? 0 : 44,
            }}
          ></Stack>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "#fff",
              py: isMobile ? 2.5 : 2.5,
              px: isMobile ? 2.5 : 5,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              mb={1.5}
              alignItems="center"
            >
              <Stack>
                <Typography
                  variant="h4"
                  fontSize={20}
                  fontWeight={500}
                  color="#000"
                >
                  Payout Report
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack
                mb={2}
                direction="row"
                alignItems="center"
                justifyContent="end"
              >
                <Pagination
                  page={page}
                  totalCount={clientData?.count ? clientData?.count : 0}
                  setPage={setPage}
                />
              </Stack>
              <PayoutReportTable
                addPanels={addPanels}
                data={clientData?.result}
                fetchReportDetails={getClientsListForPayoutReport}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default PayoutReports;
