import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import { ReactComponent as ArrowPrev } from "../../../assets/images/icons/arrow-circle-left.svg";
import { ReactComponent as ArrowNext } from "../../../assets/images/icons/arrow-circle-right.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as Reset } from "../../../assets/images/icons/reset.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import HeaderListing from "../../../components/headerListing";
import { useListingDashboardAPI } from "../../../shared/api/userListingDashboardSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style as styless } from "../../listingScreens/superAdminDashboard/index";
import ListingTable from "../listingDashboardTable";
import Pagination from "../paginationListing";
import DetailComponent from "../productScanHistory";
import styles from "./index.module.scss";
import FilterComponent from "./Filter";

export const style = {
  listingSummaryBox: {
    mt: 1.5,
    ".MuiStack-root": {
      flexDirection: "row",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      px: 1.5,
      py: 2,
      borderRight: "1px solid #E0E0E0",
      width: "100%",
      minWidth: 185,
      "&:last-child": {
        borderRight: "unset",
      },
    },
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isTablet = useMediaQuery("(max-width:992px)");


  return (
    <>
      <Box
        className={styles.contentMain}
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
        
        }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </Box>
    </>
  );
}

const ListingCustomerDashboard = () => {
  const {
    tabsSubData,
    tabsAdded,
    setTabsAdded,
    value,
    setValue,
    setSelectedIndex,
  } = useAuth();
  const {
    listingDashboardTableData,
    listingDashboardData,
    getProductDetail,
    getPerticularProductDetail,
  } = useListingDashboardAPI();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [page, setPage] = useState<any>(0);
  const [status, setStatus] = useState<any>(8);
  const [openDetailPage, setOpenDetailPage] = useState<boolean>(false);
  const [producData, setProductData] = useState<any>(null);
  const [prefix, setPrefix] = useState("");
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [dateFilter, setDateFilter] = useState<any>([]);

  const initialPayload = {
    startDate: dateFilter?.length
      ? moment(dateFilter[0]).format("YYYY-MM-DD")
      : null, //YYYY-MM-DD
    endDate: dateFilter?.length
      ? moment(dateFilter[1]).format("YYYY-MM-DD")
      : null, //YYYY-MM-DD
    limit: 50,
    status: status,
    offset: page,
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isTablet = useMediaQuery("(max-width:992px)");

  const changeStatus = (status: any) => {
    setStatus(status);
  };

  const loadInitialData = async (value?: any) => {
    const dcid: any = localStorage.getItem("dcid");
    await listingDashboardTableData(dcid, {
      ...initialPayload,
      prefix: value ? value : prefix,
    });
  };

  const fetchProductDetails = async (id: any) => {
    const payload = {
      ScanId: id,
    };
    const res = await getProductDetail(payload);
    setProductData(res);
    setOpenDetailPage(true);
  };

  useEffect(() => {
    if (page === 0 || page || status) {
      loadInitialData();
    }
    setSelectedIndex(0);
  }, [page, status, dateFilter]);

  const addPanels = async (item: any) => {
    const res = await getPerticularProductDetail(item.inventoryNumber);
    setValue(tabsSubData?.length);
    setTabsAdded(true);
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      loadInitialData(e.target.value);
    }
  };

  const handleClear = () => {
    loadInitialData("");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value1, setValue1] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue1(newValue);
    console.warn(event)
  };



  return (
    <>
      <HeaderListing />

     {(isMobile || isTablet) && <FilterComponent
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={openFilter}
        loadInitialData={loadInitialData}
        setStatus={setStatus}
        changeStatus={changeStatus}
        setPage={setPage}
        listingDashboardData={listingDashboardData}
        status={status}
      
      />
     }

      {tabsSubData.length && tabsAdded ? (
        <>{/* <DetailScreenComponent /> */}</>
      ) : (
        <Box
          className={styles.contentMain}
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            py: 2.5,
            px: isMobile ? 2 : isTablet ? 3 : 5,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={1.5}>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={isMobile ? 1 : 2.5}
            >
              Listing Dashboard
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              fontSize={14}
              fontWeight={500}
              color="#68737D"
            >
              Listing Summary
            </Typography>
            <Box display="flex" sx={style.listingSummaryBox}>
              <Box
                sx={{
                  maxWidth: "100%",
                  flex: 1,
                }}
              >
                <Tabs
                  // value={value1}
                  // onChange={handleChange}
                  // indicatorColor="primary"
                  // textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable listing dahboard status"                  
                >               
                  <Stack sx={{ pl: isMobile ? "0px" : "12px" }}>
                     <ArrowRight
                      style={{ display: isMobile ? "flex" : "none", paddingLeft: "0px" }}
                    /> 

                    <Typography
                      variant="body1"
                      fontSize={18}
                      fontWeight={500}
                      color="#1A1918"
                    >
                      All
                    </Typography>

                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#1A1918"
                      sx={{
                        borderRadius: 0.5,
                        background: "#E3EAFB",
                        padding: "3px 24px",
                        lineHeight: "normal",
                      }}
                    >
                      {listingDashboardData.totalCount}
                    </Typography>
                  </Stack>
                  {/* <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Quick Response
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#E4FAF0",
                        padding: "3px 4px",
                        lineHeight: "normal",
                      }}
                    >
                      {(listingDashboardData.totalQuickRespond) ||
                        "0"}
                    </Typography>
                  </Stack> */}
                  {/* <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Quick Listing
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#E4FAF0",
                        padding: "3px 4px",
                        lineHeight: "normal",
                      }}
                    >
                      {(listingDashboardData.totalListing) ||
                        "0"}
                    </Typography>
                  </Stack> */}
                  <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Listed
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#C8D6F882",
                        padding: "3px 24px",
                        lineHeight: "normal",
                      }}
                    >
                      {listingDashboardData.totalListing}
                    </Typography>
                    <ArrowLeft
                      style={{ display: isMobile ? " flex" : "none" }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Pending
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#EFEFEF",
                        padding: "3px 24px",
                        lineHeight: "normal",
                      }}
                    >
                      {listingDashboardData.totalPending}
                    </Typography>
                  </Stack>
                  {/* <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Processing
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#FFF1D4",
                        padding: "3px 4px",
                        lineHeight: "normal",
                      }}
                    >
                      {(listingDashboardData.totalProcessing) ||
                        "0"}
                    </Typography>
                  </Stack> */}
                  <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Error
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#FFF1D4",
                        padding: "3px 24px",
                        lineHeight: "normal",
                      }}
                    >
                      {listingDashboardData.totalError}
                    </Typography>
                  </Stack>

                  {/* <Stack>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={500}
                      color="#68737D"
                    >
                      Inventory Sync
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={20}
                      fontWeight={500}
                      color="#68737D"
                      sx={{
                        borderRadius: 0.5,
                        background: "#E4FAF0",
                        padding: "3px 4px",
                        lineHeight: "normal",
                      }}
                    >
                      {(listingDashboardData.totalsync) ||
                        "0"}
                    </Typography>
                  </Stack> */}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", mt: 3, }}>

      
            <Stack flexDirection="row" alignItems="center" mb={1.5} >
              {(isMobile || isTablet )?<Stack
                alignItems="center"
                sx={{
                  flexDirection: "row",
                  ml: isMobile || isTablet ? 0:2,
                  mr:isMobile? 1.5 : isTablet? 2:0,
                  px: "10px",
                  py:"10px",
                  border:"1px solid #E0E0E0"
                }}
                onClick={(e: any) => handleClick(e)}
              >
                <Filter                
                  id="basic-button"
                  aria-controls={openFilter ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFilter ? "true" : undefined}
                  
                 
                />
              </Stack>:null}
              <Stack
              direction="row"
              alignItems="center"
              className={`${styles.searchInput}`}
              width={isMobile ? "100%" : "156px"}

            >
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyDown={(event) => handleKeypress(event)}
                onBlur={(event) => setPrefix(event.target.value)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
                className={styles.customSearchInput}
              />
              {prefix && (
                <CloseIcon
                  onClick={async () => {
                    setPrefix("");
                    handleClear();
                  }}
                />
              )}
            </Stack>
            </Stack>
              <Stack
                justifyContent="center"
                className={styles.datepickerWrapper}
                sx={{ ...styless.borderStack, ml: isMobile ? 0 : isTablet ? 2: 2.5, px: "16px" }}
              >


                <DateRangePicker
                  size="md"
                  cleanable={false}
                  format="MM-dd-yyyy"
                  value={dateFilter}
                  placeholder={["Start Date", "-", "End Date"]}
                  onChange={(e) => setDateFilter(e)}
                  className={styles.dateRangePicker}
                  showOneCalendar={isMobile ? true : false}
                />
              </Stack>
           



          </Box>

          <Divider
            sx={{ borderColor: "#E0E0E0", opacity: 1, mt: 2.5 }}
          ></Divider>

          <Stack direction="row">
            {!isMobile && <Box sx={{ pr: 2.5, display:isTablet? "none":"flex" }}>
              {drawerOpen ? (
                <Paper elevation={0} sx={{ width: "100%", minWidth: 240 }}>
                  <Box>
                    <Box
                      display="flex"
                      alignContent="center"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={0.5}
                    >
                      <Typography
                        variant="h6"
                        fontSize={18}
                        fontWeight={500}
                        color="#1A1918"
                      >
                        Status
                      </Typography>
                      <Stack direction="row" spacing={1.5}>
                        <IconButton
                          aria-label="reset"
                          sx={{ width: 34, height: 34 }}
                          onClick={() => setStatus(8)}
                        >
                          <Reset />
                        </IconButton>
                        <IconButton
                          aria-label="toggle"
                          sx={{ width: 34, height: 34 }}
                          onClick={handleDrawerClose}
                        >
                          <ArrowPrev />
                        </IconButton>
                      </Stack>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 2, opacity: 1 }} />
                    <ListItemButton
                      sx={{
                        py: 0,
                        px: 1,
                        minHeight: 32,
                        justifyContent: "space-between",
                        backgroundColor:
                          status === 8 ? "rgba(0, 0, 0, 0.04)" : "",
                      }}
                      onClick={() => {
                        setPage(0);
                        changeStatus(8);
                      }}
                    >
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primary="All Scanned"
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      />
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      >
                        {listingDashboardData.totalCount}
                      </ListItemText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        py: 0,
                        px: 1,
                        minHeight: 32,
                        justifyContent: "space-between",
                        backgroundColor:
                          status === 1 ? "rgba(0, 0, 0, 0.04)" : "",
                      }}
                      onClick={() => {
                        setPage(0);
                        changeStatus(1);
                      }}
                    >
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primary="Pending "
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      />
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      >
                        {listingDashboardData.totalPending}
                      </ListItemText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        py: 0,
                        px: 1,
                        minHeight: 32,
                        justifyContent: "space-between",
                        backgroundColor:
                          status === 2 ? "rgba(0, 0, 0, 0.04)" : "",
                      }}
                      onClick={() => {
                        setPage(0);
                        changeStatus(2);
                      }}
                    >
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primary="Processing"
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      />
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      >
                        {listingDashboardData.totalProcessing}
                      </ListItemText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        py: 0,
                        px: 1,
                        minHeight: 32,
                        justifyContent: "space-between",
                        backgroundColor:
                          status === 3 ? "rgba(0, 0, 0, 0.04)" : "",
                      }}
                      onClick={() => {
                        setPage(0);
                        changeStatus(3);
                      }}
                    >
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primary="Listed"
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      />
                      <ListItemText
                        sx={{ flexGrow: 0 }}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                      >
                        {listingDashboardData.totalListing}
                      </ListItemText>
                    </ListItemButton>
                  </Box>
                </Paper>
              ) : (
                <Stack mt={0.5}>
                  <IconButton aria-label="toggle" onClick={handleDrawerOpen}>
                    <ArrowNext />
                  </IconButton>
                  <Divider sx={{ mt: 1, mb: 2, opacity: 1, }} />
                </Stack>
              )}
            </Box>}
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#E0E0E0", opacity: 1, display:isMobile || isTablet? "none":"flex" }}
            ></Divider>

            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#fff",
                pt: 1,            
                pl: isMobile || isTablet ? 0 : 5,
                overflowX: isMobile ? "hidden" : "auto",
              }}
            >
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#68737D"
                  ></Typography>
                  <Pagination
                    page={page}
                    totalCount={
                      listingDashboardData.count
                        ? listingDashboardData.count
                        : 0
                    }
                    setPage={setPage}
                  />
                </Stack>
                <ListingTable
                  page={page}
                  addPanels={addPanels}
                  data={listingDashboardData.listingInventoryDto}
                  fetchProductDetails={fetchProductDetails}
                />
              </Box>
            </Box>

            {openDetailPage ? (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "#E0E0E0", opacity: 1 }}
                ></Divider>
                <Box sx={{ width: "100%", maxWidth: 380, p: 2.5 }}>
                  <DetailComponent
                    setOpenDetailPage={setOpenDetailPage}
                    data={producData}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
export default ListingCustomerDashboard;
