import { Box } from "@mui/material";
import { HashRouter } from "react-router-dom";
import "./App.css";
import PageRoutes from "./routes/PageRoutes";
import { AuthProvider } from "./shared/provider/authprovider";

const App = () => {

  return (
    <HashRouter>
      <AuthProvider>
        <Box display='flex'>
          <PageRoutes />
        </Box>
      </AuthProvider>
    </HashRouter>
  );
}

export default App;
