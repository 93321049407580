import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as Circle } from '../../../assets/images/icons/circle-small.svg';
import { ReactComponent as Cross } from '../../../assets/images/icons/cross.svg';
const index = (props: any) => {
    const { data, setOpenDetailPage } = props;

    const getStatusName = (status: any) => {
        switch (status) {
            case 0:
                return "Pending"
                break;
            case 1:
                return "Pending"
                break;
            case 2:
                return "Pending"
                break;
            case 3:
                return "Listed"
                break;
            case 4:
                return "Listed"
                break;

            default:
                return "";
                break;
        }

    }

    return (<>
        {data?.length &&
            <Box>
                <Box display='flex'>
                    <Avatar
                        alt="Remy Sharp"
                        variant="square"
                        src={data[0].imageUrl}
                        sx={{ width: 22, height: 22 }}
                    />
                    <Stack ml='12px'>
                        <Typography variant="body1" fontSize={20} fontWeight={300} color='#1A1918' sx={{ lineHeight: 'normal' }}>{data[0].upc}</Typography>

                        <Stack alignItems='center' direction='row' spacing={1}>
                            <Typography variant="body2" fontSize={14} fontWeight={300} color='#1A1918'> {data[0].dateTime ? moment(data[0].dateTime).format("lll") : ""}
                            </Typography>
                            <Circle />
                            <Typography variant="body2" fontSize={14} fontWeight={300} color='#1A1918'> {"Logiva"}</Typography>
                        </Stack>
                    </Stack>
                    <IconButton aria-label="close" sx={{ ml: 'auto', height: 34, width: 34 }}>
                        <Cross onClick={() => setOpenDetailPage(false)} />
                    </IconButton>
                </Box>

                {
                    data.map((ele: any) => (
                        <Box sx={{ backgroundColor: '#FAFBFC', borderRadius: 0.5, p: 1, border: '1px solid #E0E0E0', mt: 1.5 }}>
                            <Box mb={1}>
                                <Stack flexDirection='column' alignItems='center' sx={{ width: 'max-content' }}>
                                    <Typography variant="body2" fontSize={14} fontWeight={300}>{ele.dateTime ? moment(data[0].dateTime).format("ll") : ""}</Typography>
                                    <Typography variant="body2" fontSize={14} fontWeight={300}>{ele.dateTime ? moment(data[0].dateTime).format("HH:MM") : ""}</Typography>
                                </Stack>
                                <Box ml={5} sx={{ borderLeft: '1px dashed #68737D', px: 2.5, py: 1.5 }}>
                                    <Stack direction='row' alignItems='center' mb={1.5}>
                                        <Typography variant="body2" fontSize={12} fontWeight={500} color='#fff' sx={{ height: 16, width: 16, backgroundColor: '#6572DD', borderRadius: 0.5, textAlign: 'center' }}>{ele.status}</Typography>
                                        <Typography variant="body2" fontSize={14} fontWeight={500} color='#1A1918' ml={1}>{getStatusName(ele.status)}</Typography>
                                    </Stack>
                                    <Typography variant="body2" fontSize={12} fontWeight={300}>
                                        {/* {ele.comments} */}
                                        Data Listing 03: Market Place: Found in Macy’s
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>
                    ))
                }
            </Box>}
    </>)
}
export default index;