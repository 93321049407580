import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../../shared/provider/authprovider";

const ErrorModal = (props: any) => {
  const { errorMsg, setErrorModalShow } = useAuth()
  return (
    <Modal className="formModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...props}
    >
      {/* <Modal.Header closeButton className='border-bottom-0'>
        <Modal.Title className="modalTitle">
          Success!
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className='formContainer text-center'>
          <p className="fieldTitle text-start">{errorMsg}</p>
          <button className="FilledBtn" onClick={() => setErrorModalShow(false)}>Ok</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ErrorModal;