import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, Snackbar, Stack, TextField, Typography, styled, Modal, Box, InputAdornment, MenuItem, FormHelperText, Select } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { ChangeEvent, useState } from "react";
import * as Yup from "yup";
import { useSKuFormAPI } from "../../../shared/api/useSkuFormSAAS";
import { countryList } from "./constant";
import { ArrowDropDown } from "@mui/icons-material"; import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import { grey } from "@mui/material/colors";

const Image = ({ data, index, formik }: any,) => (
    <>
        <img src={`data:image/jpeg;base64,${data}`} alt="uploaded" style={{
            width: "100%", maxWidth: index === 0 ? "280px" : "160px", objectFit: "cover",
            height: index === 0 ? "280px" : "160px"
        }} />
        <div style={{
            position: "absolute",
            color: "#fff",
            bottom: index === 0 ? "0px" : "120px",
            padding: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.29)"
        }}>{`${formik.values.sku}_${index + 1}`}</div>
    </>
);

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
`;

export const style = {

    imageContainer: {
        maxWidth: "280px",
        maxHeight: "280px"
    },
    mediaContainer: {
        border: "1px dashed #68737D",
        background: "#F5F5F5",
        padding: 2.5,
        borderRadius: 0.5,
    },
    inputLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: '#1A1918',
    },
    saveCancelBtn: {
        width: "100%",
        px: 2,
        py: 1.5,
        borderRadius: 0.5,
        boxShadow: "none",
        fontSize: 14,
        fontWeight: 300,
        textTransform: "capitalize",
        height: 34,
    },
    blueVerticalDash: {
        height: 23,
        borderWidth: 3,
        mr: 2,
        borderColor: '#004EFF',
        my: 0,
        alignSelf: 'unset',
    },
    errorIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        textWrap: 'wrap',
        // Add any other common styling properties you need for the error icon
    },
}


const styless = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: "1px solid #68737D",
    boxShadow: 24,
    // pt: 2,
    //px: 4,
    pb: 3,
};

const FormDetails = (props: any) => {
    const { setShortMessage, setMessageText, setOpen, open, messageText } = useAuth()
    const { setFormModal, formModal, updateTableData, categoryListData, uniqueDepartments, sizeListData, setSizeListData } = props;
    const { addNewSkuForm, getSizeListbySize } = useSKuFormAPI();
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [image, setImage] = useState<any>([]);
    const [urlLink, setURLLInk] = useState<any>(null);

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Required").max(255, "Max 255 characters are allowed").nullable(),
        sku: Yup.string().required("Required").nullable(),
        quantity: Yup.number().required("Required").nullable().positive('Value must be positive'),
        heightIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        lengthIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        widthIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        weight: Yup.number().required("Required").nullable().positive('Value must be positive'),
        upc: Yup.string().max(12, "Max 12 numbers are allowed").nullable(),
        ean: Yup.string().max(13, "Max 13 numbers are allowed").nullable(),
        mpn: Yup.string().required("Required").nullable(),
        shortDescription: Yup.string().max(255, "Max 255 characters are allowed").nullable(),
        description: Yup.string().max(1500, "Max 1500 characters are allowed").min(200, "min 200 characters are allowed").nullable(),
        manufacturer: Yup.string().required("Required").nullable(),
        brand: Yup.string().required("Required").nullable(),
        condition: Yup.string().required("Required").nullable(),
        minimumPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('Minimum Price must be a number'),
        maximumPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('SRP must be a number'),
        color: Yup.string().required("Required").nullable(),
        countryOfOrigin: Yup.string().required("Required").nullable(),
        multipackQuantity: Yup.string().required("Required").nullable(),
        size: Yup.string().required("Required").nullable(),
        cogs: Yup.number().required("Required").nullable().typeError('COGS must be a number'),
        sellingPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('Buy it now must be a number'),
        category: Yup.string().required("Required").nullable(),
        department: Yup.string().required("Required").nullable(),
        asin: Yup.string().max(14, "Max 14 numbers are allowed").nullable(),
    });
    const formikProductDetails: any = useFormik({
        initialValues: {
            title: "",
            sku: "",
            quantity: null,
            heightIn: null,
            lengthIn: null,
            widthIn: null,
            weight: null,
            upc: "",
            asin: null,
            ean: null,
            mpn: "",
            shortDescription: "",
            description: "",
            variesBy: "",
            manufacturer: "",
            brand: "",
            condition: "",
            retailPrice: null,
            minimumPrice: null,
            maximumPrice: null,
            bulletPoints: null,
            color: "",
            countryOfOrigin: "",
            multipackQuantity: null,
            size: "",
            images: null,
            department: "",
            weightUnit: "lb",
            cogs: null,
            sellingPrice: null,
            category: "",
            subcategory: "",
            weightOZ: null,
            // availablility: ""
        },
        onSubmit: () => submitData(),
        validationSchema: DisplayingErrorMessagesSchema,
    })

    const submitData = async () => {
        let weightInOz: number;
        switch (formikProductDetails.values.weightUnit) {
            case 'kg':
                weightInOz = formikProductDetails.values.weight * 35.27396; // 1 kg = 35.27396 oz
                break;
            case 'g':
                weightInOz = formikProductDetails.values.weight * 0.03527396; // 1 g = 0.03527396 oz
                break;
            case 'lb':
                weightInOz = formikProductDetails.values.weight * 16; // 1 lb = 16 oz
                break;
            case 'oz':
                weightInOz = formikProductDetails.values.weight; // Already in ounces
                break;
            default:
                weightInOz = 0;
        }
        try {
            const payload = {
                ...formikProductDetails.values,
                images: image.length ? image.map((ele: any) => ele.image) : [],
                weightOZ: weightInOz
            }
            const res = await addNewSkuForm(payload);
            if (res.status === 200) {
                if (res.data.result) {
                    await updateTableData();
                    await setFormModal(false);
                    setOpen(true)
                    setShortMessage("Success")
                    setMessageText("Product saved successfully")
                } else {
                    setOpen(true)
                    setMessageText(res.data.message)
                }
            }
        } catch (err) {
        }
    }

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    const readImages = (e: ChangeEvent<HTMLInputElement>, func: (images: any) => void) => {
        const files = e.target.files;
        const newImages: any = [];

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = function (e: any) {
                    const binaryData = e.target.result as string;
                    const base64String = window.btoa(binaryData);
                    newImages.push({ image: base64String, checked: false });

                    if (newImages.length === files.length) {
                        func([...image, ...newImages]);
                    }
                };
                reader.readAsBinaryString(file);
            }
        }
    }

    const handleChekBox = (index: any) => {
        const arr = [...image];
        arr[index].checked = !arr[index].checked;
        setImage(arr)
    }

    const handelDelete = () => {
        const arr = image.filter((ele: any) => !ele.checked);
        setImage(arr)
    }

    const handleCloseOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    function convertImageToBase64(url: string, callback: (base64Data: string) => void) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader: any = new FileReader();
                reader.onload = () => {
                    const base64Data: string = reader?.result?.split(',')[1];
                    callback(base64Data);
                };
                reader.readAsDataURL(blob);
            });
    }
    const [errorMsgImage, setErrorMsgImage] = useState("")
    const convertIntoBase64 = () => {
        if (urlLink == null) {
            setErrorMsgImage("Please add image url")
        }
        else {
            convertImageToBase64(urlLink, (base64Data) => {
                setImage([...image, { image: base64Data, checked: false }])
                handleCloseModal();
            });
        }
    }

    const [categoriesList2, setCategoriesList2] = useState([])
    const [subCategoriesList, setSubCategoriesList] = useState([])
    const ColorList: any = [
        { value: "Pink", label: "Pink" },
        { value: "Orange", label: "Orange" },
        { value: "Yellow", label: "Yellow" },
        { value: "Green", label: "Green" },
        { value: "Blue", label: "Blue" },
        { value: "Purple", label: "Purple" },
        { value: "Gold", label: "Gold" },
        { value: "Silver", label: "Silver" },
        { value: "Black", label: "Black" },
        { value: "Gray", label: "Gray" },
        { value: "White", label: "White" },
        { value: "Cream", label: "Cream" },
        { value: "Brown", label: "Brown" },
        { value: "Tan", label: "Tan" }
    ]
    return (
        <>

            {openModal &&
                <Modal
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={{ ...styless, width: "700px", borderRadius: "8px" }}>
                        <Stack sx={{ background: "#F5F5F5", padding: 2 }} >
                            <Typography >Add file from URL</Typography>
                        </Stack>

                        <Stack sx={{ padding: 2 }}>
                            <Typography mb={1}>Image URL</Typography>
                            <Typography variant="subtitle1" id="simple-modal-description" width="100%">
                            </Typography>
                            <TextField
                                type="string"
                                id="standard-required"
                                variant="standard"
                                placeholder="Add Image URL.."
                                fullWidth
                                onChange={(e) => setURLLInk(e.target.value)} value={urlLink} />
                        </Stack>
                        <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{errorMsgImage}</FormHelperText>
                        <Stack direction="row" gap={2} ml={2}>
                            <Button variant="contained" onClick={handleCloseModal}>Cancel</Button>
                            <Button variant="outlined" onClick={() => convertIntoBase64()}>Add File</Button>
                        </Stack>
                    </Box>
                </Modal>}



            {/* <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={messageText}
                action={action}
            /> */}

            <Dialog
                open={formModal}
                onClose={() => setFormModal(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle
                    sx={{ m: 0, pt: 4, pb: 2.5, px: 4, fontSize: 20, fontWeight: 500, color: '#002C8F' }}
                >
                    Product Details Form
                    <IconButton
                        aria-label="close"
                        onClick={() => setFormModal(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ px: 4 }}>

                    <FormikProvider value={formikProductDetails}>
                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Basic Identifiers:</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Title
                                </InputLabel>
                                <Stack flexDirection="row" position='relative' >
                                    <Field name="title" >
                                        {({ field, meta }: any) => (
                                            <TextField
                                                {...field}
                                                type="string"
                                                id="standard-required"
                                                variant="standard"
                                                placeholder="Listing Title"
                                                fullWidth
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        )}
                                    </Field>
                                    <Stack sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        textWrap: "wrap"
                                    }}
                                        title="(255 characters max) Suggested: Brand + Product Title + Function + Color + Size"><ErrorOutlineIcon /></Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    SKU
                                </InputLabel>
                                <Field name="sku" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Enter SKU"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Quantity
                                </InputLabel>
                                <Field name="quantity" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Listing Title"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    UPC
                                </InputLabel>
                                <Stack flexDirection="row" position='relative' >
                                    <Field name="upc" >
                                        {({ field, meta }: any) => (
                                            <TextField
                                                {...field}
                                                type="string"
                                                required
                                                id="standard-required"
                                                variant="standard"
                                                placeholder="Enter UPC"
                                                fullWidth
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        )}
                                    </Field>
                                    <Stack sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        textWrap: "wrap"
                                    }}
                                        title="12 numbers max"><ErrorOutlineIcon /></Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    EAN (If applicable)
                                </InputLabel>
                                <Field name="ean" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Enter EAN"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    MPN
                                </InputLabel>
                                <Field name="mpn" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Enter MPN"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    ASIN (If applicable)
                                </InputLabel>
                                <Field name="asin" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Enter ASIN"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        <Stack justifyContent="space-between" flexDirection="row" mb={5}>
                            <Typography variant="h6" fontSize={20} fontWeight={500} >{image?.filter((ele: any) => ele.checked)?.length ?
                                <div>
                                    <Checkbox
                                        checked={true}
                                        sx={{
                                            color: "#fff"
                                        }}
                                    />{image?.filter((ele: any) => ele.checked)?.length} file selected
                                </div> : "Media :"}
                            </Typography>
                            {image?.filter((ele: any) => ele.checked)?.length ? <Button
                                variant="text"
                                sx={{
                                    fontSize: 20,
                                    color: "#D9706C",
                                    textTransform: "capitalize",
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "unset"
                                    }

                                }}

                                onClick={() => handelDelete()}
                            >
                                Delete Files
                            </Button> : ""}
                        </Stack>

                        <Grid container mb={2.5} justifyContent="center" sx={style.mediaContainer}>
                            <Stack direction="column" alignItems="center" >
                                {!image?.length ?
                                    <Stack flexDirection="row" alignItems="center" gap={3.5} mb={1.5}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            variant="outlined"
                                            onChange={(e: any) => {
                                                readImages(e, setImage);
                                            }}
                                            sx={{
                                                color: "#1A1918", fontWeight: "600", fontSize: "14px", textTransform: "capitalize", borderColor: "rgba(104, 115, 125, 0.48)"
                                            }}
                                        >
                                            Upload New
                                            <VisuallyHiddenInput type="file" />

                                        </Button>
                                        <Typography sx={{ cursor: "pointer", color: "#1A1918", fontWeight: "600" }} onClick={handleCloseOpenModal}
                                        >Add from URL</Typography>
                                    </Stack>
                                    : <></>}

                                {!image?.length ?

                                    <Typography color="#68737D">Accept Images</Typography>
                                    : ""}
                            </Stack>


                            <Grid container spacing={0.5} gap={2.5}>
                                {image?.map((image: any, index: any) => (
                                    <Stack sx={{ position: "relative" }}>
                                        <Image key={index} data={image.image} index={index} formik={formikProductDetails} />

                                        <Checkbox
                                            checked={image.checked}
                                            onChange={() => handleChekBox(index)}
                                            sx={{
                                                position: "absolute",
                                                // top: "10px",
                                                color: "#fff"
                                            }}
                                        />
                                    </Stack>
                                ))}
                                {image?.length ?
                                    <Stack sx={{ background: "#fff", alignItems: "center", justifyContent: "center", height: 160, width: 160, gap: 2.5, border: "1px solid #E0E0E0", borderRadius: "4px" }}>


                                        <Button
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            variant="outlined"
                                            onChange={(e: any) => {
                                                readImages(e, setImage);
                                            }}
                                            sx={{
                                                color: "#68737D", fontWeight: "300", fontSize: "14px", textTransform: "capitalize", borderColor: "rgba(104, 115, 125, 0.48)"
                                            }}
                                        >
                                            Upload New
                                            <VisuallyHiddenInput type="file" />

                                        </Button>

                                        <Typography sx={{ cursor: "pointer", color: "#68737D", fontWeight: "300" }} onClick={handleCloseOpenModal}>Add from URL</Typography>
                                    </Stack>
                                    : <></>}
                            </Grid>
                        </Grid>

                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Dimensions :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Height (in)
                                </InputLabel>

                                <Field name="heightIn" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"

                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Height in Inches"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Width (in)
                                </InputLabel>
                                <Field name="widthIn" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Width in Inches"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Length (in)
                                </InputLabel>
                                <Field name="lengthIn" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Length in Inches"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Weight
                                </InputLabel>
                                <Stack direction="row" alignItems="end" gap={1.5}>
                                    <Field name="weight" >
                                        {({ field, meta }: any) => (
                                            <TextField
                                                {...field}
                                                type="number"
                                                id="standard-required"
                                                variant="standard"
                                                placeholder="Enter weight"
                                                fullWidth
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={meta.touched && meta.error && meta.error}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {/* <ArrowDropDown /> */}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <Field name="weightUnit" >
                                        {({ field, meta }: any) => (
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={field.value}
                                                fullWidth
                                                variant="standard"
                                                sx={{ width: 80 }}

                                                onChange={async (e: any) => {
                                                    formikProductDetails.setFieldValue("weightUnit", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="kg">kg</MenuItem>
                                                <MenuItem value="g">g</MenuItem>
                                                <MenuItem value="lb">lb</MenuItem>
                                                <MenuItem value="oz">oz</MenuItem>
                                            </Select>
                                        )}
                                    </Field>
                                </Stack>

                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Warehouse Quantity
                                </InputLabel>
                                <Field name="quantity" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Warehouse Quantity"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Product Description :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    Short Description
                                </InputLabel>
                                <Field name="shortDescription" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Provide details here.."
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={9}>
                                <InputLabel sx={style.inputLabel}>
                                    Description
                                </InputLabel>
                                <Field name="description" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            multiline
                                            variant="standard"
                                            placeholder="Provide details here.."
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel required sx={style.inputLabel}>
                                    Gender / Department
                                </InputLabel>
                                <Field name="department" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={async (e) => {
                                                formikProductDetails.setFieldValue("department", e.target.value)
                                                const Data2 = await categoryListData?.filter((item: any) => item.department === e.target.value)
                                                setCategoriesList2(Array.from(new Set(Data2 && Data2?.map((item: any) => item.category))))
                                            }
                                            }
                                            variant="standard"
                                            sx={{
                                                'MuiSelect-select': {
                                                    '&.MuiInputBase-input': {
                                                        '&.MuiInput-input:focus': {
                                                            backgroundColor: '#fff',
                                                        }
                                                    }

                                                }
                                            }}
                                            placeholder="Select Gender"
                                        >
                                            {uniqueDepartments.map((ele: any) => (<MenuItem value={ele}>{ele}</MenuItem>))}
                                        </Select>
                                    )}
                                </Field>
                                <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.department} </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Category
                                </InputLabel>
                                <Field name="category" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={async (e) => {
                                                formikProductDetails.setFieldValue("category", e.target.value);
                                                const Data3 = await categoryListData?.filter((item: any) => item.category === e.target.value && item.department === formikProductDetails.values.department)
                                                console.log("data55", Data3 && Data3)
                                                setSubCategoriesList(Array.from(new Set(Data3 && Data3?.map((item: any) => item.subcategory))))
                                                const result = await getSizeListbySize(e.target.value)
                                                setSizeListData(result?.data?.result)
                                            }}
                                            variant="standard"
                                            {...{ error: meta.touched && meta.error }}
                                        >
                                            {categoriesList2 &&
                                                categoriesList2?.map((category: any) => {
                                                    return (
                                                        <MenuItem value={category}>{category}</MenuItem>
                                                    );
                                                })}

                                        </Select>
                                    )}
                                </Field>
                                <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.category} </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    Sub Category
                                </InputLabel>
                                <Field name="subcategory" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={(e) => { formikProductDetails.setFieldValue("subcategory", e.target.value) }}
                                            variant="standard"
                                            {...{ error: meta.touched && meta.error }}
                                        >
                                            {subCategoriesList &&
                                                subCategoriesList.map((subcategory: any) => {
                                                    return (
                                                        <MenuItem value={subcategory}>{subcategory}</MenuItem>
                                                    );
                                                })}

                                        </Select>
                                    )}
                                </Field>
                                <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.subcategory} </FormHelperText>
                            </Grid>
                        </Grid>

                        {/* <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Variants (if applicable) :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    Does it vary?
                                </InputLabel>
                                <FormGroup sx={{ flexDirection: 'row' }}>
                                    <FormControlLabel control={<Checkbox checked={!isChecked} onClick={() => { formikProductDetails.setFieldValue("variesBy", null); setIsChecked(!isChecked) }} />} label="No" />
                                    <FormControlLabel control={<Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />} label="Yes" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    Please select how does it vary
                                </InputLabel>
                                <Field name="variesBy" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Select Specify reasons "
                                            fullWidth
                                            disabled={!isChecked}
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>

                        </Grid> */}

                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Brand & Manufacturer details :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Manufacturer
                                </InputLabel>
                                <Field name="manufacturer" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Manufacturer Name"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Brand
                                </InputLabel>
                                <Field name="brand" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Brand name"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Product Condition
                                </InputLabel>
                                <Field name="condition" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={(e) => { formikProductDetails.setFieldValue("condition", e.target.value) }}
                                            variant="standard"
                                            {...{ error: meta.touched && meta.error }}
                                        >
                                            <MenuItem value="New">New</MenuItem>
                                            <MenuItem value="Used">Used</MenuItem>
                                            <MenuItem value="Refurbished">Refurbished</MenuItem>
                                        </Select>
                                    )}
                                </Field>
                                <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.condition} </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel}>
                                    Bullet Points
                                </InputLabel>
                                <Field name="bulletPoints" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Mention in points"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Pricing :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    COGS
                                </InputLabel>
                                <Field name="cogs" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="$ 0.00"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Minimum Price
                                </InputLabel>
                                <Field name="minimumPrice" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="$ 0.00"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    SRP
                                </InputLabel>
                                <Field name="maximumPrice" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="$ 0.00"
                                            fullWidth

                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel required sx={style.inputLabel}  >
                                    Buy it now
                                </InputLabel>
                                <Field name="sellingPrice" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="$ 0.00"
                                            fullWidth
                                            required
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Attribute :</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Color
                                </InputLabel>
                                <Field name="color" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={(e) => { formikProductDetails.setFieldValue("color", e.target.value) }}
                                            variant="standard"
                                            {...{ error: meta.touched && meta.error }}
                                        >
                                            {
                                                ColorList.map((v: any) => {
                                                    return (
                                                        <MenuItem value={v?.value}>{v?.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Country of Origin
                                </InputLabel>
                                <Field name="countryOfOrigin" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={(e) => { formikProductDetails.setFieldValue("countryOfOrigin", e.target.value) }}
                                            variant="standard"
                                            sx={{
                                                'MuiSelect-select': {
                                                    '&.MuiInputBase-input': {
                                                        '&.MuiInput-input:focus': {
                                                            backgroundColor: '#fff',
                                                        }
                                                    }

                                                }
                                            }}
                                        >
                                            {countryList.map((ele: any) => (<MenuItem value={ele}>{ele}</MenuItem>))}
                                        </Select>
                                    )}
                                </Field>
                                <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.countryOfOrigin} </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Multipack Quantity
                                </InputLabel>
                                <Field name="multipackQuantity" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Multipack Quantity"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <InputLabel sx={style.inputLabel} required>
                                    Size
                                </InputLabel>
                                <Field name="size" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={async (e) => {
                                                formikProductDetails.setFieldValue("size", e.target.value)
                                            }}
                                            variant="standard"
                                            sx={{
                                                'MuiSelect-select': {
                                                    '&.MuiInputBase-input': {
                                                        '&.MuiInput-input:focus': {
                                                            backgroundColor: '#fff',
                                                        }
                                                    }

                                                }
                                            }}
                                            placeholder="Select Size"
                                        >
                                            {sizeListData?.map((ele: any) => (<MenuItem value={ele?.size}>{ele?.size}</MenuItem>))}
                                        </Select>
                                    )}
                                </Field>
                            </Grid>

                        </Grid>
                    </FormikProvider>
                </DialogContent>

                <DialogActions sx={{ pb: 6, pl: 4, justifyContent: "flex-start" }}>
                    <Stack gap={2.5} direction="row">
                        <Button
                            variant="contained"
                            sx={{
                                ...style.saveCancelBtn,
                                minWidth: 136,
                                background:
                                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                            }}
                            onClick={() => formikProductDetails.handleSubmit()}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                ...style.saveCancelBtn,
                                minWidth: 136,
                                border: "1px solid rgba(104, 115, 125, 0.48)",
                                color: "#68737D",
                            }}
                            onClick={() => setFormModal(false)}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog >
        </>
    )
}
export default FormDetails;