import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as ArrowUp } from "../../../assets/images/icons/arrow-up.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/images/icons/refresh.svg";
import styles from "./index.module.scss";
import { useState } from "react";
export const style = {
  filterContainer: {
    ".MuiMenu-paper": {
      border: "1px solid red",
      borderRadius: 0.5,
      borderColor: "#E0E0E0",
      top: "218px !important",
      left: "272px !important",
      width: 175,
      ".MuiList-root": {
        py: 0,
      },
      ".MuiAccordionSummary-root": {
        minHeight: 0,
        py: 1,
        px: 1.5,
        ".MuiAccordionSummary-content": {
          m: 0,
        },
      },
      ".MuiAccordionDetails-root": {
        ".MuiTypography-body1": {
          px: 1.5,
          py: 1,
          fontSize: 14,
          fontWeight: 300,
          ".MuiCheckbox-root": {
            p: 0,
          },
          "&:hover": {
            backgroundColor: "#EFEFEF",
            borderLeft: "2px solid #4079FC",
          },
        },
      },
    },
  },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Index = (props: any) => {
  const { handleClose, anchorEl, open, items, setItems } = props;

  const handleCheckboxChange = (value: any) => {
    if (items.includes(value)) {
      setItems(items.filter((item: any) => item !== value));
    } else {
      setItems([...items, value]);
    }
  };
  const checkboxes = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={style.filterContainer}
    >
      <Box px={1.5} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            mr={"10px"}
            className={styles.badge}
          >
            1
          </Stack>
          <Typography
            variant="subtitle2"
            fontSize={12}
            fontWeight={500}
            color="#68737D"
          >
            Filter by
          </Typography>
        </Box>
        <IconButton aria-label="reload" sx={{ p: 0 }}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Divider />
      <Accordion
        defaultExpanded={true}
        sx={{
          m: 0,
          boxShadow: "unset",
          "&.Mui-expanded": { m: 0 },
          "&:before": { backgroundColor: "unset" },
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowUp />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle2" fontSize={12} fontWeight={300}>
            User Status
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {/* <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> Active</Typography>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> In Active</Typography> */}
          <List>
            {checkboxes.map((checkbox, index) => (
              <ListItem key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={items.includes(checkbox.value)}
                      onChange={() => handleCheckboxChange(checkbox.value)}
                    />
                  }
                  label={checkbox.label}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider />
      {/* <Accordion sx={{ m: 0, boxShadow: 'unset', "&.Mui-expanded": { m: 0 }, '&:before': { backgroundColor: 'unset' } }}>
                <AccordionSummary
                    expandIcon={<ArrowUp />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='subtitle2' fontSize={12} fontWeight={300}>Subscription Status </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> Active </Typography>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> In Active</Typography>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> Awaiting Payment</Typography>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> On Hold</Typography>
                    <Typography><Checkbox {...label} defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 18, mr: 1 } }} /> Canceled</Typography>
                </AccordionDetails>
            </Accordion> */}
    </Menu>
  );
};
export default Index;
