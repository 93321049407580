import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, TextareaAutosize, Typography, styled } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import { useSKuFormAPI } from "../../../shared/api/useSkuFormSAAS";
import { style } from "../../listingScreens/SkuForm/FormDetails";
import { countryList } from "./constant";
import { useAuth } from "../../../shared/provider/authprovider";
import { blue, grey } from "@mui/material/colors";

type Anchor = 'right';

const Image = ({ data, index }: any,) => (
    <img src={`data:image/jpeg;base64,${data}`} alt="uploaded" style={{
        width: "100%", maxWidth: index === 0 ? "280px" : "160px", objectFit: "cover",
        height: index === 0 ? "280px" : "160px"
    }} />
);

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
`;

const styless = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const EditSkuForm = (props: any) => {
    const { getPerticularSkuRecord, editSkuForm, getSizeListbySize } = useSKuFormAPI();
    const { setShortMessage, setMessageText, setOpen } = useAuth()
    const { id, toggleDrawer, getTableData, categoryListData, setSizeListData, sizeListData
        // uniqueDepartments
    } = props;
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [image, setImage] = useState<any>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [urlLink, setURLLInk] = useState<any>(null);

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Required").max(255, "Max 255 characters are allowed").nullable(),
        sku: Yup.string().required("Required").nullable(),
        quantity: Yup.number().required("Required").nullable().positive('Value must be positive'),
        heightIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        lengthIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        widthIn: Yup.number().required("Required").nullable().positive('Value must be positive'),
        weight: Yup.number().required("Required").nullable().positive('Value must be positive'),
        upc: Yup.string().max(12, "Max 12 numbers are allowed").nullable(),
        ean: Yup.string().max(13, "Max 13 numbers are allowed").nullable(),
        mpn: Yup.string().required("Required").nullable(),
        shortDescription: Yup.string().max(255, "Max 255 characters are allowed").nullable(),
        description: Yup.string().max(1500, "Max 1500 characters are allowed").min(200, "min 200 characters are allowed").nullable(),
        manufacturer: Yup.string().required("Required").nullable(),
        brand: Yup.string().required("Required").nullable(),
        condition: Yup.string().required("Required").nullable(),
        minimumPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('Minimum Price must be a number'),
        maximumPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('SRP must be a number'),
        color: Yup.string().required("Required").nullable(),
        countryOfOrigin: Yup.string().required("Required").nullable(),
        multipackQuantity: Yup.string().required("Required").nullable(),
        size: Yup.string().required("Required").nullable(),
        cogs: Yup.number().required("Required").nullable().typeError('COGS must be a number'),
        sellingPrice: Yup.number().required("Required").nullable().positive('Value must be positive').typeError('Buy it now must be a number'),
        category: Yup.string().required("Required").nullable(),
        department: Yup.string().required("Required").nullable(),
        asin: Yup.string().max(14, "Max 14 numbers are allowed").nullable(),
    });

    const formikProductDetails: any = useFormik({
        initialValues: {
            title: "",
            sku: "",
            quantity: null,
            heightIn: null,
            lengthIn: null,
            widthIn: null,
            weight: null,
            upc: "",
            asin: null,
            ean: null,
            mpn: "",
            shortDescription: "",
            description: "",
            variesBy: "",
            manufacturer: "",
            brand: "",
            condition: "",
            retailPrice: null,
            minimumPrice: null,
            maximumPrice: null,
            bulletPoints: null,
            color: "",
            countryOfOrigin: "",
            multipackQuantity: null,
            size: "",
            images: null,
            department: "",
            weightUnit: "lb",
            cogs: null,
            sellingPrice: null,
            category: "",
            subcategory: "",
            weightOZ: null,
        },
        onSubmit: () => submitData(),
        validationSchema: DisplayingErrorMessagesSchema,

    })
    const ColorList: any = [
        { value: "Pink", label: "Pink" },
        { value: "Orange", label: "Orange" },
        { value: "Yellow", label: "Yellow" },
        { value: "Green", label: "Green" },
        { value: "Blue", label: "Blue" },
        { value: "Purple", label: "Purple" },
        { value: "Gold", label: "Gold" },
        { value: "Silver", label: "Silver" },
        { value: "Black", label: "Black" },
        { value: "Gray", label: "Gray" },
        { value: "White", label: "White" },
        { value: "Cream", label: "Cream" },
        { value: "Brown", label: "Brown" },
        { value: "Tan", label: "Tan" }
    ]
    const submitData = async () => {
        let weightInOz: number;
        switch (formikProductDetails.values.weightUnit) {
            case 'kg':
                weightInOz = formikProductDetails.values.weight * 35.27396; // 1 kg = 35.27396 oz
                break;
            case 'g':
                weightInOz = formikProductDetails.values.weight * 0.03527396; // 1 g = 0.03527396 oz
                break;
            case 'lb':
                weightInOz = formikProductDetails.values.weight * 16; // 1 lb = 16 oz
                break;
            case 'oz':
                weightInOz = formikProductDetails.values.weight; // Already in ounces
                break;
            default:
                weightInOz = 0;
        }
        try {
            const payload = {
                ...formikProductDetails.values,
                id: id,
                weightOZ: weightInOz
            }
            const res = await editSkuForm(payload);
            if (res.status === 200) {
                await getTableData();
                toggleDrawer("right", false);
                setOpen(true)
                setShortMessage("Success")
                setMessageText("Product edited successfully")
            }
        } catch (err) {
        }
    }
    /////////////////////////////////////////
    const departments = Array.from(new Set(categoryListData?.map((item: any) => item.department)));
    const categories = Array.from(new Set(categoryListData.filter((item: any) => item.department == formikProductDetails.values.department).map((item: any) => item.category)));
    const subcategories = Array.from(new Set(categoryListData.filter((item: any) => item.department === formikProductDetails.values.department && item.category === formikProductDetails.values.category).map((item: any) => item.subcategory)));
    /////////////////////////////////////////
    const fetchData = async () => {
        try {
            const res = await getPerticularSkuRecord(id);
            if (res.status === 200) {
                formikProductDetails.setValues(res.data.product);
                formikProductDetails.setFieldValue("weightUnit", res.data.product?.weightUnit)
                formikProductDetails.setFieldValue("size", res.data.product?.size)
                setIsChecked(res.data.product.variesBy ? true : false);
                // if (res.data.product.images?.length) {
                //     const data = res.data.product.images.map((ele: any) => {
                //         console.log(Buffer.from(ele).toString('base64'))
                //         return Buffer.from(ele).toString('base64')
                //     })
                // }
                for (const imageUrl of res.data.product.images) {
                    try {
                        const response = await fetch(imageUrl);
                        const blob = await response.blob();
                        const base64 = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        });
                        image.push(base64);
                    } catch (error) {
                        console.error(`Error fetching and converting image: ${error}`);
                    }
                }
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])

    const readImages = (e: ChangeEvent<HTMLInputElement>, func: (images: any) => void) => {
        const files = e.target.files;
        const newImages: any = [];

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();

                reader.onload = function (e: any) {
                    const binaryData = e.target.result as string;
                    const base64String = window.btoa(binaryData);
                    newImages.push({ image: base64String, checked: false });

                    if (newImages.length === files.length) {
                        func([...image, ...newImages]);
                    }
                };
                reader.readAsBinaryString(file);
            }
        }
    }

    const handelDelete = () => {
        const arr = image.filter((ele: any) => !ele.checked);
        setImage(arr)
    }

    const handleCloseOpenModal = () => {
        setOpenModal(true);
    }

    const handleChekBox = (index: any) => {
        const arr = [...image];
        arr[index].checked = !arr[index].checked;
        setImage(arr)
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    function convertImageToBase64(url: string, callback: (base64Data: string) => void) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader: any = new FileReader();
                reader.onload = () => {
                    const base64Data: string = reader?.result?.split(',')[1];
                    callback(base64Data);
                };
                reader.readAsDataURL(blob);
            });
    }
    const [errorMsgImage, setErrorMsgImage] = useState("")

    const convertIntoBase64 = (url?: any) => {
        if (urlLink == null) {
            setErrorMsgImage("Please add image url")
        }
        else {
            convertImageToBase64(url ? url : urlLink, (base64Data) => {
                setImage([...image, { image: base64Data, checked: false }])
                handleCloseModal();
            });
        }
    }

    const [categoriesList2, setCategoriesList2] = useState([])
    const [subCategoriesList, setSubCategoriesList] = useState([])


    return (
        <>
            {openModal && <Modal
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box sx={{ ...styless, width: "500px" }}>
                    <Typography>Add file from URL</Typography>
                    <Typography>Image URL</Typography>
                    <Typography variant="subtitle1" id="simple-modal-description">
                        <TextField
                            type="string"
                            id="standard-required"
                            variant="standard"
                            placeholder="Add Image URL.."
                            fullWidth
                            onChange={(e) => setURLLInk(e.target.value)} value={urlLink} />
                    </Typography>
                    <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{errorMsgImage}</FormHelperText>

                    <Button variant="contained" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="outlined" onClick={() => convertIntoBase64()}>Add File</Button>
                </Box>
            </Modal>}


            <Box>
                <FormikProvider value={formikProductDetails}>
                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500} >Basic Identifiers:</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={3.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Title
                            </InputLabel>
                            <Field name="title" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Listing Title"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                SKU
                            </InputLabel>
                            <Field name="sku" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Enter SKU"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                UPC
                            </InputLabel>
                            <Field name="upc" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        required
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Enter UPC"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                EAN (If applicable)
                            </InputLabel>
                            <Field name="ean" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Enter EAN"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>

                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                MPN
                            </InputLabel>
                            <Field name="mpn" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Enter MPN"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                ASIN (If applicable)
                            </InputLabel>
                            <Field name="asin" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Enter ASIN"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Stack direction='row' mb={5} alignItems='center' >
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Stack direction="row" sx={{ flex: 1 }}>
                            {image?.filter((ele: any) => ele.checked)?.length ?

                                <Stack direction="row" alignItems="center" >
                                    <Checkbox
                                        checked={true}
                                        sx={{

                                            color: "#fff"
                                        }}
                                    />
                                    <Typography variant="h6" fontSize={20} fontWeight={500} >
                                        {image?.filter((ele: any) => ele.checked)?.length} file selected
                                    </Typography>
                                </Stack>
                                : <Typography variant="h6" fontSize={20} fontWeight={500} >Media :</Typography>}


                            {image?.filter((ele: any) => ele.checked)?.length ?
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: "end",
                                    paddingRight: 2.5,
                                    flex: 1
                                }}>
                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: 20,
                                            color: "#D9706C",
                                            textTransform: "capitalize",
                                            padding: 0,
                                            '&:hover': {
                                                backgroundColor: 'unset',
                                            }
                                        }}
                                        onClick={() => handelDelete()}
                                    >
                                        Delete Files
                                    </Button>
                                </Box>
                                : ""}

                        </Stack>
                    </Stack>



                    <Grid container spacing={5} mb={3.5} px={2.5}>
                        <Grid item xs={12}>
                            <Box sx={style.mediaContainer}>
                                {/* <Grid container mb={2.5} justifyContent="center" sx={style.mediaContainer}> */}
                                <Stack direction="column" alignItems="center">
                                    {!image?.length ?
                                        <Stack flexDirection="row" alignItems="center" gap={3.5} mb={1.5}>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant="outlined"
                                                onChange={(e: any) => {
                                                    readImages(e, setImage);
                                                }}
                                                sx={{
                                                    color: "#1A1918", fontWeight: "600", fontSize: "14px", textTransform: "capitalize", borderColor: "rgba(104, 115, 125, 0.48)"
                                                }}
                                            >
                                                Upload New
                                                <VisuallyHiddenInput type="file" />

                                            </Button>
                                            <Typography color="#1A1918" fontWeight="600" onClick={handleCloseOpenModal}
                                            >Add from URL</Typography>
                                        </Stack>
                                        : <></>}

                                    {!image?.length ?

                                        <Typography color="#68737D">Accept Images</Typography>
                                        : ""}
                                </Stack>


                                <Grid container spacing={0.5} gap={2.5}>
                                    {image?.map((image: any, index: any) => (
                                        <Stack sx={{ position: "relative" }}>
                                            <Image key={index} data={image.image} index={index} />

                                            <Checkbox
                                                checked={image.checked}
                                                onChange={() => handleChekBox(index)}
                                                sx={{
                                                    position: "absolute",
                                                    // top: "10px",
                                                    color: "#fff"
                                                }}
                                            />
                                        </Stack>
                                    ))}
                                    {image?.length ?
                                        <Stack sx={{ background: "#fff", alignItems: "center", justifyContent: "center", height: 160, width: 160, gap: 2.5 }}>


                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant="outlined"
                                                onChange={(e: any) => {
                                                    readImages(e, setImage);
                                                }}
                                                sx={{
                                                    color: "#68737D", fontWeight: "300", fontSize: "14px", textTransform: "capitalize", borderColor: "rgba(104, 115, 125, 0.48)"
                                                }}
                                            >
                                                Upload New
                                                <VisuallyHiddenInput type="file" />

                                            </Button>

                                            <Typography color="#68737D" fontWeight="300" onClick={handleCloseOpenModal}>Add from URL</Typography>
                                        </Stack>
                                        : <></>}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Dimensions :</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={3.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Height (in)
                            </InputLabel>

                            <Field name="heightIn" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="number"

                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Height in Inches"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Width (in)
                            </InputLabel>
                            <Field name="widthIn" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Width in Inches"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel required sx={style.inputLabel}>
                                Length (in)
                            </InputLabel>
                            <Field name="lengthIn" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Length in Inches"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Weight
                            </InputLabel>
                            <Stack direction="row" alignItems="end" gap={1.5}>
                                <Field name="weight" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="Enter weight"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {/* <ArrowDropDown /> */}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Field>
                                <Field name="weightUnit" >
                                    {({ field, meta }: any) => (
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={field.value}
                                            fullWidth
                                            onChange={async (e: any) => {
                                                formikProductDetails.setFieldValue("weightUnit", e.target.value)
                                            }}
                                            variant="standard"
                                            sx={{ width: 80 }}
                                        >
                                            <MenuItem value="kg">kg</MenuItem>
                                            <MenuItem value="g">g</MenuItem>
                                            <MenuItem value="lb">lb</MenuItem>
                                            <MenuItem value="oz">oz</MenuItem>
                                        </Select>
                                    )}
                                </Field>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Warehouse Quantity
                            </InputLabel>
                            <Field name="quantity" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Warehouse QTY"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Product Description :</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={3.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Short Description
                            </InputLabel>
                            <Field name="shortDescription" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Provide details here.."
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel sx={style.inputLabel}>
                                Description
                            </InputLabel>
                            <Field name="description" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        multiline
                                        rows={2}
                                        variant="standard"
                                        placeholder="Provide details here.."
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel required sx={style.inputLabel}>
                                Gender / Department
                            </InputLabel>
                            <Field name="department" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        label="Department"
                                        onChange={async (e: any) => {
                                            formikProductDetails.setFieldValue("department", e.target.value)
                                            formikProductDetails.setFieldValue('category', ''); // Reset category on department change
                                            formikProductDetails.setFieldValue('subcategory', ''); // Reset subcategory on department change
                                        }}
                                        variant="standard"
                                        sx={{
                                            'MuiSelect-select': {
                                                '&.MuiInputBase-input': {
                                                    '&.MuiInput-input:focus': {
                                                        backgroundColor: '#fff',
                                                    }
                                                }

                                            }
                                        }}
                                    >
                                        {departments.map((department: any) => (
                                            <MenuItem key={department} value={department}>
                                                {department}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Field>
                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.department} </FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Category
                            </InputLabel>
                            <Field name="category" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={async (e) => {
                                            formikProductDetails.setFieldValue("category", e.target.value);
                                            formikProductDetails.setFieldValue('subcategory', '');
                                            const result = await getSizeListbySize(e.target.value)
                                            setSizeListData(result?.data?.result)
                                        }}
                                        variant="standard"
                                        {...{ error: meta.touched && meta.error }}
                                    >
                                        {categories.map((category: any) => (
                                            <MenuItem key={category} value={category}>
                                                {category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Field>
                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.category} </FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Sub Category
                            </InputLabel>
                            <Field name="subcategory" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={(e) => { formikProductDetails.setFieldValue("subcategory", e.target.value) }}
                                        variant="standard"
                                        {...{ error: meta.touched && meta.error }}
                                    >
                                        {subcategories.map((subcategory: any) => (
                                            <MenuItem key={subcategory} value={subcategory}>
                                                {subcategory}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                )}
                            </Field>
                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.subcategory} </FormHelperText>
                        </Grid>
                    </Grid>

                    {/* <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Variants (if applicable) :</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={3.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Does it vary?
                            </InputLabel>
                            <FormGroup sx={{ flexDirection: 'row' }}>
                                <FormControlLabel control={<Checkbox checked={!isChecked} onClick={() => { formikProductDetails.setFieldValue("variesBy", null); setIsChecked(!isChecked) }} />} label="No" />
                                <FormControlLabel control={<Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />} label="Yes" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Please select how does it vary
                            </InputLabel>
                            <Field name="variesBy" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Select Specify reasons "
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>

                    </Grid> */}

                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Brand & Manufacturer details :</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={2.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Manufacturer
                            </InputLabel>
                            <Field name="manufacturer" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Manufacturer Name"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Brand
                            </InputLabel>
                            <Field name="brand" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Brand name"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel sx={style.inputLabel} required>
                                Product Condition
                            </InputLabel>
                            <Field name="condition" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={(e) => { formikProductDetails.setFieldValue("condition", e.target.value) }}
                                        variant="standard"
                                        {...{ error: meta.touched && meta.error }}
                                    >
                                        <MenuItem value="New">New</MenuItem>
                                        <MenuItem value="Used">Used</MenuItem>
                                        <MenuItem value="Refurbished">Refurbished</MenuItem>
                                    </Select>
                                )}
                            </Field>
                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.condition} </FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Bullet Points
                            </InputLabel>
                            <Field name="bulletPoints" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Mention in points"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Pricing :</Typography>
                    </Stack>

                    <Grid container spacing={5} mb={2.5} px={2.5}>

                        <Grid item xs={4}>
                            <InputLabel required sx={style.inputLabel}>
                                COGS
                            </InputLabel>
                            <Field name="cogs">
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="$ 0.00"
                                        fullWidth
                                        required
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>

                        {/* <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel}>
                                Retail Price
                            </InputLabel>
                            <Field name="retailPrice" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Retail Price $"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid> */}
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Minimum Price
                            </InputLabel>
                            <Field name="minimumPrice" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="$ 0.00"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                SRP
                            </InputLabel>
                            <Field name="maximumPrice" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="$ 0.00"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel required sx={style.inputLabel}  >
                                Buy it now
                            </InputLabel>
                            <Field name="sellingPrice" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="string"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="$ 0.00"
                                        fullWidth
                                        required
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Stack direction='row' mb={5} alignItems='center'>
                        <Divider orientation="vertical" variant="middle" flexItem sx={style.blueVerticalDash} />
                        <Typography variant="h6" fontSize={20} fontWeight={500}>Attribute :</Typography>
                    </Stack>
                    <Grid container spacing={5} mb={2.5} px={2.5}>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Color
                            </InputLabel>
                            <Field name="color" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={(e) => { formikProductDetails.setFieldValue("color", e.target.value) }}
                                        variant="standard"
                                        {...{ error: meta.touched && meta.error }}
                                    >
                                        {
                                            ColorList.map((v: any) => {
                                                return (
                                                    <MenuItem value={v?.value}>{v?.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Country of Origin
                            </InputLabel>

                            <Field name="countryOfOrigin" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={(e) => { formikProductDetails.setFieldValue("countryOfOrigin", e.target.value) }}
                                        variant="standard"
                                        sx={{
                                            'MuiSelect-select': {
                                                '&.MuiInputBase-input': {
                                                    '&.MuiInput-input:focus': {
                                                        backgroundColor: '#fff',
                                                    }
                                                }

                                            }
                                        }}
                                    >
                                        {countryList.map((ele: any) => (<MenuItem value={ele}>{ele}</MenuItem>))}
                                    </Select>
                                )}
                            </Field>
                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formikProductDetails.errors.countryOfOrigin} </FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Multipack Quantity
                            </InputLabel>
                            <Field name="multipackQuantity" >
                                {({ field, meta }: any) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        id="standard-required"
                                        variant="standard"
                                        placeholder="Multipack Quantity"
                                        fullWidth
                                        {...{ error: meta.touched && meta.error }}
                                        helperText={meta.touched && meta.error && meta.error}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel sx={style.inputLabel} required>
                                Size
                            </InputLabel>
                            <Field name="size" >
                                {({ field, meta }: any) => (
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={field.value}
                                        fullWidth
                                        onChange={async (e) => {
                                            formikProductDetails.setFieldValue("size", e.target.value)
                                        }}
                                        variant="standard"
                                        sx={{
                                            'MuiSelect-select': {
                                                '&.MuiInputBase-input': {
                                                    '&.MuiInput-input:focus': {
                                                        backgroundColor: '#fff',
                                                    }
                                                }

                                            }
                                        }}
                                        placeholder="Select Size"
                                    >
                                        {sizeListData?.map((ele: any) => (<MenuItem value={ele?.size}>{ele?.size}</MenuItem>))}
                                    </Select>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Stack gap={2.5} direction="row" justifyContent="end" mt={4} px={2.5}>
                        <Button
                            variant="contained"
                            sx={{
                                ...style.saveCancelBtn,
                                maxWidth: 136,
                                background:
                                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                            }}
                            onClick={() => formikProductDetails.handleSubmit()}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                ...style.saveCancelBtn,
                                maxWidth: 136,
                                border: "1px solid rgba(104, 115, 125, 0.48)",
                                color: "#68737D",
                            }}
                            onClick={() => toggleDrawer("right", false)}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </FormikProvider>
            </Box >
        </>)
}
export default EditSkuForm;