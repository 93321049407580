import { Box, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const style = {
  breadcrumbContainer: {
    ".MuiBreadcrumbs-separator": {
      display: "none",
    },
  },
};
const CustomBreadcrumbs = (props: any) => {
  const navigate = useNavigate();
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={style.breadcrumbContainer}>
      <Box>
        <Link
          underline="none"
          color="#68737D"
          fontSize={12}
          onClick={() => navigate(props.firstFunction)}
          sx={{
            px: 1.5,
            border: "1px solid #E0E0E0",
            "&:hover": { borderColor: "#4079FC", color: "#1A1918" },
          }}
        >
          {props.firstName}
        </Link>
      </Box>
      <Box>
        <Link
          underline="none"
          color="#1A1918"
          sx={{
            px: 1.5,
            border: "1px solid #4079FC",
            "&:hover": { color: "#1A1918" },
          }}
          fontSize={12}
          aria-current="page"
        >
          {props.secondName}
        </Link>
      </Box>
    </Breadcrumbs>
  );
};
export default CustomBreadcrumbs;
