import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import HeaderListing from "../../../components/headerListing";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import AproovedUserManagement from "./AproovedUserManagement";
import UserReqestManagement from "./UserRequestManagement";
import "./index.module.scss";
import styles from "./index.module.scss";
import ManageSubscriptionScreen from "./manageSubscriptionScreen";

export const style = {
  tabContainer: {
    ".MuiTabs-indicator": {
      backgroundColor: "#000000",
      height: "1px",
    },
  },
  tabList: {
    ".MuiButtonBase-root": {
      color: "#68737D",
      fontWeight: 500,
      fontSize: 14,
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#1E1F21",
      },
    },
  },
  borderStack: {
    border: "1px solid #E0E0E0",
    borderRadius: 0.5,
    height: 40,
  },

  fiiledBtn: {
    background: "linear-gradient(138deg, #0038BA 0%, #4F18FF 100%)",
    borderRadius: 0.5,
    textTransform: "capitalize",
    height: 40,
    ml: 2,
    boxShadow: "unset",
    fontSize: 14,
    
    '&:hover': {
      boxShadow: 'unset'
    }
  },
  outlineNewBtn: {
    background: '#fff',
    border: '1px solid #E0E0E0',
    '&:hover': {
      backgroundColor: 'unset',
      boxShadow: 'unset'
    }
  },

  dialogContainer: {
    ".MuiInputLabel-root": {
      color: "#1A1918",
      fontSize: 14,
      fontWeight: 500,
      pl: 1.5,
    },
    ".MuiInputBase-root": {
      pl: 1.5,
      "&:before": {
        borderBottom: "1px solid #E0E0E0",
      },
    },
    ".Mui-error": {
      pl: 1.5,
    },
  },
  modalBtn: {
    width: "100%",
    maxWidth: "100px",
    borderRadius: 0.5,
    textTransform: "capitalize",
    fontSize: 14,
    height: 34,
  },
};

const SuperAdminDashboard = () => {
  const { isLoading, tabsSubData, tabsAdded, setSelectedIndex } = useAuth()
  const { getCount } = useClientAPI();
  const [count, setCount] = useState<any>([
    {
      ApprovedUsers: 0
    },
    {
      PendingUsers: 0
    }
  ])
  const [value, setValue] = React.useState("1");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const loadInitialData = async () => {

    const res: any = await getCount();
    setCount(res.data.result);
  };

  useEffect(() => {
    loadInitialData();
    setSelectedIndex(0)
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const [editState, setEditState] = useState<any>(false)
  const [showNewSubscription, setShowNewSubscription] = React.useState(false);

  return (
    <>
      <HeaderListing />
      {tabsSubData.length && tabsAdded ? (
        <>
        </>
      ) : (
        <Box
          className={styles.contentMain}
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            py: 2.5,
            px: isMobile ? 2 : 5,
          }}>
          <Stack mb={1.5}>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={2}
            >
              Clients
            </Typography>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={style.tabContainer} display='flex' justifyContent='space-between' alignItems='flex-end'>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  sx={style.tabList}
                >
                  <Tab label={`Clients ${count[0].ApprovedUsers}`} value="1" />
                  <Tab
                    label={`Request ${count[1].PendingUsers}`}
                    value="2"
                  />
                  <Tab label="Subscription" value="3" />
                </TabList>
                {value == "3" ?
                  <Button
                    variant="contained"
                    sx={{ ...style.fiiledBtn, mb: 0.5 }}
                    onClick={() => { setEditState(false); setShowNewSubscription(true) }}
                  >
                    <AddIcon />
                    New
                  </Button>
                  :
                  <></>
                }
              </Box>
              <Divider
                sx={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#E0E0E0",
                  borderBottomWidth: "inherit",
                  opacity: 1,
                }}
              />
              <TabPanel value="1" sx={{ px: 0, py: 2 }}>
                <AproovedUserManagement fetchLatestCount={loadInitialData} />
              </TabPanel>
              <TabPanel value="2" sx={{ px: 0, py: 2 }}>
                <UserReqestManagement fetchLatestCount={loadInitialData} />
              </TabPanel>
              <TabPanel value="3" sx={{ px: 0, py: 2 }}>
                <ManageSubscriptionScreen
                  editState={editState}
                  setEditState={setEditState}
                  showNewSubscription={showNewSubscription}
                  setShowNewSubscription={setShowNewSubscription} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>)}
    </>
  );
};
export default SuperAdminDashboard;
