import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import HeaderListing from "../../../components/headerListing";
import { useAuth } from "../../../shared/provider/authprovider";
import DestinationPanelBoard from "./DestinationPanelBoard";
import SourcePanelBoard from "./SourcePanelBoard";
import styles from "./index.module.scss";

export const style = {
  tabContainer: {
    ".MuiTabs-indicator": {
      backgroundColor: "#000000",
      height: "1px",
    },
  },
  tabList: {
    ".MuiButtonBase-root": {
      color: "#68737D",
      fontWeight: 500,
      fontSize: 16,
      textTransform: "capitalize",
      py: '10px',
      px: 2,
      mr: '10px',
      '&:last-child': {
        mr: 0,
      },
      "&.Mui-selected": {
        color: "#1A1918",
      },
    },
  },
  borderStack: {
    border: "1px solid #E0E0E0",
    borderRadius: 0.5,
    height: 32,
  },
  fiiledBtn: {
    background: "linear-gradient(95.28deg, #0038BA -5.39%, #4F18FF 102.93%)",
    borderRadius: 0.5,
    textTransform: "capitalize",
    height: 32,
    width: 100,
    ml: 2,
    boxShadow: "unset",
    fontSize: 14,
  },
};

export const styless = {
  fiiledBtn: {
    background: "linear-gradient(95.28deg, #0038BA -5.39%, #4F18FF 102.93%)",
    borderRadius: 0.5,
    textTransform: "capitalize",
    height: 32,
    ml: 2,
    boxShadow: "unset",
    fontSize: 14,
  },
};

const IntegraionsDashbaord = () => {
  const { setSelectedIndex, setIntegrationTabIndex, integrationTabIndex } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const [prefix, setPrefix] = useState("");
  const navigate = useNavigate();
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => { };
  // const [value, setValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    // setValue(newValue);
    setIntegrationTabIndex(newValue)
  };
  useEffect(() => {
    // setIntegrationTabIndex("1")
    setSelectedIndex(8)
  }, [])
  
  return (
    <>
      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{ flexGrow: 1, backgroundColor: '#e9f0fc', py: 2.5, px: isMobile ? 2 : 5 }}
      >
        <Box display="flex" justifyContent="space-between" mb={1.5}>
          <Stack>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={2}
            >
              Integrations Catalog
            </Typography>
            <Typography variant="subtitle2" fontSize={14} fontWeight={500} color={isMobile ? "#68737D":"#1A1918" }>
              In order for Resale connect functionalities to start working
              you'll need to connect some data sources. Explore all our
              connectors or click on a functionality below so we can guide you
              on what connectors you need to integrate to get things going.
            </Typography>

            {/* <Box ml={10} className={`${styles.searchInput}`}>
              <Stack direction="row" alignItems="center">
                <Search className={styles.searchIcon} />
                <input
                  type="search"
                  placeholder="Search"
                  value={prefix}
                  onKeyPress={(event) => handleKeypress(event)}
                  onChange={(event) => setPrefix(event.target.value)}
                  style={{ width: "100%" }}
                />
              </Stack>
            </Box> */}
          </Stack>
          {/* <Stack>
            <Button
              variant="contained"
              sx={styless.fiiledBtn}
              onClick={() => {
                navigate("/listing-integration-list");
              }}
            >
              <AddIcon />
              New Integration
            </Button>
          </Stack> */}
        </Box>
        <Box sx={{ width: "100%" }}>
          <TabContext value={integrationTabIndex}>
            <Box sx={{ ...style.tabContainer, borderBottom: 1, borderColor: '#fff' }} >
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                sx={style.tabList}
              >
                <Tab label="Source" value="1" />
                <Tab label="Destination" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, mt: '20px' }}>
              <SourcePanelBoard />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0, mt: '20px' }}>
              <DestinationPanelBoard />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
export default IntegraionsDashbaord;
