// import loaderGif from "../assets/loader.gif";
import { CircularProgress } from "@mui/material";
// import "../../";
const Loader = () => {
  return (
    <>
      <div className="loaderContainer">
        <CircularProgress size="md" sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          height: '30px',
          width: '30px',
          color: '#4079FC',
        }} />
      </div>
    </>
  );
};

export default Loader;
