import {
  Box,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import HeaderListing from "../../../components/headerListing";
import styles from "./index.module.scss";
import TopPlatformChart from "./charts/TopPlatformChart/TopPlatformCharta";
import TopSellingBrandChart from "./charts/TopSellingBrands/TopSellingBrandsChart";
import TotalSalesChart from "./charts/TotalSalesChart/TotalSalesChart";
import TopSellingCategoryChart from "./charts/TopSellingCategory/TopSellingCategoryChart";
import { useEffect } from "react";
import { useAuth } from "../../../shared/provider/authprovider";
import HtmlBarChart from "./htmlBarChart";
import { CustomTabPanel } from "../ListingCustomerDashboard";
import TotalSalesDetailed from "./DetaliledChartData/TotalSalesDetailed";
import TopPlatformsDetailed from "./DetaliledChartData/TopPlatformsDetailed";
import TotalOrdersChart from "./charts/TotalOrders/TotalOrdersChart";
import UnitPerOrderChart from "./charts/UnitPerOrder/UnitPerOrderChart";
import TopSellingCategoryDetailed from "./DetaliledChartData/TopSellingCategoryDetailed";
import TopSellingBrandsDetailed from "./DetaliledChartData/TopSellingBrandsDetailed";
import UnitPerOrderDetailed from "./DetaliledChartData/UnitPerOrderDetailed";
import TotalOrdersDetailed from "./DetaliledChartData/TotalOrdersDetailed";
import DetailScreenComponent from "../../detailScreenComponent";
import { ReactComponent as OpenIcon } from "../../../../src/assets/images/icons/share.svg";

export const style = {
  listingSummaryBox: {
    mt: 1.5,
    borderWidth: "2px 0px",
    borderStyle: "solid",
    borderColor: "#E0E0E0",
    mb: 5,
    ".MuiStack-root": {
      flex: 1,
      px: 2.5,
      py: 1,
    },
    ".MuiDivider-root": {
      borderWidth: 2,
      borderRightWidth: "inherit",
    },
  },
  graphCard: {
    // border: '1px solid #E8E8E8',
    // borderRadius: 0.5,
    ".MuiPaper-root": {
      boxShadow: "unset",
      border: "1px solid #E8E8E8",
      borderRadius: 0.5,
      p: 1.5,
      height: 450,
    },
    ".headingStack": {
      borderWidth: "1px 1px 0px",
      borderStyle: "solid",
      borderColor: "transparent",
      transition: ".2s ease-in",
    },
    "&:hover": {
      ".headingStack": {
        borderColor: "#E8E8E8",
        borderRadius: 0.5,
      },
    },
  },
};

const Index = () => {
  const {
    setSelectedIndex,
    setTabsSubData,
    tabsSubData,
    tabsAdded,
    setTabsAdded,
    value,
    setValue,
  } = useAuth();
  useEffect(() => {
    setSelectedIndex(3);
  }, []);

  const addPanels = async (item: any) => {
    setTabsSubData([
      ...tabsSubData,
      { ...item, title: item?.title, key: "SalesDashboard" },
    ]);
    setValue(tabsSubData.length);
    setTabsAdded(true);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  return (
    <>
      <HeaderListing />
      {tabsSubData.length && tabsAdded ? (
        <>{/* <DetailScreenComponent /> */}</>
      ) : (
        <Box
          className={styles.contentMain}
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            py: 2.5,
            px: isMobile ? 2 : 5,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={1.5}>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={2}
            >
              Sales Report
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              fontSize={14}
              fontWeight={500}
              color="#68737D"
            >
              Sales Summary
            </Typography>

            <Box
              display="flex"
              sx={{
                ...style.listingSummaryBox,
                justifyContent: "space-between",
              }}
            >
              <Stack>
                <Typography
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                  mb="5px"
                >
                  Total Sales
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight={500}
                  color="#1A1918"
                >
                  0
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack>
                <Typography
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                  mb="5px"
                >
                  GMV
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight={500}
                  color="#1A1918"
                >
                  0
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Stack>
                <Typography
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                  mb="5px"
                >
                  AOV
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight={500}
                  color="#1A1918"
                >
                  0
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Stack>
                <Typography
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                  mb="5px"
                >
                  AUV
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight={500}
                  color="#1A1918"
                >
                  0
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Stack>
                <Typography
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                  mb="5px"
                >
                  UPO
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight={500}
                  color="#1A1918"
                >
                  0
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Grid container rowSpacing={5} columnSpacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Total Sales
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card onClick={() => addPanels({ title: "Total Sales" })}>
                  <TotalSalesChart />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Top Platform
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card
                  onClick={() => addPanels({ title: "Top Platforms" })}
                  sx={style.graphCard}
                >
                  <HtmlBarChart />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Top Selling Brands
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card
                  onClick={() => addPanels({ title: "Top Selling Brands" })}
                  sx={style.graphCard}
                >
                  <TopSellingBrandChart />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Top Selling Category
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card
                  onClick={() => addPanels({ title: "Top Selling Category" })}
                  sx={style.graphCard}
                >
                  <TopSellingCategoryChart />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Total Orders
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card
                  onClick={() => addPanels({ title: "Total Orders" })}
                  sx={style.graphCard}
                >
                  <TotalOrdersChart />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.graphCard}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 2.5, py: 0.5 }}
                  className="headingStack"
                >
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#1E1F21"
                  >
                    Units per order
                  </Typography>
                  <IconButton aria-label="open graph">
                    <OpenIcon />
                  </IconButton>
                </Stack>
                <Card
                  onClick={() => addPanels({ title: "Unit Per Order" })}
                  sx={style.graphCard}
                >
                  <UnitPerOrderChart />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default Index;
