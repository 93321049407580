import moment from "moment";
import styles from "./index.module.scss";
import { ReactComponent as True } from "../../../../assets/images/icons/true.svg";
import { ReactComponent as False } from "../../../../assets/images/icons/false.svg";
import {
  Link,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useEffect, useState } from "react";

export const style = {
  tableData: {
    fontSize: 14,
    fontWeight: 300,
  },
};
interface IProps {
  data: Array<object>;
  page?: any;
  columns: any;
  dcid?: any;
  value?: any;
}

const DetailedReportTable = (props: IProps) => {
  const { data, page, columns, dcid, value } = props;
  const [tableColumns, setTableColumns] = useState<any>(columns);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (dcid === 17) {
      if (value === "3") {
        setTableColumns(
          tableColumns.filter((ele: any) => ele.apiKey !== "marketplaceFee")
        );
        return;
      }
      const source = { header: "Postage($)", apiKey: "postage", symbol: "$" };
      const target: any = tableColumns.find(
        (obj: any) => obj.apiKey === "marketplaceFee"
      );
      Object.assign(target, source);
    } else if (dcid === 19 || dcid === 23) {
      setTableColumns(
        tableColumns.filter((ele: any) => ele.apiKey !== "resaleFee")
      );
    }
  }, [dcid]);

  const TitleTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#0038BA",
      minWidth: 200,
      maxWidth: 200,
      fontSize: 14,
      fontWeight: 300,
      border: "1px solid #e0e0e0",
      letterSpacing: "normal",
    },
  }));
  const alignRight = { textAlign: "right" };
  const alignLeft = { textAlign: "left" };

  return (
    <>
      {/* <div className={styles.contentMain}> */}
      <div className={`${styles.heightG} ${styles.payoutRepoTable}`}>
        <table className={styles.tableH}>
          <thead
            className={`${styles.thead} rounded ${styles.textTruncateHeader}`}
          >
            <tr>
              <td></td>
              {tableColumns.map((item: any) => (
                <td>
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={600}
                    sx={item.symbol ? alignRight : alignLeft}
                  >
                    {item.header}
                  </Typography>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {data?.length
              ? data?.map((ele: any, index: number) => (
                  <tr>
                    <td>{page + (index + 1)}</td>
                    {tableColumns.map((item: any) => (
                      <TitleTooltip
                        title={item.apiKey === "title" ? ele[item.apiKey] : ""}
                        placement="top"
                        arrow
                      >
                        <td>
                          <Typography
                            variant="body2"
                            fontSize={14}
                            fontWeight={300}
                            sx={item.symbol ? alignRight : alignLeft}
                          >
                            {item.apiKey === "orderDate"
                              ? moment(ele[item.apiKey]).format("MMM DD, yyyy")
                              : item?.symbol
                              ? formatter.format(ele[item.apiKey])
                              : ele[item.apiKey]
                              ? ele[item.apiKey]
                              : ""}
                          </Typography>
                        </td>
                      </TitleTooltip>
                    ))}
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        {data?.length === 0 && (
          <Typography
            variant="body2"
            textAlign="center"
            fontSize={14}
            fontWeight={300}
          >
            No records found
          </Typography>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default DetailedReportTable;
