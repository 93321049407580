import { useAuth } from "../provider/authprovider";

export interface IUseAuth {
  token: any | null;
}
export const useSKuFormAPI = () => {
  const { apinew } = useAuth();

  const skuDashboardData = async (payload: any) => {
    try {
      const requestPayload = new URLSearchParams(payload).toString();
      const res = await apinew.get(`/api/Products/list?${requestPayload}`);
      return res;
    } catch (error: any) {
    }
  };

  const addNewSkuForm = async (payload: any) => {
    try {
      const res = await apinew.post(`/api/Products`, payload);
      return res;
    } catch (error: any) {
    }
  };

  const editSkuForm = async (payload: any) => {
    try {
      const res = await apinew.put(`/api/Products/${payload.id}`, payload);
      return res;
    } catch (error: any) {
    }
  };

  const deleteSkuRecord = async (payload: any) => {
    try {
      const res = await apinew.delete(`/api/Products/${payload.id}`);
      return res;
    } catch (error: any) {
    }
  };

  const getPerticularSkuRecord = async (payload: any) => {
    try {
      const res = await apinew.get(`/api/Products/${payload}`);
      return res;
    } catch (error: any) {
    }
  };

  const uploadExcel = async (payload: any) => {
    try {
      const res = await apinew.post(`/api/Products/upload`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (error: any) {
    }
  };
  const scanUPCForDetails = async (upc: any) => {
    try {
      const res = await apinew.get(`/api/Scan/GetSkuFromUPC?upc=${upc}`);
      return res;
    } catch (error: any) {
    }
  };
  const exportSkuFormDetails = async (dcid: any, payload: any) => {
    try {
      const res = await apinew.post(`/api/Products/Export/Channel?dcid=${dcid}`, payload, {
        responseType: "blob",
      });
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
    }
  };
  const exportSkuFormDetailstoCSV = async (payload: any) => {
    try {
      const res = await apinew.post(`/api/Products/Export?ReportType=2`, payload);
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
    }
  };
  const exportSkuFormDetailsPoshmark = async (dcid: any, payload: any) => {
    try {
      const res = await apinew.post(`/api/Products/Export/Poshmark?dcid=${dcid}`, payload, {
        responseType: "blob",
      });
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
    }
  };
  const deleteSkuFormRecord = async (id: any) => {
    try {
      const result = await apinew?.delete(`/api/Products/${id}`);
      return result;
    } catch (error: any) {
      // handleErrorSaas(error)
    }
  };
  const categoryList = async () => {
    try {
      const res = await apinew.post(`/api/Products/Category/Taxonomy`,);
      return res
    } catch (error: any) {
    }
  };
  const getSizeList = async () => {
    try {
      const res = await apinew.get(`/api/Products/Size/Taxonomy`);
      return res;
    } catch (error: any) {
    }
  };
  const getSizeListbySize = async (category: any) => {
    try {
      const res = await apinew.get(`/api/Products/Size/Taxonomy/${category}`);
      return res;
    } catch (error: any) {
    }
  };
  return {
    skuDashboardData,
    getPerticularSkuRecord,
    deleteSkuRecord,
    editSkuForm,
    addNewSkuForm,
    uploadExcel,
    scanUPCForDetails,
    exportSkuFormDetails,
    exportSkuFormDetailsPoshmark,
    deleteSkuFormRecord,
    exportSkuFormDetailstoCSV,
    categoryList,
    getSizeList,
    getSizeListbySize
  } as const;
};
