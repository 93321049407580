import { Box, Button, Chip, Divider, Grid, InputLabel, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import shopifyIntegration from "../../../assets/images/shopify-integration.svg";
import CustomBreadcrumbs from "../../../components/customBreadcrumbs";
import HeaderListing from "../../../components/headerListing";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";

export const style = {
    switch: {
        width: 34,
        height: 14,
        padding: "6px",
        overflow: "visible",
        ".MuiButtonBase-root": {
            "&.MuiSwitch-switchBase": {
                top: "-4px",
                color: "#68737D",
                "&.Mui-checked": {
                    color: "#4F18FF",
                },

                "&.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#A0A7AD",
                    opacity: 1,
                },
                "&:hover": {
                    backgroundColor: "unset",
                },
            },
        },
        ".MuiSwitch-track": {
            backgroundColor: "#A0A7AD",
            opacity: 1,
        },
        ".MuiSwitch-thumb": {
            width: 14,
            height: 14,
            boxShadow: "unset",
        },
    },
    switchBox: {
        borderRadius: 0.5,
        border: "1px solid #E0E0E0",
        px: 1.5,
        height: 40,
        ".MuiTypography-root": {
            fontSize: 14,
            fontWeight: 500,
            color: "#000",
        },
    },
    greyBox: {
        borderRadius: 0.5,
        backgroundColor: "#EFEFEF",
        p: 2.5,
        ".MuiInputLabel-root": {
            fontSize: 14,
            fontWeight: 500,
            color: "#1A1918",
            mb: 1,
        },
        ".MuiInput-root": {
            p: "10px",
            "&:before": {
                borderBottom: "2px solid #E0E0E0",
            },
            "&::placeholder": {
                color: "red",
            },
        },
    },
    saveCancelBtn: {
        width: "100%",

        px: 2,
        py: 1.5,
        borderRadius: 0.5,
        boxShadow: "none",
        fontSize: 14,
        fontWeight: 300,
        textTransform: "capitalize",
        height: 34,
    },
};

const Index = () => {
    const { postNewDestinationIntegration, getIndividualData, integrationsAccountsList, getIntegrationsAccountsList, putDestinationIntegration } = useIntegrationsSAAS();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const isDesktop = useMediaQuery(theme.breakpoints.up(1280));
    const isTablet = !isMobile && !isDesktop;
    const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
    const { name } = useParams();
    const userId = localStorage.getItem("userId");
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required("Required").nullable(),
        refreshToken: Yup.string().required("Required").nullable(),
        authorization: Yup.string().required("Required").nullable(),
        region: Yup.string().required("Required").nullable(),
        name: Yup.string().required("Required").nullable(),
    });

    interface IInitials {
        userName: string,
        name: string,
        refreshToken: string,
        authorization: string,
        region: string,
        enableInventoryInfo: boolean,
        enableGetTracking: boolean,
        enableGetOrders: boolean
    }

    const intials: IInitials = {
        userName: "",
        name: "",
        refreshToken: "",
        authorization: "",
        region: "",
        enableInventoryInfo: false,
        enableGetTracking: false,
        enableGetOrders: false
    }

    const formik = useFormik({
        initialValues: intials,
        validationSchema: validationSchema,
        onSubmit: () => submitData(),
    });

    const submitData = async () => {
        try {
            const payload = {
                ...formik?.values,
                sourceDestinationType: 1,
                accountType: integrationsAccountsList?.find((ele: any) => ele.name === name)?.id,
                userId: userId,
                id: location?.state?.id
            }
            const res = location?.state?.id ? await putDestinationIntegration(payload) : await postNewDestinationIntegration(payload);
            if (res?.status == 200) {
                if (location?.state?.id) {
                    navigate(`/listing-configure-destination-integration/${formik.values.name}`, { state: { id: location?.state?.id, isFirstTime: isFirstTime } })
                } else {
                    navigate(`/listing-configure-destination-integration/${formik.values.name}`, { state: { id: res.data.result, isFirstTime: isFirstTime } })
                }
            }
        } catch (err) {
        }
    };

    const location = useLocation();
    const getData = async () => {
        try {
            const res: any = await getIndividualData(location?.state?.id);
            console.log(res);
            if (res?.status === 200) {
                formik.setFieldValue("name", res.data?.result.name);
                formik.setFieldValue("userName", res.data?.result.userName);
                formik.setFieldValue("region", res.data?.result.region);
                formik.setFieldValue("authorization", res.data?.result.authorization);
                formik.setFieldValue("refreshToken", res.data?.result.refreshToken);
                formik.setFieldValue("enableGetOrders", res.data?.result.enableGetOrders);
                formik.setFieldValue("enableGetTracking", res.data?.result.enableShipmentInfo);
                formik.setFieldValue("enableInventoryInfo", res.data?.result.enableInventoryInfo);
            }
        } catch (err) {
        }
    }

    const getAccountList = async () => {
        await getIntegrationsAccountsList();
    }

    useEffect(() => {
        if (location?.state?.id) {
            getData();
        }
    }, [location?.state?.id])

    useEffect(() => {
        if (location?.state?.isFirstTime) {
            setIsFirstTime(location?.state?.isFirstTime)
        }
    }, [location?.state?.isFirstTime])

    useEffect(() => {
        getAccountList();
    }, [])

    return (
        <>
            <HeaderListing />
            <Box
                className={styles.contentMain}
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: "#fff",
                }}
            >
                <Stack
                    px={1.5}
                    py={2}
                    justifyContent="center"
                    sx={{
                        backgroundColor: "#FAFBFC",
                        borderBottom: "1px solid #E0E0E0",
                        height: 44,
                    }}
                >
                    <CustomBreadcrumbs
                        firstName={"Integrations"}
                        firstFunction={-2}
                        secondName={name}
                    />
                </Stack>
                <Box
                    p={isMobile ? 2 : isTablet ? 3 : 0}
                    height={isDesktop ? "calc(100vh - 104px)" : "unset"}
                >
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        border={isTablet ? "1px solid #E0E0E0" : "unset"}
                        height={isDesktop ? "calc(100vh - 104px)" : "unset"}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: isMobile ? "unset" : 240,
                                px: 2.5,
                                py: isDesktop ? 5 : 2.5,
                                mb: isMobile ? 2.5 : 0,
                                border: isMobile ? "1px solid #E0E0E0" : "unset",
                            }}
                        >
                            <Box
                                display={isMobile ? "flex" : "unset"}
                                justifyContent="space-between"
                            >
                                <Stack mr={isMobile ? "30px" : 0} alignItems="center">
                                    <img
                                        src={shopifyIntegration}
                                        style={{ width: "100%", maxWidth: isMobile ? 100 : 150 }}
                                    />
                                </Stack>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontSize={18}
                                        fontWeight={500}
                                        color="#000"
                                        mt={isMobile ? 0 : 5}
                                        mb={2.5}
                                    >
                                        Integration Capability
                                    </Typography>
                                    <Stack rowGap={4}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Orders
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={formik.values.enableGetOrders}
                                                onChange={(e: any) => formik.setFieldValue("enableGetOrders", e.target.checked)}
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Inventory
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={formik.values.enableInventoryInfo}
                                                onChange={(e: any) =>
                                                    formik.setFieldValue("enableInventoryInfo", e.target.checked)
                                                }
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Tracking No.
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={formik.values.enableGetTracking}
                                                onChange={(e: any) =>
                                                    formik.setFieldValue("enableGetTracking", e.target.checked)
                                                }
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>

                        
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                backgroundColor: "#fff",
                                py: isMobile ? 0 : 2.5,
                                px: isMobile ? 0 : isTablet ? 2.5 : 5,
                                borderLeft: isMobile ? "none" : "1px solid #E0E0E0",
                            }}
                        >
                            <FormikProvider value={formik}>
                                <Stack direction="row" alignItems="center" mb={2}>
                                    <Chip
                                        label="1"
                                        sx={{
                                            fontSize: 18,
                                            width: 22,
                                            height: 22,
                                            ".MuiChip-label": { px: 0.5 },
                                        }}
                                    />
                                    <Typography
                                        ml={1.5}
                                        fontSize={18}
                                        fontWeight={500}
                                        color="#000"
                                    >
                                        Authentication
                                    </Typography>
                                </Stack>
                                <Box sx={style.greyBox}>
                                    <Grid container rowGap="30px" columnSpacing={5}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel required> Integration Name</InputLabel>
                                            <Field name="name">
                                                {({ field, meta }: any) => (
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                        placeholder="Enter integration name"
                                                        {...field}
                                                        // sx={style.textField}
                                                        {...{ error: meta.touched && meta.error }}
                                                        helperText={
                                                            meta.touched && meta.error && meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel required>User Name</InputLabel>
                                            <Field name="userName">
                                                {({ field, meta }: any) => (
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                        placeholder="Enter user name"
                                                        {...field}
                                                        // sx={style.textField}
                                                        {...{ error: meta.touched && meta.error }}
                                                        helperText={
                                                            meta.touched && meta.error && meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel required> Refresh Token</InputLabel>
                                            <Field name="refreshToken">
                                                {({ field, meta }: any) => (
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                        type="password"
                                                        placeholder="Enter refresh token"
                                                        {...field}
                                                        {...{ error: meta.touched && meta.error }}
                                                        helperText={
                                                            meta.touched && meta.error && meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel required>Authorization Token</InputLabel>
                                            <Field name="authorization">
                                                {({ field, meta }: any) => (
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                        {...field}
                                                        type="password"
                                                        placeholder="Enter authorization token"
                                                        {...{ error: meta.touched && meta.error }}
                                                        helperText={
                                                            meta.touched && meta.error && meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel required> Region</InputLabel>
                                            <Field name="region">
                                                {({ field, meta }: any) => (
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="standard"
                                                        required
                                                        fullWidth
                                                        placeholder="Enter region"
                                                        {...field}
                                                        {...{ error: meta.touched && meta.error }}
                                                        helperText={
                                                            meta.touched && meta.error && meta.error
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Stack gap={2.5} direction={isMobile ? 'column' : "row"} justifyContent="end" mt={4}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            ...style.saveCancelBtn,
                                            maxWidth: isMobile ? 'unset' : 136,
                                            background:
                                                "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                                        }}
                                        onClick={async () => {
                                            formik.handleSubmit();
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            ...style.saveCancelBtn,
                                            maxWidth: isMobile ? 'unset' : 136,
                                            border: "1px solid rgba(104, 115, 125, 0.48)",
                                            color: "#68737D",
                                        }}
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </FormikProvider>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
export default Index;
