import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  Link,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HeaderListing from "../../../components/headerListing";
import MessagePopup from "../MessagePopop";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router";
import CustomBreadcrumbs from "../../../components/customBreadcrumbs";
import shopifyIntegration from "../../../assets/images/shopify-integration.svg";
import { Divider } from "antd";
import { useAuth } from "../../../shared/provider/authprovider";

export const style = {
  fiiledBtn: {
    background: "linear-gradient(95.28deg, #0038BA -5.39%, #4F18FF 102.93%)",
    borderRadius: 0.5,
    color: "#fff",
    width: "100%",
    p: "10px",
    height: 48,
    fontSize: 16,
    fontWeight: 500,
    textTransform: "capitalize",
    
  },
  stepperContainer: {
    ".MuiStepper-root": {
      ".MuiStepLabel-root": {
        ".MuiStepLabel-label": {
          color: "#000000",
          ".Mui-active": {
            color: "#000000",
          },
        },
        ".MuiStepLabel-iconContainer": {
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            border: "1px solid #4B1BFB",
            borderRadius: "50%",
            color: "#fff",
            "&.Mui-active": {
              color: "#fff",
            },
            ".MuiStepIcon-text": {
              fill: "#000",
              fontSize: 18,
            },
          },
        },
      },
      ".MuiStepConnector-root": {
        ml: "9px",
        ".MuiStepConnector-line": {
          borderColor: "#4B1BFB",
          minHeight: 20,
        },
      },
    },
  },
};

const StepsToIntegrate = () => {
  const { integrationTabIndex } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280));
  const isTablet = !isMobile && !isDesktop;

  const { name } = useParams();
  const navigate = useNavigate();
  return (
    <>
      {/* <MessagePopup /> */}
      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
        }}
      >
        <Stack
          px={1.5}
          py={2}
          justifyContent="center"
          sx={{
            backgroundColor: "#FAFBFC",
            borderBottom: "1px solid #E0E0E0",
            height: 44,
          }}
        >
          <CustomBreadcrumbs
            firstName={"Integrations"}
            firstFunction={-1}
            secondName={name}
          />
        </Stack>
        <Box
          p={isMobile ? 2 : isTablet ? 3 : 0}
          height={isDesktop ? "calc(100vh - 104px)" : "unset"}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            border={isTablet ? "1px solid #E0E0E0" : "unset"}
            height={isDesktop ? "calc(100vh - 104px)" : "unset"}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: isMobile ? "unset" : 240,
                border: isMobile ? "1px solid #E0E0E0":"none",
                borderRadius: isMobile ? "2px":"none",
                px: isMobile ? 3 : 2.5,
                pt: isMobile ? 2.5 : 5,
                mb: isMobile ? 2.5 : 0,

              }}
            >
              <Box display={isMobile ? "flex" : "unset"}>
                <Stack mr={isMobile ? "30px" : 0} alignItems="center">
                  <img
                    src={shopifyIntegration}
                    style={{ width: "100%", maxWidth: isMobile ? 100 : 150 }}
                  />
                </Stack>
                <Box flex={isMobile ? 1 : "unset"}>
                  <Typography
                    variant="h6"
                    fontSize={14}
                    fontWeight={500}
                    color="#68737D"
                    mt={isMobile ? 0 : 5}
                    mb={2}
                  >
                    Offers
                  </Typography>
                  <Stack
                    direction={isDesktop ? "row" : "column"}
                    spacing={1.5}
                    mb={1.5}
                  >
                    <Typography
                      variant="body2"
                      fontSize={12}
                      fontWeight={500}
                      color="#0038BA"
                      px={0.8}
                      py={0.5}
                      sx={{
                        backgroundColor: "#C8D6F8",
                        width: isDesktop ? "fit-content" : "100%",
                        borderRadius: 0.5,
                        textAlign: "center",
                      }}
                    >
                      Order Feed
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      fontWeight={500}
                      color="#0038BA"
                      px={0.8}
                      py={0.5}
                      sx={{
                        backgroundColor: "#C8D6F8",
                        width: isDesktop ? "fit-content" : "100%",
                        borderRadius: 0.5,
                        textAlign: "center",
                      }}
                    >
                      Shipment Info{" "}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    fontSize={12}
                    fontWeight={500}
                    color="#0038BA"
                    px={0.8}
                    py={0.5}
                    sx={{
                      backgroundColor: "#C8D6F8",
                      width: isDesktop ? "fit-content" : "100%",
                      borderRadius: 0.5,
                      textAlign: "center",
                    }}
                  >
                    Inventory Sync
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{ ...style.fiiledBtn, mt: "30px", mb: isMobile ? 2:0 }}
                onClick={() => {
                  if (integrationTabIndex == "1") {
                    navigate(`/listing-add-new-integration/${name}`, {
                      state: { isFirstTime: true },
                    });
                  } else {
                    navigate(
                      `/listing-add-new-destination-integration/${name}`,
                      { state: { isFirstTime: true } }
                    );
                  }
                }}
              >
                Connect
              </Button>
            </Box>

            {isMobile ? <Divider></Divider> : <></>}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                backgroundColor: "#fff",
                py: isMobile ? 0 : 2.5,
                px: isMobile ? 0 : 5,
                borderLeft: isMobile ? "none" : "1px solid #E0E0E0",
              }}
            >
              <Stack mb={"30px"}>
                <Typography
                  variant="h4"
                  fontSize={20}
                  fontWeight={500}
                  color="#000"
                  mb={1.5}
                >
                  {name} - Data Gathering
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={14}
                  fontWeight={500}
                  color="#68737D"
                >
                  In order to make the connection, you will have to create a
                  custom app in Shopify. This will give you the necessary
                  information for a successful <br /> integration with{" "}
                  <Link sx={{ color: "#0038BA", textDecoration: "none" }}>
                    Resale.com
                  </Link>
                </Typography>
              </Stack>
              <Box
                sx={{
                  border: "1px solid #E0E0E0",
                  background: "#FAFBFC",
                  p: 2.5,
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Typography
                  variant="h5"
                  fontSize={isMobile? 16:20}
                  fontWeight={500}
                  color="#000000"
                  mb={5}
                >
                  Before you start, here’s what you’ll need to do:{" "}
                </Typography>

                {/* stepper */}
                <Box sx={style.stepperContainer}>
                  <Stepper orientation="vertical">
                    <Step>
                      <StepLabel sx={{ p: 0 }}>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Enable custom app development from the Shopify admin
                        </Typography>
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel sx={{ p: 0 }}>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Create and install a{" "}
                          <span style={{ fontWeight: 500 }}>custom app</span>
                        </Typography>
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel sx={{ p: 0 }}>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Configure{" "}
                          <span style={{ fontWeight: 500 }}>
                            Admin API scopes
                          </span>
                        </Typography>
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel sx={{ p: 0 }}>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Install{" "}
                          <Link
                            sx={{ color: "#0038BA", textDecoration: "none" }}
                          >
                            Resale.com
                          </Link>{" "}
                          on <span style={{ fontWeight: 500 }}>DEV Resale</span>
                        </Typography>
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel sx={{ p: 0 }}>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Copy Admin Token*, API Key* and API secret key*.
                        </Typography>
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel
                        sx={{ p: 0, display: "flex", alignItems: "start" }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Next, navigate to your{" "}
                          <Link
                            sx={{ color: "#0038BA", textDecoration: "none" }}
                          >
                            Locations.
                          </Link>
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={300}
                          fontSize={isMobile ? 14:16}
                        >
                          Click on your default location and{" "}
                          <span style={{ fontWeight: 500 }}>
                            Copy and save the entire URL
                          </span>
                        </Typography>
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </Box>
              <Box
                sx={{
                  p: "10px",
                  backgroundColor: "#C8D6F8",
                  mt: "30px",
                  display: isTablet ? "none" : "flex",
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={isMobile ? 16:20}
                  fontWeight={500}
                  color="#0038BA"
                >
                  Once you have gathered all of the information above, please
                  send it to your Onboarding Manager so we can set up the
                  integration.
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Box
            sx={{
              p: 2.5,
              backgroundColor: "#C8D6F8",
              mt: 2.5,
              display: isTablet ? "flex" : "none",
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={400}
              color="#0038BA"
            >
              Once you have gathered all of the information above, please send
              it to your Onboarding Manager so we can set up the integration.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default StepsToIntegrate;
