import {
    Box,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useAuth } from "../../shared/provider/authprovider";
import DetailScreen from "../listingScreens/detailScreen";
import styles from './index.module.scss';
import DetailedReport from "../listingScreens/PayoutReports/DetailedReport";
import TopPlatformsDetailed from "../listingScreens/salesDashboard/DetaliledChartData/TopPlatformsDetailed";
import TopSellingBrandsDetailed from "../listingScreens/salesDashboard/DetaliledChartData/TopSellingBrandsDetailed";
import TopSellingCategoryDetailed from "../listingScreens/salesDashboard/DetaliledChartData/TopSellingCategoryDetailed";
import TotalOrdersDetailed from "../listingScreens/salesDashboard/DetaliledChartData/TotalOrdersDetailed";
import TotalSalesDetailed from "../listingScreens/salesDashboard/DetaliledChartData/TotalSalesDetailed";
import UnitPerOrderDetailed from "../listingScreens/salesDashboard/DetaliledChartData/UnitPerOrderDetailed";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    return (
        <>
            <Box
                className={styles.contentMain}
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: "#fff",
                }}
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>
                        {children}
                    </>
                )}
            </Box>
        </>
    );
}

const Index = () => {
    const { tabsSubData, tabsAdded, setTabsAdded, value } = useAuth();
    return (<>
        {tabsSubData.map((ele: any, index: number) => {
            if (ele.key === "listing") {
                return (
                    <CustomTabPanel value={value} index={index}>
                        <DetailScreen data={ele} setTabsAdded={setTabsAdded} />
                    </CustomTabPanel>
                );
            } else if (ele.key === "PayoutReport") {
                return (
                    <CustomTabPanel value={value} index={index}>
                        <DetailedReport data={ele} />
                    </CustomTabPanel>);
            }
            else if (ele.key === "SalesDashboard") {
                return (
                    <CustomTabPanel value={value} index={index}>
                        {ele.title === "Total Sales" && <TotalSalesDetailed />}
                        {ele.title === "Top Platforms" && <TopPlatformsDetailed />}
                        {ele.title === "Top Selling Category" && <TopSellingCategoryDetailed />}
                        {ele.title === "Top Selling Brands" && <TopSellingBrandsDetailed />}
                        {ele.title === "Total Orders" && <TotalOrdersDetailed />}
                        {ele.title === "Unit Per Order" && <UnitPerOrderDetailed />}
                    </CustomTabPanel>);
            }
        })}
    </>)
}
export default Index;