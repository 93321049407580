import {
  Box,
  Link,
  Stack,
  TextField,
  Typography,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "./index.module.scss";
import ellipse from "../../../assets/images/ellipse.jpg";
import { ReactComponent as Key } from "../../../assets/images/icons/key-icon.svg";
import { ReactComponent as Left } from "../../../assets/images/icons/left.svg";
import { style } from "../RegistrationScreen/index";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../shared/provider/authprovider";

const ListingForgetPassword = () => {
  const { forgotPasswordListingUser } =
    useClientAPI();
  const { messageText, setOpen, open } = useAuth()
  const navigate = useNavigate();
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required")
      .email("Please enter valid email")
      .nullable(),
  });
  const formikLogin: any = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitDataLogin(),
  });
  const submitDataLogin = async () => {
    // console.log(formikLogin?.values);
    const resp = await forgotPasswordListingUser(formikLogin?.values);
    if (resp?.data?.status == 200) {
      navigate("/listing-reset-password", { state: formikLogin?.values });
    }
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={9000}
        onClose={handleClose}
        message={messageText}
        action={action}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.formWrapper}
      >
        <Stack
          justifyContent="center"
          sx={{ background: "#fff" }}
          className={styles.formContainer}
        >
          <img
            src={ellipse}
            alt="ellipse"
            className={`${styles.responsiveImg} ${styles.formBgImg}`}
          />
          <Stack sx={{ px: 6, py: 3, position: "relative" }}>
            <Stack flexDirection="row" justifyContent="center">
              <Key className={styles.keyIcon} />
            </Stack>
            <Stack mt={4} mb={2.5} alignItems="center">
              <Typography variant="h4" fontSize={30} fontWeight={600} color='#000'>
                Forgot password?
              </Typography>
              <Typography variant="body2" fontSize={20} fontWeight={300} color='#000' textAlign='center'>
                No worries, we’ll send you reset instructions.
              </Typography>
            </Stack>
            <FormikProvider value={formikLogin}>
              <Field name="email">
                {({ field, meta }: any) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="filled-basic"
                    variant="filled"
                    label="Enter your email"
                    sx={style.textField}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                  />
                )}
              </Field>
              <Button
                variant="contained"
                className={styles.filledBtn}
                onClick={() => formikLogin.handleSubmit()}
                sx={{
                  mt: 5,
                  mb: 2.5,
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", backgroundColor: "#004EFF" },
                }}
              >
                Reset Password
              </Button>
              <Link
                className={styles.textLink}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => navigate("/listing-login")}
              >
                <Left className={styles.backArrow} /> Back to log in
              </Link>


            </FormikProvider>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default ListingForgetPassword;
