import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material"
import { Field, FormikProvider, useFormik } from "formik";
const PaymentDetails=(props:any) => {
    const formik: any = useFormik({
        initialValues: {
          firstname: "",
          lastname: "",
          email: "",
        },
        onSubmit: async () => {
            console.log('first')
        },
      });
    const {setopenSetting}=props
    return(
        <>
        <Box>Edit Payment Details
        <FormikProvider value={formik}>
        <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography >Name on Card*</Typography>
        <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label=" Name"
                        //   sx={{m:1}}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>

                    <Typography >Card Number</Typography>
        <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label="card number"
                        //   sx={{m:1}}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography >Expiration</Typography>
        <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label=" MM/YY"
                        //   sx={{m:1}}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field></Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>

                    <Typography >CVV</Typography>
        <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label="CVV"
                        //   sx={{m:1}}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>

                    <Typography >Postal code</Typography>
        <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label="postal code"
                        //   sx={{m:1}}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field>
                    </Grid>
</Grid>
            <Box sx={{display:'flex', justifyContent:'end',p:2}}>
        <Button sx={{background:'lightBlue',m:2}}>Save</Button>
        <Button sx={{m:2}} onClick={()=>setopenSetting(0)}>cancel</Button>
        </Box>
        </FormikProvider>
        </Box>
        </>
    )
}
export default PaymentDetails