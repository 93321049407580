import { useEffect, useState } from "react";
import AccountDetails from "./AccountManagement/AccountDetails";
import BusinessDetails from "./AccountManagement/BusinessDetails";
import ChangePassword from "./AccountManagement/ChangePassword";
import PaymentDetails from "./AccountManagement/PaymentDetails";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";

const AccManagementUser = () => {
  const [openSetting, setopenSetting] = useState<any>(0);
  const [accountData, setAccountData] = useState<any>();
  const { getUserInvoiceDetails,ExportInvoiceAPI } = useSubScriptionAPI()

  const cardstyle = {
    display: "flex",
  };
  const loadAccountData = async () => {
    const result = await getUserInvoiceDetails("", 0, 10, localStorage.getItem("userId"))
    setAccountData(result[0].user)
}
useEffect(() => {
  loadAccountData()
}, []);
  return (
    <>
      {/* <FrontPage /> */}
      {openSetting === 0 && (
        <Stack>
          Manage Account
          <Box sx={cardstyle}>
            <IconButton sx={{ p: 0, ml: 1.5, mt: "-70px" }}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/2.jpg"
                sx={{
                  height: 24,
                  width: 24,
                  fontSize: 12,
                  backgroundColor: "#0178D7",
                }}
              />
            </IconButton>
            <Stack sx={{ width: "500px", pb: 1 }}>
              <Stack sx={{ p: 2 }}>
                Name<Stack sx={{ fontWeight: 300 }}>Account Admin</Stack>
              </Stack>
              <Stack sx={{ p: 2 }}>
                Email <Stack sx={{ fontWeight: 300 }}>abs@mail.com</Stack>
              </Stack>
            </Stack>
            <Button onClick={() => setopenSetting(1)}>Edit</Button>
          </Box>
          <Typography>Business Details</Typography>
          <Box sx={{ ...cardstyle, ml: "35px" }}>
            <Stack sx={{ width: "500px", pb: 1 }}>
              <Stack sx={{ p: 2 }}>
                {" "}
                Company Name
                <Stack sx={{ fontWeight: 300 }}> Company Name</Stack>
              </Stack>
            </Stack>
            <Button onClick={() => setopenSetting(2)}>Edit</Button>
          </Box>
          {/* <Stack> Busi<Button onClick={() => setopenSetting(2)}>change</Button></Stack> */}
          <Typography>Payment Details</Typography>
          <Box sx={{ ...cardstyle, ml: "35px" }}>
            <Stack sx={{ width: "500px", pb: 1 }}>
              <Stack sx={{ p: 2 }}>
                {" "}
                Payment Method
                <Stack sx={{ fontWeight: 300 }}>
                  {" "}
                  MasterCard-4668, expiring 9/2023
                </Stack>
              </Stack>
            </Stack>
            <Button onClick={() => setopenSetting(3)}>Edit</Button>
          </Box>
          <Typography>Password</Typography>
          <Box>
            <Button
              onClick={() => setopenSetting(4)}
              sx={{ border: "1px solid grey", m: 2 }}
            >
              change Password
            </Button>
          </Box>
        </Stack>
      )}
      {openSetting === 1 && <AccountDetails setopenSetting={setopenSetting} />}
      {openSetting === 2 && <BusinessDetails setopenSetting={setopenSetting} />}
      {openSetting === 3 && <PaymentDetails setopenSetting={setopenSetting} />}
      {openSetting === 4 && <ChangePassword setopenSetting={setopenSetting} />}
    </>
  );
};
export default AccManagementUser;
