
import moment from "moment";
import styles from "./index.module.scss";
import { ReactComponent as True } from '../../../assets/images/icons/true.svg';
import { ReactComponent as False } from '../../../assets/images/icons/false.svg';
import { Link, Stack, Typography } from "@mui/material";

export const style = {
    tableData: {
        fontSize: 14,
        fontWeight: 300,

    }
}
interface IProps {
    data: Array<object>;
    fetchProductDetails?: any;
    addPanels?: any;
    page?: any;
}

const Index = (props: IProps) => {
    const { data, fetchProductDetails, addPanels, page } = props;

    return (<>
        <div className={styles.heightG}>
            <table className={styles.tableH}>
                <thead className={`${styles.thead} rounded`}>
                    <tr>
                        <td></td>
                        <td>SKU</td>
                        <td>Brand</td>
                        <td>Status</td>
                        <td>Created</td>
                        <td>Updated</td>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {data && data.length ? data?.map((ele: any, index: number) => (
                        <tr onClick={() => fetchProductDetails(ele.inventoryNumber)} style={{ cursor: 'pointer' }}>
                            <td>{(page) + (index + 1)}</td>
                            <td><span onClick={(e) => e.stopPropagation()}><Link variant="body2" color='#0038BA' underline="hover" sx={{ ...style.tableData, cursor: 'pointer', width: 'max-content', '&:hover': { color: '#004EFF' } }} onClick={() => addPanels(ele)}>{ele?.inventoryNumber}</Link></span></td>
                            <td><Typography variant="body2" sx={style.tableData}>{ele?.brand}</Typography></td>
                            <td>{ele?.status === 1 ?
                                <Stack direction='row' alignItems='center' gap='5px'>
                                    <True />
                                    <Typography variant="body2" sx={style.tableData}>True</Typography>
                                </Stack>
                                :
                                <Stack direction='row' alignItems='center' gap='5px'>
                                    <False />
                                    <Typography variant="body2" sx={style.tableData}>False</Typography>
                                </Stack>
                            }</td>
                            <td>{ele?.createdDate ? moment(ele?.createdDate).format("MM/DD/YYYY") : ""}</td>
                            <td>{ele?.updatedDate ? moment(ele?.updatedDate).format("MM/DD/YYYY") : ""}</td>
                        </tr>
                    )) :
                        <tr className={styles.noRecordRow}>
                            <td colSpan={6}>
                                <Typography variant="body2" textAlign='center' fontSize={14} fontWeight={300}>No records found</Typography>
                            </td>
                        </tr>}
                </tbody>
            </table>
        </div >
    </>)
}

export default Index;