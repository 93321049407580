import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { environmentUrl } from "../../api/index.service";
export interface IUseAuth {
  token: any | null;
  setToken(value: any): any;
  roles: any | null;
  setRoles(value: any): any;
  firstName: any | null;
  setFirstName(value: any): any;
  lastName: any | null;
  setLastName(value: any): any;
  signInCalled(details: any): any;
  signout(): void;

  isLoading: boolean;
  setLoading(value: any): any;

  tabsSubData: Array<Object>;
  setTabsSubData(value: any): any;
  setTabsAdded(value: any): any;
  tabsAdded: boolean;
  value: number;
  setValue(value: any): any;

  error: any | null;
  setError(value: any): any;

  errorModalShow: any | null;
  setErrorModalShow(value: any): any;
  errorMsg: any | null;
  setErrorMsg(value: any): any;
  selectedIndex: any | null;
  setSelectedIndex(value: any): any;
  newData: any | null;
  setNewData(value: any): any;
  setNewExportLoader(value: any): any;
  api: any;
  handleError(error: any): any;
  apinew: any;
  open: any;
  setOpen: any;
  setShortMessage: any;
  shortMessage: any;
  setMessageText: any;
  messageText: any;
  integrationTabIndex: any;
  setIntegrationTabIndex: any;
  openError: boolean;
  setOpenError: any;
  setErrorMessageText: any;
  errorMessageText: any;
}

const AuthContext = createContext({});

export const useAuth = () => useContext<IUseAuth>(AuthContext as any);

export const AuthProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<any | null>(null);
  const [firstName, setFirstName] = useState<any | null>(null);
  const [lastName, setLastName] = useState<any | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorModalShow, setErrorModalShow] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<any>(null);
  const [error, setError] = useState<any>("");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [tabsSubData, setTabsSubData] = useState<any>([]);
  const [tabsAdded, setTabsAdded] = useState<boolean>(false);
  const [value, setValue] = useState<any>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<any>("");
  const [errorMessageText, setErrorMessageText] = useState<any>("");
  const [shortMessage, setShortMessage] = useState<any>("");
  const [integrationTabIndex, setIntegrationTabIndex] = useState<any>("1");

  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getDataFromStorage = () => {
    const role = localStorage.getItem("role");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    setRoles(role);
    setFirstName(firstName);
    setLastName(lastName);
  };

  const handleError = (error: any) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      setErrorModalShow(true);
      setErrorMsg(error?.response?.data?.results);
    }
  };
  const signout = () => {
    localStorage.clear();
    window.location.href = "/";
  };


  const token = sessionStorage.getItem("token") || localStorage.getItem("token");
 
  const apinew = axios.create({
    baseURL: environmentUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  let a = 0;

  apinew.interceptors.request.use(
    function (config) {
      a = a + 1;
      setLoading(true);
      return config;
    },
  )

  apinew.interceptors.response.use(
    function (response) {
      a = a - 1;
      if (a === 0) {
        setLoading(false);
      }
      return response;
    },
    function (error) {
      a = a - 1;
      if (a === 0) {
        setLoading(false);
      }
      if (error?.response?.status === 401) {
        setTabsSubData([])
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/#/listing-login";
      }
      return Promise.reject(error)
    }
  )


  return (
    <AuthContext.Provider
      value={{
        token,
        roles,
        firstName,
        lastName,
        signout,
        isLoading,
        setLoading,
        selectedIndex,
        setSelectedIndex,
        setTabsSubData,
        tabsSubData,
        handleError,
        apinew,
        tabsAdded,
        setTabsAdded,
        value,
        setValue,
        open,
        setOpen,
        setShortMessage,
        shortMessage,
        setMessageText,
        messageText,
        integrationTabIndex,
        setIntegrationTabIndex,
        setOpenError,
        openError,
        errorMessageText,
        setErrorMessageText
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
