import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import HeaderListing from "../../../components/headerListing"
import styles from "./index.module.scss";
import { useEffect } from "react";
import { useAuth } from "../../../shared/provider/authprovider";

const FinanceDashboard = () => {
    const {setSelectedIndex} = useAuth()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    useEffect(() => {
        setSelectedIndex(4)
    }, [])
    return (
        <>
            <HeaderListing />
            <Box
                className={styles.contentMain}
                component="main"
                sx={{ flexGrow: 1, backgroundColor: '#e9f0fc', py: 2.5, px: isMobile ? 2 : 5 }}
            >
                <Box display="flex" justifyContent="space-between" mb={1.5}>
                    <Stack>
                        <Typography
                            variant="h4"
                            fontSize={20}
                            fontWeight={500}
                            color="#000"
                            mb={2}
                        >
                            Finance Dashboard
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}
export default FinanceDashboard