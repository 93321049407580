import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Slide, Snackbar, Stack, Typography } from "@mui/material";
import { style } from "../../../src/screens/listingScreens/RegistrationScreen/index";
import { ReactComponent as SuccessLogo } from "../../assets/images/icons/success-logo.svg";

const SuccessSnackbar = (props: any) => {
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        props.setOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    const SlideTransition = (props: any) => {
        return <Slide {...props} direction="left" />;
      };
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ backgroundColor: "#fff" }}
                >
                    <SuccessLogo />
                    <Stack ml={1.5}>
                        <Typography variant="h6" fontSize={12} fontWeight={500} color="#000">
                            {props.shortMessage}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#000"
                            fontWeight={300}
                            fontSize={12}
                        >
                            {props.messageText}
                        </Typography>
                    </Stack>
                </Box>}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={SlideTransition}
            sx={{ ...style.snackbar }}
        />
    )
}
export default SuccessSnackbar