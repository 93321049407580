import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import welcomeImg from "../../../../src/assets/images/welcomeImg.jpg";
import { ReactComponent as Close } from "../../../assets/images/icons/x.svg";

const MoadalPreLanding = (props: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 800,
    p: 1,
    boxShadow: "0px 14px 45px rgba(0, 0, 0, 0.08)",
    borderRadius: 2,
    border: "unset",
    textAlign: "center",
    backgroundColor: "#fff",
  };
  const styles = {
    fiiledBtn: {
      textTransform: "capitalize",
      height: 40,
      width: 150,
      boxShadow: "unset",
      fontSize: 16,
      background: "#0038BA",
      border: "1px solid #004EFF",
      borderRadius: 1,
      mt: 4,
    },
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton sx={{ display: "flex", marginLeft: "auto" }}>
            <Close />
          </IconButton>
          <Box sx={{ pt: 2, px: 5, pb: 3 }}>
            <Typography
              id="modal-modal-title"
              variant="h2"
              fontSize={40}
              fontWeight={500}
              color='#000'
            >
              Welcome to Resale
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2.5, mb: 5, fontSize: 16 }}
            >
              Resale allows you to easily connect your data sources and
              collaborates across the <br /> organization to resolve your issues
              faster, while maintaining active state of issue resolution.
            </Typography>
            <img
              src={welcomeImg}
              style={{ width: "100%", maxWidth: "640px" }}
            />
            <Stack alignItems="center">
              <Button
                variant="contained"
                sx={styles.fiiledBtn}
              // onClick={() => setShowNewSubscription(true)}
              >
                Get Started
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default MoadalPreLanding;
