import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import RegistrationScreen from "../screens/listingScreens/RegistrationScreen";
import ListingLoginScreen from "../screens/listingScreens/LoginScreen";
import ListingForgetPassword from "../screens/listingScreens/ListingForgetPassword";
import OpenEmailApp from "../screens/listingScreens/ListingForgetPassword/OpenEmail";
import SetNewPasswordScreen from "../screens/listingScreens/ListingForgetPassword/SetNewPasswordScreen";
import ListingLandingDashboard from "../screens/listingScreens/accountManagementDashboard";
import SuperAdminDashboard from "../screens/listingScreens/superAdminDashboard";
import PostLoginScreen from "../screens/listingScreens/LoginScreen/postLoginScreens";
import IntegraionsDashbaord from "../screens/listingScreens/IntegraionsDashbaord";
import SalesDashboard from '../screens/listingScreens/salesDashboard'
import StepsToIntegrate from "../screens/listingScreens/IntegraionsDashbaord/StepsToIntegrate";
import DestinationStepsToIntegrate from "../screens/listingScreens/IntegraionsDashbaord/addIntegrationDestinationScreen";
import DestinationConfigure from "../screens/listingScreens/IntegraionsDashbaord/configuredDestinationScreen";
import SouceConfigure from "../screens/listingScreens/IntegraionsDashbaord/configureSourceScreen";
import AddIntegrationScreen from "../screens/listingScreens/IntegraionsDashbaord/AddIntegrationScreen";
import ListingCustomerDashboard from "../screens/listingScreens/ListingCustomerDashboard";
import FinanceDashboard from "../screens/listingScreens/FinanceDashboard";
import IntegrationOverview from "../screens/listingScreens/IntegraionsDashbaord/IntegrationOverview";
import SourceDashboard from "../screens/listingScreens/IntegraionsDashbaord/SourceDashboard";
import DestinationDashboard from "../screens/listingScreens/IntegraionsDashbaord/DestinationDashboard";
import PayoutReports from "../screens/listingScreens/PayoutReports";
import UserPayoutReports from '../screens/listingScreens/userPayoutReport'
import OrdersDashboard from '../screens/listingScreens/ordersDashboard'
import { useAuth } from "../shared/provider/authprovider";
import Loader from "../loader";
import { useClientAPI } from "../shared/api/userManagementSAAS";
import SuccessSnackbar from "../components/SuccessSnackbar";
import SkuForm from "../screens/listingScreens/SkuForm";
import SetUpTool from "../screens/listingScreens/SkuForm/SetUpTool";
import ErrorSnackbar from "../components/ErrorSnackbar";
const PageRoutes = () => {
  // const baseRoute = (sessionStorage.getItem("token") && localStorage.getItem("role")) ? (localStorage.getItem("role") === "User" ? <Navigate to="/userdashboard" /> : <Navigate to="/dashboard" />) : <LoginScreen />
  // console.log('base route', baseRoute);
  const { isLoading, open, setOpen, messageText, shortMessage, setOpenError, openError, errorMessageText } = useAuth();
  const token = localStorage.getItem("token") || "";
  const rememberMe = localStorage.getItem("rememberMe")

  useEffect(() => {
    if (rememberMe && token) {
      sessionStorage.setItem("token", token);
    }
  }, [rememberMe])


  return (
    <>
      {open && <SuccessSnackbar open={open} setOpen={setOpen} messageText={messageText} shortMessage={shortMessage} />}
      {openError && <ErrorSnackbar open={openError} setOpen={setOpenError} messageText={errorMessageText} shortMessage={shortMessage} />}

      {isLoading ? <Loader /> : ""}
      <Routes>
        {/* <Route
        path="/"
        element={baseRoute}
      /> */}
        <Route
          path="/"
          // element={<ListingLoginScreen />}
          element={
            (localStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer") ? (
              <ListingCustomerDashboard />
            ) : (localStorage.getItem("token") != null &&
              localStorage.getItem("role") === "SuperAdmin") ?
              (<ListingLandingDashboard />)
              : (<Navigate to="/listing-login" />)
          }
        />


        <Route
          path="/listing-registration"
          element={<RegistrationScreen />}
        />
        <Route
          path="/listing-login"
          element={<ListingLoginScreen />}
        />
        <Route
          path="/listing-forget-password"
          element={<ListingForgetPassword />}
        />
        <Route
          path="/listing-reset-password"
          element={<OpenEmailApp />}
        />
        <Route
          path="/listing-set-new-password"
          element={<SetNewPasswordScreen />}
        />
        <Route
          path="/account-management"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <ListingLandingDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-saas-main-dashboard"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "SuperAdmin" ? (
              <SuperAdminDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-integration"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <IntegraionsDashbaord />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />

        <Route
          path="/listing-sales"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <SalesDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/finance-dashboard"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <FinanceDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/integration-overview"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <IntegrationOverview />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/source-dashboard"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <SourceDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/destination-dashboard"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <DestinationDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/payout-reports"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "SuperAdmin" ? (
              <PayoutReports />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/client/payout-report"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <UserPayoutReports />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-integration-steps/:name"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <StepsToIntegrate />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-add-new-destination-integration/:name"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <DestinationStepsToIntegrate />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />

        <Route
          path="/listing-configure-destination-integration/:name"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <DestinationConfigure />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />

        <Route
          path="/listing-configure-source-integration/:name"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <SouceConfigure />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />

        <Route
          path="/listing-add-new-integration/:name"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <AddIntegrationScreen />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-customer-dashboard"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <ListingCustomerDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/listing-dashboard-customer"
          element={<PostLoginScreen />}
        />
        <Route
          path="/order"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <OrdersDashboard />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        {/* /sku-form */}
        <Route
          path="/sku-form"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <SkuForm />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
        <Route
          path="/sku-setup-tool"
          element={
            sessionStorage.getItem("token") != null &&
              localStorage.getItem("role") === "Customer" ? (
              <SetUpTool />
            ) : (
              <Navigate to="/listing-login" />
            )
          }
        />
      </Routes>
    </>);
};

export default PageRoutes;
