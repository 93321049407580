import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export interface IUseAuth {
  token: any | null;
}
export const useListingDashboardAPI = () => {
  const { apinew, tabsSubData, setTabsSubData } = useAuth();
  const [messageText, setMessageText] = useState<any>("");
  const [listingDashboardData, setListingDashboardData] = useState<any>([]);
  const [listingHeaderData, setListingDashboardHeaderData] = useState<any>([]);
  const listingDashboardHeaderData = async (payload: any) => {
    try {
      const requestPayload = new URLSearchParams(payload).toString();
      const res = await apinew.get(
        `/api/AdminDashboard/ProductsReport?${requestPayload}`
      );
      if (res?.data?.status == 200) {
        setListingDashboardHeaderData(res?.data.results);
      }
      return res?.data.results;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };

  const listingDashboardTableData = async (dcid: number, payload: any) => {
    try {
      const res = await apinew.post(`/api/MasterOrder/Inventory/Listing?dcid=${dcid}`, payload);
      if (res?.status == 200) {
        setListingDashboardData(res?.data.results);
      }
      return res;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };

  const getProductDetail = async (payload: any) => {
    try {
      const requestPayload = new URLSearchParams(payload).toString();
      const res = await apinew.get(`/api/AdminDashboard/ScannedProductReport?${requestPayload}`);
      if (res?.status == 200) {
        return res?.data.results;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };

  const getMarketPlaceDetail = async (payload: any) => {
    try {
      const requestPayload = new URLSearchParams(payload).toString();
      const res = await apinew.get(`/api/MasterOrder/MarketPlace?${requestPayload}`);
      if (res?.status == 200) {
        return res?.data.results;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };

  const getPerticularProductDetail = async (payload: any) => {
    try {
      const res = await apinew.get(`/api/MasterOrder/Inventory?productId=${payload}`);
      if (res?.status == 200) {
        setTabsSubData([...tabsSubData, { ...res?.data.results, key: "listing", productId: payload  }]);
        return res?.data.results
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };


  return {
    messageText,
    setMessageText,
    apinew,
    listingDashboardTableData,
    listingDashboardData,
    setListingDashboardData,
    listingDashboardHeaderData,
    setListingDashboardHeaderData,
    listingHeaderData,
    getProductDetail,
    getPerticularProductDetail,
    tabsSubData,
    setTabsSubData,
    getMarketPlaceDetail
  } as const;
};
