import { Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import * as Yup from "yup";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Reject } from "../../../assets/images/icons/reject.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style } from "../../listingScreens/superAdminDashboard/index";
import Pagination from "../paginationListing";
import ApproveNewUserModal from "./approveNewUserModal";
import styles from "./index.module.scss";
import RejectNewUserModal from "./rejectnewUserModal";

const UserReqestManagement = (props: any) => {
  const { fetchLatestCount } = props;
  const { isLoading, setMessageText, setOpen, setShortMessage } = useAuth();
  const {
    getListingUserList,
    putAcceptUser,
    putRejectUser,
    getUser,
    userData,
  } = useClientAPI();
  const [type, setType] = useState(null);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);
  const [comment, setComment] = useState<any>("");
  const [prefix, setPrefix] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [listingRequestCount, setListingRequestCount] = useState<any>([]);
  const [listingRequestList, setListingRequestList] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState<any>([]);

  const initialPayload = {
    fromDate: dateFilter?.length ? moment(dateFilter[0]).format("MM-DD-YYYY") : null,
    toDate: dateFilter?.length ? moment(dateFilter[1]).format("MM-DD-YYYY") : null,
    limit: limit,
    isActivate: [true, false],
    offset: page,
    userApprovalStatusList: [0],
    prefix: prefix,
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    const resultUser = await getListingUserList(
      initialPayload
    );
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
    setPage(0);
  };
  const putAcceptUserRequest = async () => {
    try {
      const values: any = formik.values;
      for (let item of Object.keys(values)) {
        if (!values[item] && values[item] !== false && values[item] !== true) {
          values[item] = 0;
        }
      }
      const payload = {
        userId: userData.id,
        ...values,
      };
      const response = await putAcceptUser(payload);
      if (response?.status == 200) {
        await fetchLatestCount();
        formik.resetForm();
        setShow(false);
        await loadInitialData()
        setMessageText("User Approoved");
        setOpen(true);
      }
    } catch (err) { }
  };
  const putRejectUserRequest = async (id: any) => {
    try {
      const payload = {
        id: id,
        comment: comment
      }
      const response = await putRejectUser(payload);
      if (response?.status == 200) {
        setComment("");
        setOpen(false);
        setShortMessage("")
        await fetchLatestCount();
        formik.resetForm();
        setOpenRejectModal(false);
        setShow(false);
        await loadInitialData();
        setMessageText("User Rejected");
        setOpen(true);
      } else {
        setOpen(true);
        setShortMessage("Failed to reject")
      }
    } catch (err) {
    }
  };

  const loadInitialData = async () => {
    const resultUser = await getListingUserList(initialPayload);
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
  };

  useEffect(() => {
    loadInitialData();
  }, [dateFilter, page]);

  const approveUserSchemaForDirectPercentageShare = Yup.object().shape({
    dcid: Yup.string().required("Required").nullable(),
    classificationCode: Yup.string().required("Required").nullable(),
    resaleFee: Yup.string().required("Required").nullable(),
    revShareDropShipper: Yup.string().required("Required").nullable(),
    directPercentageShare: Yup.string().required("Required").nullable(),
    payoutReport: Yup.string().required("Required").nullable(),
  });

  const approveUserSchemaFixedDollarAmount = Yup.object().shape({
    dcid: Yup.string().required("Required").nullable(),
    classificationCode: Yup.string().required("Required").nullable(),
    resaleFee: Yup.string().required("Required").nullable(),
    revShareDropShipper: Yup.string().required("Required").nullable(),
    directPercentageShare: Yup.string().required("Required").nullable(),
    payoutReport: Yup.string().required("Required").nullable(),
  });

  const approveUserSchemaPercentageofGMV = Yup.object().shape({
    dcid: Yup.string().required("Required").nullable(),
    classificationCode: Yup.string().required("Required").nullable(),
    resaleFee: Yup.string().required("Required").nullable(),
    marketPlaceFee: Yup.string().required("Required").nullable(),
    fixedFee: Yup.string().required("Required").nullable(),
    percentage: Yup.string().required("Required").nullable(),
    revShareDropShipper: Yup.string().required("Required").nullable(),
    payoutReport: Yup.string().required("Required").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      dcid: null,
      classificationCode: null,
      resaleFee: "",
      marketPlaceFee: null,
      fixedFee: null,
      percentage: null,
      higherValue: null,
      revShareDropShipper: null,
      directPercentageShare: null,
      payoutReport: "",
      isHigher: false,
    },
    validationSchema:
      type === 0
        ? approveUserSchemaForDirectPercentageShare
        : type === 1
          ? approveUserSchemaFixedDollarAmount
          : approveUserSchemaPercentageofGMV,
    onSubmit: () => putAcceptUserRequest(),
  });

  return (
    <>
      {show && (
        <ApproveNewUserModal
          formik={formik}
          show={show}
          modalTitle={modalTitle}
          setShow={setShow}
          userData={userData}
          putRejectUserRequest={putRejectUserRequest}
          setType={setType}
        />
      )}

      {openRejectModal && (
        <RejectNewUserModal
          show={openRejectModal}
          modalTitle={modalTitle}
          setShow={setOpenRejectModal}
          userData={userData}
          setComment={setComment}
          comment={comment}
          putRejectUserRequest={putRejectUserRequest}
        />
      )}

      <Stack direction="row" justifyContent="space-between" mb={1.5}>
        <Stack direction="row">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          </div>


          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: 2, px: "10px" }}
          >
            <DateRangePicker
              size="md"
              cleanable={false}
              format="MM-dd-yyyy"
              value={dateFilter}
              placeholder={["Start Date", "-", "End Date"]}
              onChange={(e) => setDateFilter(e)}
              className={styles.dateRangePicker}
            />
          </Stack>
        </Stack>

        <Pagination
          page={page}
          totalCount={
            listingRequestCount ? listingRequestCount : 0
          }
          setPage={setPage}
        />
        {/* new code end*/}
      </Stack >
      <div className={styles.heightG}>
        <table className={styles.tableH}>
          <thead className={`${styles.thead} rounded`}>
            <tr>
              <td>Sr. No.</td>
              <td>Name</td>
              <td>Email</td>
              <td>Company</td>
              <td>Phone</td>
              <td>Date</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!isLoading ? (
              <>
                {
                  listingRequestList &&
                  listingRequestList?.items?.map((value: any, index: any) => {
                    return (
                      <tr>
                        <td>{(page) + (index + 1)}</td>
                        <td>{value?.firstName + " " + value?.lastName}</td>
                        <td>{value?.email}</td>
                        <td>{value?.company}</td>
                        <td>{value?.mobileNumber}</td>
                        <td>{value?.createdTime.slice(0, 10)}</td>
                        <td>
                          <Stack direction="row" spacing={5}>
                            <Button
                              sx={{ fontWeight: 300, letterSpacing: "unset" }}
                              onClick={async () => {
                                await getUser(value?.id);
                                setModalTitle("Accept");
                                setShow(true);
                              }}
                              className={styles.tBtn}
                            >
                              <Accept />
                              Accept
                            </Button>
                            <Button
                              sx={{ fontWeight: 300, letterSpacing: "unset" }}
                              onClick={async () => {
                                await getUser(value?.id);
                                setModalTitle("Reject");
                                setOpenRejectModal(true);
                              }}
                              className={styles.tBtn}
                            >
                              <Reject />
                              Reject
                            </Button>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                }
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default UserReqestManagement;
