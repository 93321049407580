import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "rsuite/dist/rsuite.css";
import { ReactComponent as Calendar } from "../../../../assets/images/icons/calendar.svg";
import { useAnalyticsDashboardSAAS } from "../../../../shared/api/useAnalyticsDashboardSAAS";
import { style as styless } from "../../../listingScreens/superAdminDashboard/index";
import Pagination from "../../paginationListing";
import DetailedReportTable from "../PayoutReportTable/DetailedReportTable";
import SummaryPayout from "../summaryPayout";
import styles from "./index.module.scss";
import { useAuth } from "../../../../shared/provider/authprovider";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Index = (props: any) => {
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let [dateFilter, setDateFilter] = useState<any>([
    moment(firstDay).format("YYYY-MM-DDT00:00:00.000[Z]"),
    moment().clone().endOf("month").format("YYYY-MM-DDT00:00:00.000[Z]"),
  ]);
  const [page, setPage] = useState<any>(0);
  const [value, setValue] = useState("1");
  const [status, setstatus] = useState("");
  const [monthoptions, setMonthoptions] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMEssage] = useState<any>("");
  const [summaryData, setSummaryData] = useState<any>();
  const [salesData, setSalesData] = useState<any>();
  const [billingCycle, setBillingCycle] = useState<any>(0);
  const [showimportmodal, setshowimportmodal] = useState<any>(false);
  const [File, setFile] = useState<any>(null);
  const { setOpen, setMessageText } = useAuth();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const style = {
    tabContainer: {
      mt: 2,
      ".MuiTabs-indicator": {
        backgroundColor: "#000000",
        height: "1px",
      },
    },
    tabList: {
      ".MuiButtonBase-root": {
        color: "#68737D",
        fontWeight: 500,
        fontSize: 14,
        textTransform: "capitalize",
        "&.Mui-selected": {
          color: "#1E1F21",
        },
      },
    },
    borderStack: {
      border: "1px solid #E0E0E0",
      borderRadius: 0.5,
      height: 32,
    },
    errorMsg: {
      color: "#d32f2f",
      fontSize: "10.5px",
      fontWeight: 300,
    },
  };

  const initialPayload = {
    startDate: dateFilter?.length && dateFilter[0],
    endDate: dateFilter?.length && dateFilter[1],
    limit: 50,
    status: status,
    offset: page,
  };
  const initialPayloadConv = {
    startDate: dateFilter?.length && dateFilter[0],
    endDate: dateFilter?.length && dateFilter[1],
    limit: 50,
    offset: page,
  };
  const {
    getDetailedPayoutReportbyDCID,
    ExportPayoutReportbyDCID,
    getSummarydatabyDCIDAPI,
    uploadSummaryCSVAPI,
    getDetailedPayoutReportbyDCIDConvictional,
    uploadSummaryCSVAPIConvictional,
    // ExportPayoutReportbyDCIDConvictional,
    // ExportPayoutReportbyDCIDConvictionalAdmin
  } = useAnalyticsDashboardSAAS();

  const exportPayoutReport = async () => {
    const payload = {
      startDate: dateFilter?.length && dateFilter[0],
      endDate: dateFilter?.length && dateFilter[1],
    };
    if (data?.payoutType == 0) {
      const res = await ExportPayoutReportbyDCID(data?.dcid, payload);
      if (res?.status === 200) {
        var csvdata = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        var csvURL = window.URL.createObjectURL(csvdata);
        let tempLink;
        tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "OrderPayoutReports.xlsx");
        tempLink.click();
      }
    } else if (data?.payoutType == 1) {
      const res = await ExportPayoutReportbyDCID(
        data?.dcid,
        payload
      );
      if (res?.status === 200) {
        var csvdata = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        var csvURL = window.URL.createObjectURL(csvdata);
        let tempLink;
        tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "OrderPayoutReports.xlsx");
        tempLink.click();
      }
    }
  };
  const MonthlyList = () => {
    const months = [];

    for (let i = 0; i < 5; i++) {
      const currentDate = moment().subtract(i, "months");
      const startOfMonth = currentDate.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      months.push(monthObject);
    }
    setMonthoptions(months);
    setDateFilter(months[0]?.value.split("/"));
  };

  const BiMonthlyList = () => {
    const months = [];

    for (let i = 0; i < 5; i += 2) {
      const currentDate = moment().subtract(i, "months");
      const prevMonth = moment().subtract(i + 1, "months");
      const startOfMonth = prevMonth.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      months.push(monthObject);
    }
    setMonthoptions(months);
  };

  const BiweeklyList = () => {
    const months = [];

    for (let i = 0; i < 3; i++) {
      const currentDate = moment().subtract(i, "months");
      const startOfMonth = currentDate.clone().startOf("month");
      const midmonth = moment(startOfMonth).add(14, "days");
      const midmonth2 = moment(startOfMonth).add(15, "days");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${midmonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(midmonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      const monthObject2 = {
        label: `${midmonth2.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(midmonth2).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };
      if (i === 0 && parseInt(moment().format("DD")) < 16) {
        months.push(monthObject);
      } else {
        months.push(monthObject2);
        months.push(monthObject);
      }
    }
    setMonthoptions(months);
    setDateFilter(months[0]?.value.split("/"));
  };
  const weeklyList = () => {
    let dates: any = [];
    let date = new Date();
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    let startDate: any = new Date(date);
    startDate.setDate(date.getDate() - dayOfWeek + 7);
    const addDays = () => {
      const prevDate = startDate;
      startDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      const dateObject = {
        label: `${moment(startDate).format("MMM Do YY")} To ${moment(
          prevDate
        ).format("MMM Do YY")}`,
        value: `${moment(startDate).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(prevDate).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      dates.push(dateObject);
    };

    for (let i = 0; i < 5; i++) {
      addDays();
    }
    setMonthoptions(dates);
    setDateFilter(dates[0]?.value.split("/"));
  };

  useEffect(() => {
    if (billingCycle === 0) weeklyList();
    else if (billingCycle === 1) BiweeklyList();
    else if (billingCycle === 2) MonthlyList();
    else if (billingCycle === 3) BiMonthlyList();
  }, [billingCycle === 0 || billingCycle]);

  console.log(billingCycle, "billingCycle");

  const salesReturnsTableColumns = [
    { header: "Date", apiKey: "orderDate" },
    { header: "Order Id", apiKey: "siteOrderID" },
    { header: "Invoice Id", apiKey: "invoiceID" },
    { header: "Brand", apiKey: "brand" },
    { header: "SKU", apiKey: "sku" },
    { header: "Title", apiKey: "title" },
    { header: "Qty Sold", apiKey: "quantity" },
    { header: "GMV($)", apiKey: "gmv", symbol: "$" },
    { header: "Marketplace Fees($)", apiKey: "marketplaceFee", symbol: "$" },
    { header: "Resale Fees($)", apiKey: "resaleFee", symbol: "$" },
    { header: "Gross Proceeds($)", apiKey: "netProceeds", symbol: "$" },
  ];
  const salesReturnsTableColumnsConvetional = [
    { header: "Date", apiKey: "createdDate" },
    { header: "Seller Name", apiKey: "sellerName" },
    { header: "Seller Order Code", apiKey: "sellerOrderCode" },
    { header: "Seller Order Number", apiKey: "sellerOrderNumber" },
    { header: "LineItem ID", apiKey: "lineItemID" },
    { header: "LineItem Title", apiKey: "lineItemTitle" },
    { header: "LineItem SKU", apiKey: "lineItemSKU" },
    { header: "LineItem Quantity", apiKey: "lineItemQuantity" },
    // { header: "LineItem Retail Price", apiKey: "lineItemRetailPrice" },
    // { header: "LineItem Price", apiKey: "lineItemPrice" },
    { header: "GMV", apiKey: "gmv" },
    { header: "Margin", apiKey: "margin" },
    { header: "Total", apiKey: "netPayout" },
    { header: "Order Fee", apiKey: "orderFee" },
  ];
  const getSalesData = async () => {
    if (data?.payoutType == 1) {
      const result = await getDetailedPayoutReportbyDCIDConvictional(
        data?.id,
        initialPayloadConv
      );
      console.log("Result666", result);
      setSalesData(result);
    } else {
      const res = await getDetailedPayoutReportbyDCID(
        data?.dcid,
        initialPayload
      );
      console.log("Result666", res);
      setSalesData(res);
    }
  };

  const getSummarydatabyDCID = async () => {
    const { billingCycle, items } = await getSummarydatabyDCIDAPI(data?.dcid);
    console.log(billingCycle, items, "9((((")
    setBillingCycle(billingCycle);
    setSummaryData(items);
  };

  useEffect(() => {
    getSummarydatabyDCID();
  }, []);

  useEffect(() => {
    if (page === 0 || page) {
      getSalesData();
    }
  }, [page, dateFilter, status]);

  useEffect(() => {
    setPage(0);
    if (value === "2") setstatus("Shipped");
    else if (value === "3") setstatus("Returned");
    else setstatus("");
  }, [value]);

  const handleChangeDate = (e: any) => {
    let string = e.target.value.split("/");
    setDateFilter(string);
  };
  useEffect(() => {
    setPage(0);
  }, [dateFilter]);

  const openImportModal = () => {
    setshowimportmodal(true);
  };

  const handleSelectFile = (e: any) => {
    const files = e.target.files;
    setFile(files[0]);
    setIsError(false);
  };

  const uploadCSV = async () => {
    const formData = new FormData();
    // formData.append('file',File );
    formData.append("file", File);
    if (data?.payoutType == 0) {
      if (File) {
        setIsError(false);
        const res = await uploadSummaryCSVAPI(data?.dcid, formData);
        if (res?.status >= 200 && res?.status < 299) {
          setshowimportmodal(false);
          setErrorMEssage("");
          if (value === "1") {
            await getSummarydatabyDCID();
            await getSalesData();
          } else {
            await getSalesData();
          }
          setMessageText("File Uploaded Successfully");
          setOpen(true);
        } else {
          setErrorMEssage(res.exception);
        }
      } else {
        setIsError(true);
      }
    }
    else {
      if (File) {
        setIsError(false);
        const res = await uploadSummaryCSVAPIConvictional(data?.id, formData);
        if (res?.status >= 200 && res?.status < 299) {
          setshowimportmodal(false);
          setErrorMEssage("");
          if (value === "1") {
            await getSummarydatabyDCID();
            await getSalesData();
          } else {
            await getSalesData();
          }
          setMessageText("File Uploaded Successfully");
          setOpen(true);
        } else {
          setErrorMEssage(res.exception);
        }
      } else {
        setIsError(true);
      }
    }
    setFile(null);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2.5,
        px: isMobile ? 2 : 5,
      }}
    >
      {showimportmodal && (
        <Dialog
          open={showimportmodal}
          onClose={() => {
            setErrorMEssage("");
            setIsError(false);
            setshowimportmodal(false);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            ...styless.dialogContainer,
            ".MuiPaper-root": { minWidth: isMobile ? 350 : 400 },
          }}
        >
          <DialogTitle
            sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
          >
            {value === "1" && "Import Summary data"}
            {value === "2" && "Import Sales data"}
            {value === "3" && "Import Returns data"}
            <IconButton
              aria-label="close"
              onClick={() => {
                setErrorMEssage("");
                setIsError(false);
                setshowimportmodal(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* ) : null} */}
          </DialogTitle>

          <DialogContent sx={{ p: 2.5 }}>
            <Box sx={{ py: 1.5 }}>
              <Typography variant="h6">
                Please upload file in .xlsx format
              </Typography>
            </Box>
            <Box>
              <Form.Control
                id="file-input"
                type="file"
                className={styles.fileTextfield}
                accept="csv/*"
                onChange={(event: any) => {
                  if (
                    event.target.files[0].size <= 10000000 &&
                    event.target.files[0].size !== null
                  ) {
                    handleSelectFile(event);
                  }
                }}
              />
            </Box>
            {isError ? (
              <Typography variant="caption" sx={style.errorMsg}>
                Required
              </Typography>
            ) : (
              ""
            )}
            {
              <Typography variant="caption" sx={style.errorMsg}>
                {errorMessage}
              </Typography>
            }
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: "flex-start",
              pl: 2.5,
              pb: 3,
              "&>:not(:first-of-type)": { ml: 2.5 },
            }}
          >
            <Button
              onClick={() => uploadCSV()}
              sx={{
                ...styless.modalBtn,
                background: "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                color: "#fff",
              }}
            >
              Upload
            </Button>
            <Button
              onClick={() => {
                setErrorMEssage("");
                setIsError(false);
                setshowimportmodal(false);
              }}
              sx={{
                ...styless.modalBtn,
                ml: 2,
                border: "1px solid rgba(104, 115, 125, 0.48)",
                color: "#68737D",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box display="flex" justifyContent="space-between" mb={1.5}>
        <Stack>
          <Typography
            variant="h4"
            fontSize={20}
            fontWeight={500}
            color="#000"
            mb={2}
          >
            {data?.companyName} Payout Report
          </Typography>
        </Stack>
        <Stack direction="row">
          <Stack
            justifyContent="center"
            direction="row"
            alignItems="center"
            gap={"10px"}
            sx={{ px: "10px" }}
          >
            <Typography
              variant="body2"
              fontSize={14}
              fontWeight={500}
              color="#1A1918"
            >
              {billingCycle === 0 && "Weekly"}
              {billingCycle === 1 && "Bi-Weekly"}
              {billingCycle === 2 && "Monthly"}
              {billingCycle === 3 && "Bi-Monthly"}
            </Typography>
            <Calendar />

            <FormControl
              variant="standard"
              sx={{
                maxWidth: 280,
                ".MuiInputBase-root": {
                  "&:before": {
                    borderBottom: "unset",
                  },
                  "&:after": {
                    borderBottom: "unset",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "unset",
                  },
                },
              }}
            >
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleChangeDate}
                inputProps={{ "aria-label": "Without label" }}
                value={dateFilter.join("/")}
              >
                {monthoptions &&
                  monthoptions.map((month: any, index: any) => {
                    return (
                      <MenuItem value={month.value}>
                        {month.label}
                        {index === 0 && " (Open)"}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Stack>
          <Button
            variant="contained"
            sx={{
              ...styless.fiiledBtn,
              ...styless.outlineNewBtn,
              color: "#68737D",
            }}
            onClick={() => exportPayoutReport()}
          >
            <FileDownloadOutlinedIcon style={{ marginRight: "10px" }} />
            Export
          </Button>
          <Button
            variant="contained"
            sx={{
              ...styless.fiiledBtn,
              ...styless.outlineNewBtn,
              color: "#68737D",
            }}
            onClick={() => openImportModal()}
          >
            <FileUploadOutlinedIcon style={{ marginRight: "10px" }} />
            Import
          </Button>
        </Stack>
      </Box>
      <TabContext value={value}>
        <Box sx={style.tabContainer}>
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            sx={style.tabList}
          >
            <Tab label="Summary" value="1" />
            <Tab label="Sales" value="2" />
            <Tab label="Returns" value="3" />
          </TabList>
        </Box>
        <Divider
          sx={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#E0E0E0",
            borderBottomWidth: "inherit",
            opacity: 1,
          }}
        />
        <TabPanel value="1">
          <Box>
            <SummaryPayout data={summaryData} reportType={data?.payoutType} />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box>
            <Stack
              mb={2}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              <Pagination
                page={page}
                totalCount={
                  salesData?.results?.count ? salesData?.results.count : 0
                }
                setPage={setPage}
              />
            </Stack>
            {data?.payoutType == 1 ? (
              <DetailedReportTable
                page={page}
                data={salesData?.results.itemsConvictional}
                columns={salesReturnsTableColumnsConvetional}
                dcid={data?.dcid}
                value={value}
              />
            ) : (
              <DetailedReportTable
                page={page}
                data={salesData?.results.items}
                columns={salesReturnsTableColumns}
                dcid={data?.dcid}
                value={value}
              />
            )}{" "}
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Box>
            <Stack
              mb={2}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              <Pagination
                page={page}
                totalCount={
                  salesData?.results.count ? salesData?.results.count : 0
                }
                setPage={setPage}
              />
            </Stack>
            <DetailedReportTable
              page={page}
              data={salesData?.results.items}
              columns={salesReturnsTableColumns}
              dcid={data?.dcid}
              value={value}
            />{" "}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Index;
