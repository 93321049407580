import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";

const BusinessDetails = (props: any) => {
  const formik: any = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    onSubmit: async () => {
      console.log("first");
    },
  });
  const { setopenSetting } = props;
  return (
    <>
      <Box>
        Edit Business Details
        <FormikProvider value={formik}>
          <Grid container rowSpacing={1} columnSpacing={5}>
        
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Company Name*</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Company Name"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Business Email Address</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Email"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Address 1</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Address 1"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Address 2</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Address 2"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>City</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="City"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Country/Region</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Country/Region"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>State/Province</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="State/Province"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Zip Code</Typography>
              <Field name="firstname">
                {({ field, meta }: any) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    required
                    fullWidth
                    {...field}
                    label="Zip code"
                    //   sx={{m:1}}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
            <Button sx={{ background: "lightBlue", m: 2 }}>Save</Button>
            <Button sx={{ m: 2 }} onClick={() => setopenSetting(0)}>
              cancel
            </Button>
          </Box>
        </FormikProvider>
      </Box>
    </>
  );
};
export default BusinessDetails;
