import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, Grid, Stack, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import Tab from '@mui/material/Tab';
import moment from "moment";
import * as React from 'react';
import { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import ImageCarousel from "../imageCarosal";
import Pagination from '../paginationListing';
import styles from "./index.module.scss";
import { useListingDashboardAPI } from "../../../shared/api/userListingDashboardSAAS";

export const style = {
    tabContainer: {
        '.MuiTabs-indicator': {
            display: 'none',
        },
        '.MuiTab-root': {
            '&.Mui-selected': {
                color: '#1A1918',
                fontWeight: 500
            }
        }
    }
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2, border: '1px solid #E0E0E0' }}>
                    <Typography variant='body2' fontSize={14} fontWeight={300} color='#68737D'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Index = (props: any) => {
    const { getMarketPlaceDetail } = useListingDashboardAPI();
    const { data, setTabsAdded } = props;
    const [value, setValue] = useState(0);
    const [tabeData, setTableData] = useState<any>({
        count: 0,
        labels: []
    });
    const theme = useTheme();
    const [page, setPage] = useState<number>(0);
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    const payload = {
        productId: data?.productId,
        offset: 0,
        limit: 10
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getMarketPlaces = async (payload: any) => {
        const res = await getMarketPlaceDetail(payload);
        setTableData(res)
    }

    useEffect(() => {
        getMarketPlaces(payload);
    }, [])

    return (<>

        <Box>
            <Stack
                px={1.5}
                py={2}
                justifyContent="center"
                sx={{
                    backgroundColor: "#FAFBFC",
                    borderBottom: "1px solid #E0E0E0",
                    height: 44,
                }}
            >
                <Stack flexDirection='row' alignItems='center'>
                    <Button sx={{ p: 0 }} onClick={() => setTabsAdded(false)}>Dashboard </Button> /
                    <Typography> SKU - {data.inventoryNumber}</Typography>
                </Stack>
            </Stack>

            <Box
                sx={{
                    flexGrow: 1,
                    py: 2.5,
                    px: isMobile ? 2 : 5,
                }}>



                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Stack mb={1.5}>
                            <Typography
                                variant="h4"
                                fontSize={20}
                                fontWeight={500}
                                color="#000"
                                mb={2}
                            >
                                SKU - {data.inventoryNumber}
                            </Typography>
                            <Typography variant="body1" fontSize={16} fontWeight={500} color="#68737D">UPC - {data.upc}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            className={`${styles.searchInput}`}
                        >
                            <Search className={styles.searchIcon} />
                            <input
                                type="search"
                                placeholder="Search"
                                style={{ width: "100%" }}
                            />
                            <CloseIcon
                            />
                        </Stack>
                    </Grid>
                </Grid>



                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body2" fontSize={16} fontWeight={500} color='#1A1918'>Created : <span style={{ fontWeight: 300 }}>{data.createdTime ? moment(data.itemCreateDate).format("MM/DD/YYYY") : ""}</span></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" fontSize={16} fontWeight={500} color='#1A1918'>Last Updated : <span style={{ fontWeight: 300 }}>{data.updatedTime ? moment(data.itemLastModifiedDate).format("MM/DD/YYYY") : ""}</span></Typography>
                            </Grid>
                        </Grid>
                        <Divider flexItem sx={{
                            mt: '14px',
                            mb: '30px',
                            borderWidth: 1,
                            borderColor: "#E0E0E0",
                            opacity: 1,
                        }} />
                        <ImageCarousel images={data.pictureURLs} />
                        <Box>
                            <Typography variant="h6" fontSize={20} fontWeight={500} color='#1A1918' textAlign='center'>{data.brand}</Typography>
                            <Typography variant="body2" fontSize={14} fontWeight={300} color='#68737D' my={1.5}>{data.auctionTitle}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontSize={14} fontWeight={300} color='#68737D'>Quantity : <span style={{ fontWeight: 500, color: '#000' }}>{data.totalQuantity}</span></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontSize={14} fontWeight={300} color='#68737D'>Price : <span style={{ fontWeight: 500, color: '#000' }}>{formatter.format(data.retailPrice)}</span></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontSize={14} fontWeight={300} color='#68737D'>Condition : <span style={{ fontWeight: 500, color: '#000' }}>New</span></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontSize={14} fontWeight={300} color='#68737D'>Available In store : <span style={{ fontWeight: 500, color: '#000' }}>{data.totalQuantity !== "0" ? "Yes" : "No"}</span></Typography>
                                </Grid>
                            </Grid>

                            <Box sx={{ width: '100%', typography: 'body1', mt: 2 }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                    sx={style.tabContainer}
                                >
                                    <Tab label="Description" {...a11yProps(0)} sx={{ textTransform: 'capitalize', fontSize: 14, fontWeight: 500, color: '#68737D' }} />
                                    <Divider orientation="vertical" flexItem />
                                    <Tab label="Product Details" sx={{ textTransform: 'capitalize', fontSize: 14, fontWeight: 500, color: '#68737D' }} {...a11yProps(1)} />
                                </Tabs>
                                <CustomTabPanel value={value} index={0}>
                                    {data.description}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    {data.shortDescription}
                                </CustomTabPanel>
                            </Box>


                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <Box>
                            <Stack mb={2} direction='row' alignItems='center' justifyContent='space-between'>
                                <Typography variant="body2" fontSize={14} fontWeight={500} color='#68737D'>{tabeData.count} Market Places/channel</Typography>
                                <Pagination page={page} totalCount={tabeData.count ? tabeData.count : 0} setPage={setPage} />
                            </Stack>
                            <div className={styles.heightG}>
                                {tabeData.count ? <table className={styles.tableH}>
                                    <thead className={`${styles.thead} rounded`}>
                                        <tr>
                                            <td>Market</td>
                                            {/* <td>Status</td>
                                            <td>Updated</td> */}
                                        </tr>
                                    </thead>
                                    <tbody className={styles.tbody}>
                                        {tabeData.labels?.map((ele: any) => (
                                            <tr>
                                                <td>{ele}</td>
                                                {/* <td>{ele?.status}</td>
                                                <td>{ele?.updatedOn ? moment(ele?.updatedOn).format("MM/DD/YYYY") : ""}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> :
                                    <tr className={styles.noRecordRow}>
                                        <td colSpan={6}>
                                            <Typography variant="body2" textAlign='center' fontSize={14} fontWeight={300}>No records found</Typography>
                                        </td>
                                    </tr>
                                }
                            </div>
                        </Box>
                    </Grid>
                </Grid>

                {/* <Box>
                    <Box display="flex" mt={3}>

                    </Box>

                    <div>Nike</div>
                    <div>Nike Air Jordan 1 Mid Aquatone Blue Gold Men AJ1 Casual Lifestyle</div>
                    <div>Price : US $114.99</div>  <div>Quantity : 655</div>
                    <div>Condition : New</div>  <div>Available In store : yes</div>
                    <div>Description : Product Details</div>
                    <div>New with box: A brand-new, unused, and unworn item (including handmade items) in the original packaging (such as the original box or bag) and/or with the original tags attached.</div>

                    <Box>
                        <Stack mb={2} direction='row' alignItems='center' justifyContent='space-between'>
                            <Typography variant="body2" fontSize={14} fontWeight={500} color='#68737D'>6 Market Places/channel</Typography>
                            <Pagination page={1} totalCount={tableData.count ? tableData.count : 0} setPage={setPage} />
                        </Stack>
                        <div className={styles.heightG}>
                            <table className={styles.tableH}>
                                <thead className={`${styles.thead} rounded`}>
                                    <tr>
                                        <td>Market</td>
                                        <td>Status</td>
                                        <td>Updated</td>
                                    </tr>
                                </thead>
                                <tbody className={styles.tbody}>
                                    {tableData.items?.map((ele: any) => (
                                        <tr>
                                            <td>{ele?.market}</td>
                                            <td>{ele?.status}</td>
                                            <td>{ele?.updatedOn ? moment(ele?.updatedOn).format("MM/DD/YYYY") : ""}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Box>
                </Box> */}
            </Box>
        </Box>

    </>)
}
export default Index;