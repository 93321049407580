import moment from "moment";
import styles from "./index.module.scss";
import { ReactComponent as True } from "../../../../assets/images/icons/true.svg";
import { ReactComponent as False } from "../../../../assets/images/icons/false.svg";
import { Link, Stack, Typography } from "@mui/material";

export const style = {
  tableData: {
    fontSize: 14,
    fontWeight: 300,
  },
};
interface IProps {
  data: Array<object>;
  fetchReportDetails?: any;
  addPanels?: any;
  page?: any;
}

const Index = (props: IProps) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const { data, fetchReportDetails, addPanels, page } = props;
  return (
    <>
      <div className={`${styles.heightG}`}>
        <table className={styles.tableH}>
          <thead className={`${styles.thead} rounded`}>
            <tr>
              <td></td>
              <td>Organization</td>
              {/* <td>Status</td>
              <td>Start date</td> */}
              {/* <td>Aggrement</td> */}
              <td align="right">Net amount</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {data?.map((ele: any, index: number) => (
              <tr
                onClick={() => fetchReportDetails(ele.dcid)}
                style={{ cursor: "pointer" }}
              >
                <td>{(parseInt(page) ? page : 0) * 10 + (index + 1)}</td>
                {/* <td>{ele?.organization}</td> */}
                <td onClick={() => addPanels(ele)}>
                  <Link
                    variant="body2"
                    color="#0038BA"
                    underline="hover"
                    sx={{
                      ...style.tableData,
                      cursor: "pointer",
                      width: "max-content",
                      "&:hover": { color: "#004EFF" },
                    }}
                  >
                    {ele?.companyName}
                  </Link>
                </td>

                {/* <td>
                  <Stack direction="row" alignItems="center" gap="5px">
                    <True />
                    <Typography variant="body2" sx={style.tableData}>
                      Active
                    </Typography>
                  </Stack>
                </td>
                <td>
                  {ele?.createdDate
                    ? moment(ele?.createdDate).format("MM/DD/YYYY")
                    : ""}
                </td> */}
                <td align="right">{formatter.format(ele?.netPrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Index;
