import { useEffect, useState } from "react"
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS"
import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import Loader from "../../../loader";
import moment from "moment";
import { ReactComponent as Download } from "../../../assets/images/icons/download.svg";
import { IconButton } from "@mui/material";

const UserInvoice = () => {
    const { isLoading } = useAuth();
    const { getUserInvoiceDetails,ExportInvoiceAPI } = useSubScriptionAPI()
    const [userInvoice, setUserInvoice] = useState<any>()
    const loadInitialData = async () => {
        const result = await getUserInvoiceDetails("", 0, 10, localStorage.getItem("userId"))
        setUserInvoice(result)
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    useEffect(() => {
        loadInitialData()
    }, [])

    const exportInvoice = async (id:any) => {
       
        const res = await ExportInvoiceAPI(id);
        if (res.status === 200) {
          var data = new Blob([res.data, res.data, res.data], {
            type: "application/pdf;base64,",
          });
          var csvURL = window.URL.createObjectURL(data);
          let tempLink;
          tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", "invoice.pdf");
          tempLink.click();
        }
      };
    return (
        <>
            <div className={`table-responsive ${styles.heightG}`}>
                <table className={styles.tableH}>
                    <thead className={`${styles.thead} rounded`}>
                        <tr>
                            <td>Subscription</td>
                            <td>Date</td>
                            <td>Number</td>
                            <td>Amount</td>
                            <td>Due Date</td>
                            <td style={{ width: '100px' }}></td>
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {!isLoading ? (
                            <>
                                {
                                    // listingUserCount > 0 ?
                                    userInvoice &&
                                    userInvoice?.map((value: any) => {
                                        return (
                                            value?.transactions?.map((item: any) => {
                                                return (
                                                    <tr>
                                                        <td>{value?.subscription?.subscriptionName}</td>
                                                        <td>{moment(new Date(value?.subscription?.updateTime), "en-us").format("DD-MMM-YYYY")}</td>
                                                        <td>{item?.orderId}</td>
                                                        {/* <td>$ {item?.price}/ {value?.subscription?.subscriptionPlanStatusDto == 30 ? "Month" : "Year"}</td> */}
                                                        <td>{formatter.format(item?.price)}/month</td>
                                                        <td>{moment(new Date(value?.subscription?.subscriptionEndDate), "en-us").format("DD-MMM-YYYY")}</td>
                                                        <td style={{ width: '100px' }}><IconButton aria-label="download" onClick={()=>exportInvoice(item.id)}><Download /></IconButton></td>
                                                    </tr>
                                                )
                                            })
                                        );
                                    })
                                    // :
                                    // <tr>
                                    //     <td colSpan={7}>
                                    //         <p className='mb-0 py-2'>No records found</p>
                                    //     </td>
                                    // </tr>
                                }
                            </>
                        ) : (
                            // <Loader />
                            ""
                        )}
                    </tbody>


                </table>
            </div>
        </>
    )
}
export default UserInvoice