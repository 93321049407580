import { Box, Stack, Typography } from "@mui/material"
import styles from "./index.module.scss";
import { ReactComponent as LogoIcon } from "../../../assets/images/resale-icon-large.svg";
import ellipse from "../../../assets/images/ellipse.jpg";

const RegistrationSuccess = () => {
  return (
    <>
      {/* <Stack
          justifyContent="center"
          sx={{ background: "#fff" }}
          className={styles.formContainer}
        >
         
          <Stack sx={{ px: 6, py: 4, position: "relative" }}>
           
            <Stack mt={3} mb={1}>
              <Typography variant="h4" fontSize={20} fontWeight={600}>
                Thank You for Registering
              </Typography>
              <Typography variant="body2" fontSize={14} fontWeight={300}>
                Get started with your account
              </Typography>
            </Stack>
        
          </Stack>
        </Stack> */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.formWrapper}
      >
        <Stack
          justifyContent="center"
          sx={{ background: "#fff", overflow: 'hidden' }}
          className={styles.formContainer}
        >
          <img
            src={ellipse}
            alt="ellipse"
            className={`${styles.responsiveImg} ${styles.formBgImg} ${styles.topSpace}`}
          />
          <Stack sx={{ px: 6, py: 7, position: "relative" }}>
            <Stack flexDirection="row" justifyContent="center">
              <LogoIcon className={styles.keyIcon} />
            </Stack>
            <Stack mt={5} mb={1} alignItems="center" color="#000">
              <Typography variant="h4" fontSize={30} fontWeight={600} mb={2.5}>
                Thank You for Registering
              </Typography>
              <Typography variant="body2" fontSize={16} fontWeight={300} mb={2} lineHeight='28px'>
                Your registration has been successfully submitted and is currently under review by our administrative team.
              </Typography>
              <Typography variant="body2" fontSize={16} fontWeight={300} lineHeight='28px'>
                You will receive a confirmation email once your account has been activated. <span style={{ fontWeight: 500 }}>We appreciate your patience.</span>
              </Typography>
            </Stack>

          </Stack>
        </Stack>
      </Box>
    </>
  )
}
export default RegistrationSuccess