import { useLocalStorageState } from "ahooks";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authprovider";
import { environmentUrl } from "./index.service";

export interface IUseAuth {
  token: any | null;
}
export const useClientAPI = () => {
  const { apinew, setLoading, setShortMessage, setMessageText, setOpen, } = useAuth();
  const [errorMessageText, setErrorMessageText] = useState<any>("");
  const [listingUserList, setListingUserList] = useState<any>();
  const [listingUserCount, setListingUserCount] = useState<any>(0);
  const [token, setToken] = useLocalStorageState<any | null>("");
  const [roles, setRoles] = useState<any | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getDataFromStorage = () => {
    const token = sessionStorage.getItem("token");
    const role = localStorage.getItem("role");
    setRoles(role);
    setToken(token);
  };
  //add new client
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)
  const postNewClient = async (details: any) => {
    try {
      const newData = await apinew.post(`/api/User`, details);
      if (newData?.data?.status == 200) {
        setShortMessage("Account Created")
        setMessageText("Your account has been successfully created.");
        setOpen(true);
        setRegistrationSuccess(true)
      }
      else {
        setShortMessage("Error Creating Account")
        setMessageText(newData?.data?.result);
        setOpen(true);
      }
      return newData;
    } catch (error: any) {
      setShortMessage("Error Creating Account")
      setMessageText(error?.response?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const getUser = async (id: any) => {
    try {
      const data = await axios.get(`${environmentUrl}/api/User/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
      setUserData(data?.data?.result);
      return data;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const signInListing = async (details: any) => {
    setLoading(true);
    try {
      const result = await axios.post(
        `${environmentUrl}/api/Authentication/token`,
        details
      );

      localStorage.setItem("rememberMe", details.rememberMe)

      setToken(result?.data?.token);
      setRoles(result?.data?.roles);
      localStorage.setItem("role", result?.data?.roles);
      sessionStorage.setItem("token", result?.data?.token);
      if (details.rememberMe) {
        localStorage.setItem("token", result?.data?.token);
      }else{
        localStorage.removeItem("token");
      }
      localStorage.setItem("userId", result?.data?.userId);
      localStorage.setItem("email", result?.data?.email);
      localStorage.setItem("mobile", result?.data?.mobileNumber);
      localStorage.setItem("dcid", result?.data?.dcid);
      localStorage.setItem("isConvictional", result?.data?.payoutType)
      return result;
    } catch (error: any) {
      handleErrorSaas(error);
    } finally {
      setLoading(false);
    }
  };

  const handleErrorSaas = (error: any) => {
    if (error.response?.status === 401) {
      setErrorMessageText(error?.response?.data);
      navigate("/listing-login");
    }
    if (error.response?.status === 400) {
      setErrorMessageText(error?.response?.data);
      console.log(error?.response?.data);
    } else {
      setErrorMessageText(error?.response?.data);
      console.log(error?.response?.data);
    }
  };

  //forget password
  const forgotPasswordListingUser = async (details: any) => {
    try {
      const result = await apinew.post(`/api/User/ForgotPassword`, details);
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
    }
  };

  // deleteuser
  const deleteListingUser = async (id: any) => {
    try {
      // const newData = await apinew.delete(`/api/Admin/DeactivateUser?id=${id}`);
      const newData = await apinew.delete(`/api/Admin/DeleteUser?id=${id}`);
      return newData;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
    }
  };
  const getListingUserList = async (payload: any) => {
    try {
      const result = await apinew?.post(`/api/User/List`, payload);
      return result;
    } catch (error: any) {
      handleErrorSaas(error);
    }
  };
  const putAcceptUser = async (id: any) => {
    try {
      const newData = await apinew.put(`/api/Admin/ApproveUser`, id);
      return newData;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
    }
  };
  const putRejectUser = async (id: any) => {
    try {
      const newData = await apinew.put(`/api/Admin/DispproveUser`, id);
      return newData;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
    }
  };

  // resetPasswordListing api/User/ResetPassword
  const resetPasswordListing = async (details: any) => {
    try {
      const newData = await apinew.post(`/api/User/ResetPassword`, details);
      if (newData?.data?.status == 200) {
        setMessageText("Password Changed");
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
          navigate("/listing-login");
        }, 3000);
      }
      return newData;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };

  const fetchUserData = async (id: any) => {
    try {
      const res = await apinew.get(`/api/User/${id}`);
      if (res?.status == 200) {
        return res.data.result;
      }
    } catch (error: any) {
    }
  };

  const getCount = async () => {
    try {
      const res = await apinew.get(`/api/User/UserCount`);
      return res;
    } catch (error: any) {
    }
  };

  const editUserData = async (payload: any) => {
    try {
      const res = await apinew.put(`/api/Account`, payload);
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
    }
  };

  return {
    postNewClient,
    signInListing,
    errorMessageText,
    forgotPasswordListingUser,
    getListingUserList,
    listingUserList,
    listingUserCount,
    putAcceptUser,
    putRejectUser,
    getUser,
    userData,
    apinew,
    roles,
    token,
    handleErrorSaas,
    deleteListingUser,
    resetPasswordListing,
    registrationSuccess,
    fetchUserData,
    editUserData,
    getCount,
  } as const;
};
