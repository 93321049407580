import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import { style } from ".";


const Index = (props: any) => {
    const { show, modalTitle, setShow, userData, putRejectUserRequest, comment, setComment } = props;

    return (<>
        <Dialog
            open={show}
            onClose={() => { setShow(false); setComment("") }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
        >
            <DialogTitle sx={{ m: 0, p: 2.5, fontSize: 18, fontWeight: 300 }}>
                <Typography variant="body2" fontWeight={300} fontSize={14}>
                    {modalTitle}{" "}
                    <span style={{ fontWeight: "500" }}>
                        {userData?.firstName}{" "}
                    </span>
                    Registartion Request
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => { setShow(false) }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
                <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <InputLabel>Email Address</InputLabel>
                            <TextField
                                id="standard-read-only-input"
                                defaultValue={userData?.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                fullWidth
                                sx={{ fontSize: 16 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <InputLabel>Organisation Name</InputLabel>
                            <TextField
                                id="standard-read-only-input"
                                defaultValue={userData?.company}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                fullWidth
                                sx={{ fontSize: 16 }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <InputLabel>Comments</InputLabel>
                                <TextField
                                    id="standard-required"
                                    multiline
                                    placeholder="State reason for rejecting users request..."
                                    autoComplete="off"
                                    fullWidth
                                    required
                                    variant="standard"
                                    rows={2}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Box>
                        </Grid>

                    </Grid>

                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    justifyContent: "center",
                    pb: 3,
                    "&>:not(:first-of-type)": { ml: 2.5 },
                }}
            >
                <Button
                    sx={{
                        ...style.modalBtn,
                        background:
                            "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                        color: "#fff",
                    }}
                    onClick={() => {
                        putRejectUserRequest(userData?.id);
                    }}
                >
                    Reject
                </Button>
            </DialogActions>
        </Dialog >
    </>)
}
export default Index;