import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Reload } from "../../../assets/images/icons/reload.svg";
import HeaderListing from "../../../components/headerListing";
import Pagination from "../paginationListing";
import styles from "./index.module.scss";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../shared/provider/authprovider";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Reject } from "../../../assets/images/icons/reject.svg";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { style as styless } from "../../listingScreens/superAdminDashboard/index";
import DeletePopUp from "./deletePopUp";

export const style = {
  modalBtn: {
    width: "100%",
    maxWidth: "100px",
    borderRadius: 0.5,
    textTransform: "capitalize",
    fontSize: 14,
    height: 34,
  },
};

const Index = () => {
  const { destinatonDashData, deleteIndividualEntry, getImageist } =
    useIntegrationsSAAS();
  const theme = useTheme();
  const navigate = useNavigate();
  const [imgList, setImgList] = useState<any>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const [prefix, setPrefix] = useState<string>("");
  const { setSelectedIndex, setIntegrationTabIndex } = useAuth();
  const userId = localStorage.getItem("userId");
  const [page, setPage] = useState<number>(0);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteRecordId, seteleteRecordId] = useState<any>(null);
  const [tableData, setTableData] = useState<any>({
    count: 0,
    items: [],
  });

  const initialPayload = {
    sourceDestinationType: 1,
    userId: userId,
    limit: 50,
    offSet: page,
    prefix: prefix,
  };

  const [payload, setPayload] = useState<any>(initialPayload);

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      loadInitialData(prefix);
    }
  };

  const loadInitialData = async (prefix?: any) => {
    try {
      const res: any = await destinatonDashData({
        ...payload,
        prefix: prefix ? prefix : "",
      });
      console.log(res);
      if (res.status === 200) {
        setTableData(res.data.result);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (page === 0 || page) {
      loadInitialData();
    }
  }, [page]);

  const getImage = async () => {
    const res = await getImageist();
    if (res.status === 200) {
      setImgList(res.data.result);
    }
  };

  useEffect(() => {
    setSelectedIndex(7);
    getImage();
  }, []);

  const getCapabilitiesName = (item: any) => {
    return `${item.enableGetOrders && "Orders"} ${item.enableGetProducts ? ", Products" : ""
      } ${item.enableInventoryInfo ? ", Inventory Info" : ""} ${item.enableShipmentInfo ? ", Shipment Info" : ""
      }`;
  };

  const deleteRecord = async () => {
    const res: any = await deleteIndividualEntry(deleteRecordId);
    if (res.status === 200) {
      loadInitialData();
      setOpenDeletePopUp(false);
    }
  };

  const handleOpenPopUp = (id: any) => {
    seteleteRecordId(id);
    setOpenDeletePopUp(true);
  };

  return (
    <>
      {openDeletePopUp && (
        <DeletePopUp
          header={"Destination"}
          open={openDeletePopUp}
          setOpen={setOpenDeletePopUp}
          handleDeleteUser={deleteRecord}
        />
      )}

      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{ flexGrow: 1, py: 2.5, px: isMobile ? 2 : 5 }}
      >
        <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" mb={1.5}>
          <Stack>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={2}
            >
              Destination
            </Typography>
          </Stack>

          <Box display="flex">
            <Stack
              direction="row"
              alignItems="center"
              className={`${styles.searchInput}`}
              sx={{ height: 40 }}
            >
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyDown={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
                className={styles.customSearchInput}
              />
              <CloseIcon
                onClick={async () => {
                  setPrefix("");
                  loadInitialData("");
                }}
              />
            </Stack>

            <Button
              variant="contained"
              sx={{ ...styless.fiiledBtn, mb: 0.5, }}
              // py:isMobile? 1.5:0, px:isMobile?2.5:0 
              onClick={() => {
                setIntegrationTabIndex("2");
                navigate("/listing-integration");
              }}
            >
              <AddIcon style={{ marginRight: "6px" }} /> New Destination
            </Button>

          </Box>
        </Box>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              fontSize={14}
              fontWeight={500}
              color="#68737D"
            ></Typography>
            <Pagination
              page={page}
              totalCount={tableData.count ? tableData.count : 0}
              setPage={setPage}
            />
          </Stack>

          <div className={styles.heightG}>
            <table className={styles.tableH}>
              <thead className={`${styles.thead} rounded`}>
                <tr>
                  <td></td>
                  <td>Destination</td>
                  <td>Instance Name</td>
                  <td>Cron Name</td>
                  <td>Connection Status</td>
                  <td>Created</td>
                  <td>Domain</td>
                  {/* <td>Total Attributes</td> */}
                  <td>Capabilities</td>
                  <td className={styles.actionCol}>Actions</td>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {tableData?.count ? (
                  tableData?.items?.map((ele: any, index: number) => (
                    <tr>
                      <td>{page * 10 + (index + 1)}</td>
                      <td>
                        <Stack direction="row" alignItems="center">
                          <img
                            src={`data:image/svg+xml;base64,${imgList?.find(
                              (item: any) =>
                                item.accountId === ele.accountType
                            )?.image
                              }`}
                            className={styles.tableImg}
                          />{" "}
                          <Typography
                            variant="body2"
                            fontSize={14}
                            fontWeight={300}
                            ml={0.5}
                          >
                            {" "}
                            {ele?.accountName}
                          </Typography>
                        </Stack>
                      </td>
                      <td>{ele?.name}</td>
                      <td>
                        <Typography
                          variant="subtitle2"
                          color="#0038BA"
                          fontSize={14}
                          fontWeight={500}
                        >
                          {ele?.cronName}
                        </Typography>
                      </td>
                      <td>
                        {ele?.isActive ? (
                          <Stack direction="row" alignItems="center" gap="5px">
                            <Accept />
                            <Typography
                              variant="body2"
                              fontSize={14}
                              fontWeight={300}
                            >
                              Success
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack direction="row" alignItems="center" gap="5px">
                            <Reject />
                            <Typography
                              variant="body2"
                              fontSize={14}
                              fontWeight={300}
                            >
                              Failed
                            </Typography>
                          </Stack>
                        )}
                      </td>
                      <td>
                        {ele?.createdTime
                          ? moment(ele?.createdTime).format("MM-DD-YYYY")
                          : ""}
                      </td>
                      <td>{ele?.url}</td>
                      {/* <td>{ele?.totalAttribute}</td> */}
                      <td>{getCapabilitiesName(ele)}</td>
                      <td>
                        <Stack direction="row" spacing={1}>
                          <Tooltip title="Edit" placement="top" arrow>
                            <IconButton
                              aria-label="Edit"
                              onClick={() =>
                                navigate(
                                  `/listing-add-new-destination-integration/${ele.name}`,
                                  { state: { id: ele.id } }
                                )
                              }
                            >
                              <Edit className={styles.tBtn} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top" arrow>
                            <IconButton aria-label="Delete">
                              <Delete
                                className={styles.tBtn}
                                onClick={() => handleOpenPopUp(ele.id)}
                              />
                            </IconButton>
                          </Tooltip>

                          <IconButton aria-label="Reload">
                            <Reload className={styles.tBtn} />
                          </IconButton>
                        </Stack>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={styles.noRecordRow}>
                    <td colSpan={10}>
                      <Typography
                        variant="body2"
                        textAlign="center"
                        fontSize={14}
                        fontWeight={300}
                      >
                        No records found
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
};
export default Index;
