import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HeaderListing from "../../../components/headerListing";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { useAuth } from "../../../shared/provider/authprovider";
import Shopify from "../../../assets/images/listingList/SHOPIFY.svg";
import { ReactComponent as Visibility } from "../../../assets/images/icons/open-eye.svg";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Ellipse } from "../../../assets/images/icons/ellipse.svg";
import { ReactComponent as Overview } from "../../../assets/images/icons/overview.svg";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
import { ReactComponent as Reject } from "../../../assets/images/icons/reject.svg";

export const style = {
  card: {
    border: "1px solid #E0E0E0",
    p: 2.5,
    borderRadius: 0.5,
    position: "relative",
    img: {
      width: "100%",
      maxWidth: 100,
      mb: "10px",
    },
    mt: "10px",
  },
  cardBtn: {
    backgroundColor: "#EFEFEF",
    height: 80,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#68737D",
    borderRadius: "unset",
  },
};
const IntegrationOverview = () => {
  const { sourceDashData, getImageist } = useIntegrationsSAAS();
  const { setSelectedIndex, setIntegrationTabIndex } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const navigate = useNavigate();
  const [imgList, setImgList] = useState<any>([]);

  const handleNavigate = (id: string) => {
    navigate("/listing-integration");
  };

  const userId = localStorage.getItem("userId");
  const [sourceData, setSourceData] = useState<any>({
    count: 0,
    items: [],
  });
  const [destinationData, setDestinationData] = useState<any>({
    count: 0,
    items: [],
  });

  const initialPayload = {
    sourceDestinationType: 0,
    userId: userId,
    limit: 100,
    offSet: 0,
    prefix: "",
  };

  const initialPayload2 = {
    sourceDestinationType: 1,
    userId: userId,
    limit: 100,
    offSet: 0,
    prefix: "",
  };

  const loadSourceData = async () => {
    try {
      const res: any = await sourceDashData(initialPayload);
      if (res.status === 200) {
        setSourceData(res.data.result);
      }
    } catch (err) {}
  };

  const loadDestinationData = async () => {
    try {
      const res: any = await sourceDashData(initialPayload2);
      if (res.status === 200) {
        setDestinationData(res.data.result);
      }
    } catch (err) {}
  };

  const getImage = async () => {
    const res = await getImageist();
    if (res.status === 200) {
      setImgList(res.data.result);
    }
  };

  useEffect(() => {
    loadSourceData();
    loadDestinationData();
    setSelectedIndex(5);
    getImage();
  }, []);
  return (
    <>
      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{ flexGrow: 1, py: 2.5, px: isMobile ? 2 : 5 }}
      >
        <Box display="flex" justifyContent="space-between" mb={2.5}>
          <Stack>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"
              mb={1.5}
            >
              Resale Overview
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={16}
              fontWeight={300}
              color="#1A1918"
            >
              You’ve added Sources and Destinations to Segment. Add others to do
              even more with your data.
            </Typography>
          </Stack>
        </Box>

        <Box display="flex">
          <Stack flex={1}>
            <Stack mb={5}>
              <Typography
                variant="subtitle2"
                fontSize={16}
                fontWeight={500}
                color="#1A1918"
                mb="30px"
              >
                Source
              </Typography>
              {sourceData?.items?.map((ele: any) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={style.card}
                >
                  <Ellipse className={styles.ellipse} />
                  <img
                    src={`data:image/svg+xml;base64,${
                      imgList?.find(
                        (item: any) => item.accountId === ele.accountType
                      )?.image
                    }`}
                  />

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1.5}
                    alignItems="center"
                  >
                    {ele?.isActive ? <Accept /> : <Reject />}
                    <IconButton
                      aria-label="view"
                      sx={{ height: 34, width: 34 }}
                    >
                      <Visibility
                        onClick={() =>
                          navigate(
                            `/listing-configure-source-integration/${ele.name}`,
                            { state: { id: ele.id } }
                          )
                        }
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            {/* <Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={style.card}>
                                <Ellipse className={styles.ellipse} />
                                <img src={Shopify} />
                                <Stack direction='row' justifyContent='space-between' spacing={1.5} alignItems='center'>
                                    <Accept />
                                    <IconButton aria-label="view" sx={{ height: 34, width: 34 }}>
                                        <Visibility />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack> */}
            <Stack>
              <Button
                sx={style.cardBtn}
                onClick={() => handleNavigate("1")}
                startIcon={<AddRoundedIcon />}
              >
                New Source
              </Button>
            </Stack>
          </Stack>
          <Stack flex={1}>
            <Overview style={{ marginTop: "93px" }} />
          </Stack>
          <Stack flex={1}>
            <Stack>
              <Typography
                variant="subtitle2"
                fontSize={16}
                fontWeight={500}
                color="#1A1918"
                mb="30px"
              >
                Destination
              </Typography>
              {destinationData?.items?.map((ele: any) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={style.card}
                >
                  <Ellipse className={styles.ellipse} />
                  <img
                    src={`data:image/svg+xml;base64,${
                      imgList?.find(
                        (item: any) => item.accountId === ele.accountType
                      )?.image
                    }`}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1.5}
                    alignItems="center"
                  >
                    {ele?.isActive ? <Accept /> : <Reject />}
                    <IconButton
                      aria-label="view"
                      sx={{ height: 34, width: 34 }}
                    >
                      <Visibility
                        onClick={() =>
                          navigate(
                            `/listing-configure-destination-integration/${ele.name}`,
                            { state: { id: ele.id } }
                          )
                        }
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack mt={5}>
              <Button
                sx={style.cardBtn}
                onClick={() => handleNavigate("2")}
                startIcon={<AddRoundedIcon />}
              >
                New Destination
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default IntegrationOverview;
