import { useState } from "react"
import MoadalPreLanding from "./modalPreLanding"
import HeaderListing from "../../../components/headerListing";
import { Box, Typography } from "@mui/material";
import styles from "./index.module.scss";

const PostLoginScreen = () => {
    const [showModal, setShowModal] = useState(true)
    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    return (
        <>
            <HeaderListing />
            {/* <div>User Dashboard</div>
            <Paypal /> */}
            <Box
                className={styles.contentMain}
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", py: 3 }}
            >
                <Typography variant="h4" ml={2}>
                    User Dashboard
                </Typography>
                <Box sx={{ width: "100%" }}>
                    <MoadalPreLanding open={showModal} handleClose={handleClose} />
                </Box>
            </Box>
        </>
    )
}
export default PostLoginScreen