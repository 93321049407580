import { Bubble, Line } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import { CategoryScale, registerables } from "chart.js";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";

const TotalSalesChart = () => {
  Chart.register(CategoryScale);
  Chart.register(...registerables);
  const chartdata = {
    // config:config,
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],

    datasets: [
      {
        label: "Jun  13, 2022 - July 13, 2022",
        data: [10, 20, 15, 24, 30, 24],
        fill: false,
        backgroundColor: ['rgba(104, 115, 125, 1)'],
        borderColor: ["#68737D"],
        borderWidth: 2,
        tension: 0.4,
        borderDash: [5, 5], // This sets the line to be dashed
        borderRadius: "10px",
      },
      {
        label: " 1 June - 7 June",
        data: [20, 25, 18, 25, 20, 32],
        backgroundColor: ['rgba(70, 125, 253, 1)'],
        borderColor: ["#A3BEFF"],
        borderWidth: 2,
        fill: false,
        tension: 0.4,

      },
    ],
  };

  const options = {

    plugins: {

      legend: {
        display: true,
        labels: {
          pointStyle: "line", // Change to eg 'circle' or 'rect' to control legend independently of the plotted pointStyle
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "#000",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };

  return (

    <>
      {" "}
      <Line data={chartdata} options={options} />
    </>
  );
};
export default TotalSalesChart;
