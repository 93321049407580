import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useSKuFormAPI } from "../../../shared/api/useSkuFormSAAS";
import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import { style as styless } from "../../listingScreens/superAdminDashboard/index";

import SampleForm from "../../../assets/images/form/sampleForm.png";

export const style = {
  inputLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#1A1918",
  },
  saveCancelBtn: {
    width: "100%",
    px: 2,
    py: 1.5,
    borderRadius: 0.5,
    boxShadow: "none",
    fontSize: 14,
    fontWeight: 300,
    textTransform: "capitalize",
    height: 34,
  },
  blueVerticalDash: {
    height: 23,
    borderWidth: 3,
    mr: 2,
    borderColor: "#004EFF",
    my: 0,
    alignSelf: "unset",
  },
  pointer: {
    cursor: "pointer",
  },
  errorMsg: {
    color: "#d32f2f",
    fontSize: "10.5px",
    fontWeight: 300,
  },
};
const ImportExcel = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const { showImportModal, setShowImportModal, fetchData } = props;
  const { setOpen, setMessageText, setOpenError, setErrorMessageText } =
    useAuth();
  const [isError, setIsError] = useState<boolean>(false);

  const formikImportExcel: any = useFormik({
    initialValues: {
      upc: "",
    },
    onSubmit: () => submitData(),
  });
  const submitData = () => {
    handleFileUpload();
  };
  const { uploadExcel } = useSKuFormAPI();
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setIsError(false);
  };
  const handleFileUpload = async () => {
    if (!file) {
      setIsError(true);
      return;
    }
    try {
      setIsError(false);
      const formData = new FormData();
      formData.append("file", file);

      const response: any = await uploadExcel(formData);
      if (response.status === 200) {
        if (response.data.result) {
          setShowImportModal(false);
          fetchData();
          setOpen(true);
          setMessageText(response.data.message);
        } else {
          setOpenError(true);
          setErrorMessageText(response.data.message);
        }
      } else {
        setOpenError(true);
        setErrorMessageText(response.data.message);
      }
    } catch (error) { }
  };

  const downloadExcelFile = () => {
    const link = document.createElement('a');
    link.href = '/setupFormTemplate.xlsx'; // Path to your Excel file in the public folder
    link.download = 'setupFormTemplate.xlsx';
    link.click();
  };

  const [state, setState] = useState({
    bottom: false,
  });

  type Anchor = "bottom";

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setState({ ...state, [anchor]: open });
  };

  return (
    <>

      <Drawer
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={() => toggleDrawer("bottom", false)}
        sx={{
          border: "1px solid red",
          zIndex: 1301,
          ".MuiModal-backdrop": { left: 60 },
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: 936,
            py: 2.5,

          },
        }}
      >
        <>
          <img src={SampleForm} className="App-logo" alt="logo" />
          <Button
            variant="contained"
            sx={{
              ...styless.fiiledBtn,
              ...styless.outlineNewBtn,
              color: "#68737D",
              textDecoration: "underline",
              ml: isMobile ? 0 : 2,
              textWrap: "nowrap",
              px: isMobile ? 1 : 2,
            }}
            onClick={() => toggleDrawer("bottom", false)}
          >
            Cancel
          </Button>
        </>

      </Drawer>

      {showImportModal && (
        <Dialog
          open={showImportModal}
          onClose={() => {
            setIsError(false);
            setShowImportModal(false);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle
            sx={{
              m: 0,
              pt: 4,
              pb: 2.5,
              px: 4,
              fontSize: 20,
              fontWeight: 500,
              color: "#002C8F",
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            Import Excel
            <Stack flexDirection="row" mt={isMobile ? 1 : 0}>
              <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  textDecoration: "underline",
                  ml: isMobile ? 0 : 2,
                  textWrap: "nowrap",
                  px: isMobile ? 1 : 2,
                }}
                onClick={downloadExcelFile}
              >
                Download the template
              </Button>
              <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  textWrap: "nowrap",
                  px: isMobile ? 1 : 2,
                  ml: isMobile ? 1.5 : 2,
                }}
                onClick={() => toggleDrawer("bottom", true)}
              >
                Sample Form
              </Button>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={() => setShowImportModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ px: 4 }}>
            <FormikProvider value={formikImportExcel}>
              <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>
                Basic Identifiers:
              </Typography>
              <Grid container spacing={5} mb={2.5}>
                <Grid item md={6}>
                  <InputLabel sx={style.inputLabel}>Select File</InputLabel>
                  <Field name="title" sx={{ cursor: "pointer" }}>
                    {({ field, meta }: any) => (
                      <TextField
                        {...field}
                        type="file"
                        className={styles.fileTextfield}
                        accept=".xlsx"
                        onChange={handleFileChange}
                        id="standard-required"
                        variant="standard"
                        fullWidth
                        {...{ error: meta.touched && meta.error }}
                        helperText={meta.touched && meta.error && meta.error}
                      />
                    )}
                  </Field>
                  {isError ? (
                    <Typography variant="caption" sx={style.errorMsg}>
                      Required
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </FormikProvider>
          </DialogContent>
          <DialogActions sx={{ pt: 2.5, p: isMobile ? 2 : 4 }}>
            <Stack gap={2.5} direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{
                  ...style.saveCancelBtn,
                  minWidth: 136,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                }}
                onClick={() => formikImportExcel.handleSubmit()}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                sx={{
                  ...style.saveCancelBtn,
                  minWidth: 136,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
                onClick={() => {
                  setIsError(false);
                  setShowImportModal(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default ImportExcel;
