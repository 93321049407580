import { Link, Stack, Typography } from "@mui/material";
import moment from "moment";
import styles from "./index.module.scss";
import { ReactComponent as Accept } from "../../../../assets/images/icons/accept.svg";
import { ReactComponent as UnshippIcon } from "../../../../assets/images/icons/unshippIcon.svg";
import { ReactComponent as False } from "../../../../assets/images/icons/false.svg";

export const style = {
    tableData: {
        fontSize: 14,
        fontWeight: 300,
    }
}

const Table = (props: any) => {
    const { data, fetchProductDetails, addPanels, page } = props;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const addIcons = (item: any) =>{

        if(item?.shippingStatus === "Shipped"){
            return (<Stack
                direction="row"
                alignItems="center"
                gap={1}>
                <Accept/>
                <Typography>Shipped</Typography>
                </Stack>)
        }else if (item?.shippingStatus === "Unshipped"){
            return (<Stack
                direction="row"
                alignItems="center"
                gap={1}>
                <UnshippIcon />
                <Typography>Unshipped</Typography>
                 </Stack>)
        }else if(item?.shippingStatus === "Canceled"){
            return (<Stack
                direction="row"
                alignItems="center"
                gap={1}>
                <False />
                <Typography>Cancelled</Typography>
                 </Stack>)
        }else{
            return (<Stack
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Typography></Typography>
                 </Stack>)
        }
      }

    return (<>
        <div className={`${styles.heightG} ${styles.orderTable}`}>
            <table className={styles.tableH}>
                <thead className={`${styles.thead} rounded`}>
                    <tr>
                        <td></td>
                        <td>Order</td>
                        <td>Date</td>
                        <td>Customer</td>
                        <td>Total</td>
                        <td>Payment Status</td>
                        <td>Items</td>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {data && data.length ? data?.map((ele: any, index: number) => (
                        <tr style={{ cursor: 'pointer' }}>
                            <td>{(page ) + (index + 1)}</td>
                            {/* <td><span onClick={(e) => e.stopPropagation()}><Link variant="body2" color='#0038BA' underline="hover" sx={{ ...style.tableData, cursor: 'pointer', width: 'max-content', '&:hover': { color: '#004EFF' } }} onClick={() => addPanels(ele)}>{ele?.orderId}</Link></span></td> */}
                            <td><span onClick={(e) => e.stopPropagation()}>{ele?.orderId}</span></td>
                           
                            <td><Typography variant="body2" sx={style.tableData}>{ele?.createdDate ? moment(ele?.createdDate).format("MM/DD/YYYY") : ""}</Typography></td>
                            <td><Typography variant="body2" sx={style.tableData}>{ele?.customer}</Typography></td>
                            <td><Typography variant="body2" sx={style.tableData}>{formatter.format(ele?.totalPrice)}</Typography></td>
                            <td>{addIcons(ele)}

                            {/* {value?.shippingStatus == Unshipped ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Shipped />
                              <Typography>Shipped</Typography>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Unshipped />
                              <Typography>Unshipped</Typography>
                            </Stack>
                          )} */}
                            </td>
                            <td>{ele?.itemCount}</td>
                        </tr>
                    )) :
                        <tr className={styles.noRecordRow}>
                            <td colSpan={8}>
                                <Typography variant="body2" textAlign='center' fontSize={14} fontWeight={300}>No records found</Typography>
                            </td>
                        </tr>}
                </tbody>
            </table>
        </div >
    </>)
}
export default Table;