import { Box, Button, Stack } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import './imageCarousel.scss';
import { ReactComponent as Arrowright } from '../../../assets/images/icons/arrow-right.svg';

interface IProps {
    images?: any;
}

const Item = ({ item }: any) => {
    return (
        <Box
            sx={{
                // overflow: "hidden",
                display: 'flex',
                justifyContent: 'end'
            }}
        >
            <img
                style={{ width: '100%', maxWidth: 362, objectFit: "cover", border: '1px solid #DFDBDB' }}
                src={`${item}`}
                alt="First slide"
                height="362"
            />
        </Box>
    );
};
const ImageCarousel = (props: IProps) => {
    const { images } = props;

    const anArrayOfNumbers =
        images &&
        images?.map((item: any) => (
            <Stack>
                <img
                    style={{ width: "100%", maxWidth: 44, border: '1px solid #DFDBDB', }}
                    src={item}
                    alt={item.name}
                    title={item.name}
                    height="44"
                />
            </Stack>
        ));
    return (
        <>
            <Carousel
                className="carousel"
                NextIcon={<Arrowright className="nextIcon" />
                }
                PrevIcon={<Arrowright className="prevIcon" />}
                navButtonsAlwaysVisible
                autoPlay={false}
                IndicatorIcon={anArrayOfNumbers}
                indicatorIconButtonProps={{
                    style: {
                        cursor: `pointer`,
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        // overflow: "hidden",
                    },
                }}
                sx={{ mb: 2.5 }}
                indicatorContainerProps={{
                    style: {
                        marginTop: '0px',
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'max-content',
                        zIndex: 1,
                        transform: 'translateY(-50%)',
                    }

                }}

                fullHeightHover={false}
                navButtonsProps={{
                    style: {
                        backgroundColor: 'cornflowerblue',
                        borderRadius: 0
                    }
                }}
                navButtonsWrapperProps={{
                    className: 'navButtonsWrapper',
                    style: {
                        bottom: 'unset',
                        height: 'unset',
                    },
                }}
            >
                {images?.map((image: any, i: number) => (
                    <Item key={i} item={image}></Item>
                ))}
            </Carousel>
        </>
    );
};

export default ImageCarousel;
