import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export interface IUseAuth {
  token: any | null;
}
export const useOrderDashboardAPI = () => {
  const { apinew } = useAuth();

  const orderDashboardData = async (payload: any) => {
    try {
      const res = await apinew.post( `/api/OrderPayout/Reports/Orders`, payload);
        return res;
    } catch (error: any) {
    }
  };

  const exportOrdersAPI = async (payload: any) => {
    try {
      const res = await apinew.post(`/api/OrderPayout/Orders/Export`, payload, { responseType: "blob" });
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
    }
  };

  return {
    orderDashboardData,
    exportOrdersAPI
  } as const;
};
