import { useState } from "react";
import { useAuth } from "../provider/authprovider";
import { useClientAPI } from "./userManagementSAAS";

export const useSubScriptionAPI = () => {
  const {
    apinew,
    handleError,
    setMessageText,
    setOpen,
    open,
    messageText,
  } = useAuth();
  const { handleErrorSaas, roles } = useClientAPI();
  const [subscriptionList, setSubscriptionList] = useState<any>(null);
  const [subscriptionCount, setSubscriptionCount] = useState<any>();
  const [subscriptionByIdNew, setSubscriptionByIdNew] = useState<any>([]);
  const [orderIdFromBackend, setOrderIdFromBackend] = useState<any>(null);
  const [subscriptionIdPass, setSubscriptionIdPass] = useState<any>(null);
  const [flag, setFlag] = useState<any>(null);
  const getSubscriptionList = async (
    payload:any
  ) => {
    try {
      const newData = await apinew.post(
        `/api/Subscription/List`,payload
      );
      setSubscriptionList(newData?.data?.result);
      if (localStorage.getItem("role") == "Customer") {
        localStorage.setItem("subcr", newData?.data?.result?.items[0].id);
      }
      setSubscriptionCount(newData?.data?.count);
      return newData;
    } catch (error: any) {
      handleErrorSaas(error);
    }
  };

  const getAllCompanyList = async () => {
    try {
      const newData = await apinew.get(`/api/User/List/All`);
      return newData;
    } catch (error: any) {
      handleErrorSaas(error);
    }
  };

  const getSubscriptionById = async (id: any) => {
    try {
      const newData = await apinew.get(`/api/Subscription/${id}`);
      setSubscriptionByIdNew(newData?.data?.result);
      return newData;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  // /api/Subscription
  const addNewSubscriptionPlan = async (details: any) => {
    try {
      const result = await apinew.post(`/api/Subscription`, details);
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const putSubscriptionPlan = async (id: any, details: any) => {
    try {
      const result = await apinew.put(`/api/Subscription/${id}`, details);
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  // /api/Subscription?id=e39c9053-0530-4331-b7bf-c48f2399cb1a
  const deleteSubscription = async (id: any) => {
    try {
      const result = await apinew.delete(`/api/Subscription?id=${id}`);
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  ////////////paypal payment
  const CreateOrderForSubscription = async (subscriptionId: any) => {
    try {
      const result = await apinew.post(
        `/api/Order/CreateOrderForSubscription?SubscriptionId=${subscriptionId}`
      );
      localStorage.setItem("order", result?.data?.id);
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const paypalCapturePayment = async (orderId: any, subscriptionId: any) => {
    try {
      const result = await apinew.post(
        `/api/Order/CapturePayment?orderId=${orderId}&SubscriptionId=${subscriptionId}`
      );
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const paypalCancelPayment = async (orderId: any) => {
    try {
      const result = await apinew.post(
        `/api/Order/CancelPayment?orderId=${orderId}`
      );
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const paypalErrorPayment = async (orderId: any) => {
    try {
      const result = await apinew.post(
        `/api/Order/ErrorPayment?orderId=${orderId}`
      );
      return result;
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };

  const ExportInvoiceAPI = async (id: any) => {
    try {
      const res = await apinew.get(`/api/Order/Download/Invoice${id}`, { responseType: "blob" });
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };

  ///invoice - transaction
  const getUserInvoiceDetails = async (
    prefix: any,
    offset: any,
    limit: any,
    userId: any
  ) => {
    try {
      const newData = await apinew.get(
        `/api/Order/Transactions/List?prefix=${prefix}&offset=${offset}&limit=${limit}&sortBy=createdTime&orderBy=DESC&userId=${userId}`
      );
      console.log(newData?.data?.result?.items, "9");
      return newData?.data?.result?.items;
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  return {
    getSubscriptionList,
    subscriptionList,
    subscriptionCount,
    getSubscriptionById,
    subscriptionByIdNew,
    paypalCapturePayment,
    paypalCancelPayment,
    paypalErrorPayment,
    orderIdFromBackend,
    CreateOrderForSubscription,
    subscriptionIdPass,
    addNewSubscriptionPlan,
    putSubscriptionPlan,
    deleteSubscription,
    getUserInvoiceDetails,
    setFlag,
    flag,
    getAllCompanyList,
    ExportInvoiceAPI
  } as const;
};
