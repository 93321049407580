import {
  Box,
  Typography,
  Tabs,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// import { ReactComponent as ArrowRight } from "../../../../assets/images/icons/arrow-right.svg";
// import { ReactComponent as ArrowLeft } from "../../../../assets/images/icons/arrow-left.svg";

export const style = {
  orderSummaryBox: {
    mt: 1.5,
    ".MuiStack-root": {
      flexDirection: "row",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      px: 1.5,
      py: 2,
      borderRight: "1px solid #E0E0E0",
      width: "100%",
      minWidth: 185,
      "&:last-child": {
        borderRight: "unset",
      },
    },
  },
};

const Index = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const { headerNames } = props;

  return (
    <>
      <Box>
        <Typography
          variant="subtitle2"
          fontSize={14}
          fontWeight={500}
          color="#68737D"
        >
          Order Summary
        </Typography>
        <Box display="flex" sx={style.orderSummaryBox}>
          <Box
            sx={{
              maxWidth: "100%",
              flex: 1,
            }}
          >
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {headerNames.map((ele: any) => (
                <Stack>
                  <Typography
                    variant="body1"
                    fontSize={ele.fontSize}
                    fontWeight={500}
                    color={`${ele.fontColor ? ele.fontColor : "#68737D"}`}
                  >
                    {ele.name}
                  </Typography>

                  <Typography
                    variant="h5"
                    fontSize={20}
                    fontWeight={500}
                    color={`${ele.fontColor ? ele.fontColor : "#68737D"}`}
                    sx={{
                      borderRadius: 0.5,
                      background: `${ele.color}`,
                      width: "max-width",
                      padding: "3px 10px",
                      lineHeight: "normal",
                    }}
                  >
                    {ele.data}
                  </Typography>
                </Stack>
              ))}
            </Tabs>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Index;
