import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputLabel, Stack, TextField, Typography } from "@mui/material"
import { Field, FormikProvider, useFormik } from "formik"
import CloseIcon from "@mui/icons-material/Close";
import { useSKuFormAPI } from "../../../shared/api/useSkuFormSAAS";
export const style = {
    inputLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: '#1A1918',
    },
    saveCancelBtn: {
        width: "100%",
        px: 2,
        py: 1.5,
        borderRadius: 0.5,
        boxShadow: "none",
        fontSize: 14,
        fontWeight: 300,
        textTransform: "capitalize",
        height: 34,
    }
}
const ScanUPC = (props: any) => {
    const { setScanUPCFormModal, scanUPCFormModal } = props;
    const {scanUPCForDetails} = useSKuFormAPI()
    const formikScanUPCDetails: any = useFormik({
        initialValues: {
            upc: "",
        },
        onSubmit: () => submitData(),
    })
    const submitData = async() => {
        console.log("Values", formikScanUPCDetails.values)
        const result = await scanUPCForDetails( formikScanUPCDetails.values.upc)
        console.log("Result55" , result)
    }
    return (
        <>
            <Dialog
                open={scanUPCFormModal}
                onClose={() => setScanUPCFormModal(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle
                    sx={{ m: 0, pt: 4, pb: 2.5, px: 4, fontSize: 20, fontWeight: 500, color: '#002C8F' }}
                >
                    Product Details Form
                    <IconButton
                        aria-label="close"
                        onClick={() => setScanUPCFormModal(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ px: 4 }}>

                    <FormikProvider value={formikScanUPCDetails}>
                        <Typography variant="h6" fontSize={20} fontWeight={500} mb={5}>Basic Identifiers:</Typography>
                        <Grid container spacing={5} mb={2.5}>
                            <Grid item xs={6}>
                                <InputLabel sx={style.inputLabel}>
                                    Scan UPC
                                </InputLabel>
                                <Field name="upc" >
                                    {({ field, meta }: any) => (
                                        <TextField
                                            {...field}
                                            type="string"
                                            id="standard-required"
                                            variant="standard"
                                            placeholder="UPC"
                                            fullWidth
                                            {...{ error: meta.touched && meta.error }}
                                            helperText={meta.touched && meta.error && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </FormikProvider>
                </DialogContent>
                <DialogActions sx={{ pt: 2.5, p: 4 }}>
                    <Stack gap={2.5} direction="row" justifyContent="end">
                        <Button
                            variant="contained"
                            sx={{
                                ...style.saveCancelBtn,
                                minWidth: 136,
                                background:
                                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                            }}
                            onClick={() => formikScanUPCDetails.handleSubmit()}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                ...style.saveCancelBtn,
                                minWidth: 136,
                                border: "1px solid rgba(104, 115, 125, 0.48)",
                                color: "#68737D",
                            }}
                            onClick={() => setScanUPCFormModal(false)}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

        </>
    )
}
export default ScanUPC