import {
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
// import SHOPIFY from "../../../assets/images/listingList/shopify.svg";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import styles from "./index.module.scss";

const SourcePanelBoard = () => {
  const { getIntegrationsAccountsList, integrationsAccountsList, sourceDashData, getImageist } = useIntegrationsSAAS();
  const navigate = useNavigate();
  const [imgList, setImgList] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  const [tableData, setTableData] = useState<any>({
    count: 0,
    items: []
  })

  const loadInitialData = async () => {
    const res = await getImageist();
    if (res.status === 200) {
      setImgList(res.data.result);
    }
  };

  const loadAccountList = async () => {
    await getIntegrationsAccountsList();
  }

  const loadIData = async () => {
    try {
      const initialPayload = {
        sourceDestinationType: 0,
        userId: userId,
        limit: 100,
        offSet: 0,
        prefix: ""
      }
      const res: any = await sourceDashData(initialPayload);
      if (res.status === 200) {
        setTableData(res.data.result)
      }
    } catch (err) {
    }
  }

  useEffect(() => {
    loadIData();
    loadInitialData();
    loadAccountList();
  }, []);

  const handleNavigate = (item: any) => {
    if (tableData?.items?.find((ele: any) => item.id === ele.accountType)) {
      const record: any = tableData?.items?.find((ele: any) => item.id === ele.accountType);
      navigate(`/listing-configure-source-integration/${item.name}`, { state: { id: record.id } })
    } else {
      navigate(`/listing-integration-steps/${item?.name}`)
    }
  }

  return (
    <Box>
      <Stack mb={"30px"}>
        <Typography
          variant="h4"
          fontSize={18}
          fontWeight={500}
          color="#000"
          mb={1.5}
        >
          Please select your first data source.
        </Typography>
        <Typography
          variant="subtitle2"
          fontSize={14}
          fontWeight={300}
          color="#1A1918"
        >
          Select your first connector.
          <span style={{ fontWeight: 500 }}>Required</span>
        </Typography>
      </Stack>
      {/* <Grid item xs={2}>
          <Box>
            <Paper elevation={0}>
              <Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    fontSize={18}
                    fontWeight={500}
                    color="#1A1918"
                  >
                    Categories
                  </Typography>
                  <IconButton aria-label="toggle">
                    <ArrowNext />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 2, opacity: 1 }} />
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 1,
                    minHeight: 32,
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primary="All"
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    10
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 1,
                    minHeight: 32,
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primary="Orders"
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    10
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 1,
                    minHeight: 32,
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primary="Inventory"
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    10
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 1,
                    minHeight: 32,
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primary="Shipment "
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    10
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 1,
                    minHeight: 32,
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primary="Products"
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                  <ListItemText
                    sx={{ flexGrow: 0 }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    10
                  </ListItemText>
                </ListItemButton>
              </Box>
            </Paper>
          </Box>
        </Grid> */}
      {/* <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ ml: 2, opacity: 1 }}
          /> */}
      <Stack>
        <Grid container spacing={2.5}>
          {integrationsAccountsList?.map((item: any) => {
            if (item.sourceDestinationType === 0) {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      borderRadius: 0.5,
                      border: "1px solid #E0E0E0",
                      background: "#FFF",
                      boxShadow: "unset",
                      height: "114px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      "&:hover": {
                        boxShadow: "0px 4px 11px 5px rgba(0, 0, 0, 0.13)",
                      },
                    }}
                    onClick={() => handleNavigate(item)}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <img
                        // src={require("../../../assets/images/listingList/" +
                        //   item?.name +
                        //   ".svg")}
                        src={`data:image/svg+xml;base64,${imgList?.find((ele: any) => ele.accountId === item.id)?.image}`}
                        className={styles.cardImage}
                      />
                      <IconButton>
                        <Plus />
                      </IconButton>
                    </Stack>
                    <Typography
                      variant="body2"
                      color="#68737D"
                      fontSize={14}
                      fontWeight={500}
                    >
                      The global commerce platform
                    </Typography>
                  </Card>
                </Grid>
              );
            }

          })}
        </Grid>
      </Stack>
    </Box>
  );
};
export default SourcePanelBoard;
