import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ellipse from "../../../assets/images/ellipse.jpg";
import { ReactComponent as ArrowTip } from "../../../assets/images/icons/arrow-tip.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/images/icons/eye-slash.svg";
import { ReactComponent as HelpIcon } from "../../../assets/images/icons/help-icon.svg";
import { ReactComponent as Visibility } from "../../../assets/images/icons/open-eye.svg";
import { ReactComponent as SuccessLogo } from "../../../assets/images/icons/success-logo.svg";
import logo from "../../../assets/images/logo.svg";
import styles from "./index.module.scss";
import { Group } from "antd/lib/avatar";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import SuccessSnackbar from "../../../components/SuccessSnackbar";
import RegistrationSuccess from "./RegistrationSuccess";
import { useAuth } from "../../../shared/provider/authprovider";
export const style = {




  textField: {
    "& .MuiInputLabel-root": {
      fontSize: 16,

      "&.Mui-focused": {
        color: "#0038ba",
        transform: 'translate(12px, 4px) scale(0.75)',
      },
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "unset",
      "&.Mui-focused": {
        border: "1px solid #0038ba",
        backgroundColor: "unset",
        borderRadius: 1,
        "&:before": {
          borderBottom: "unset",
        },
      },
      "&:after": {
        borderBottom: "unset",
      },
      "&:hover": {
        backgroundColor: "unset",
      },
      "& .MuiFilledInput-input": {
        paddingTop: "20px",
        paddingBottom: "0px",
      },
    },
  },
  helpBox: {
    position: "absolute",
    zIndex: 1,
    left: "24px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  helpText: {
    width: "202px",
    whiteSpace: "normal",
    background:
      "linear-gradient(96.2deg, #0038BA -7.06%, #0038BA 22.57%, #4F18FF 89.3%, #4F18FF 112.37%)",
    borderRadius: 1,
    padding: 2,
    color: "#fff",
    marginLeft: "-4px",
  },
  snackbar: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "#fff",
      borderRadius: 0.5,
      px: 2,
      py: 0.8,
      "& .MuiIconButton-root": {
        color: "#000",
      },
    },
  },
};
const RegistrationScreen = () => {
  const navigate = useNavigate();
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("Required")
      .nullable()
      .min(3)
      .max(20)
      .matches(/^[A-Za-z ]*$/, "Please enter valid firstname"),
    lastname: Yup.string()
      .required("Required")
      .nullable()
      .min(3)
      .max(20)
      .matches(/^[A-Za-z ]*$/, "Please enter valid lastname"),
    email: Yup.string()
      .required("Required")
      .email("Please enter valid email")
      .nullable(),
    mobileNumber: Yup.string()
      .required("Required")
      .matches(
        /^(\+?\d{10})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        "Phone Number is not valid"
      )
      .nullable(),
    company: Yup.string().required("Required").nullable(),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
  });
  const { postNewClient, registrationSuccess } =
    useClientAPI();
  const { messageText, setOpen, open, shortMessage } = useAuth()

  const formikFormReg: any = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobileNumber: "",
      role: "Customer",
      company: "",
      password: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async () => {
      await postNewClient(formikFormReg?.values);
    },
  });
  function camelize(str: any) {
    return str.replace(
      /^(\w)(.+)/,
      (match: any, p1: any, p2: any) => p1.toUpperCase() + p2.toLowerCase()
    );
  }
  const [showPassword, setShowPassword] = useState<any>(false);
  //   const [error, setError] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show: any) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };


  const handleChange = (e: any) => {
    const input = e.target.value;
    const formattedNumber: any = formatPhoneNumber(input);
    formikFormReg.setFieldValue("mobileNumber", formattedNumber);
  };

  const formatPhoneNumber = (input: any) => {
    // Remove all non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Format the phone number as (123) 456-7890
    const formattedNumber = digitsOnly.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const SlideTransition = (props: any) => {
    return <Slide {...props} direction="left" />;
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.formWrapper}
      >
        {registrationSuccess ?
          <RegistrationSuccess /> : <Stack
            justifyContent="center"
            sx={{ background: "#fff" }}
            className={styles.formContainer}
          >
            <img
              src={ellipse}
              alt="ellipse"
              className={`${styles.responsiveImg} ${styles.formBgImg}`}
            />
            <Stack sx={{ px: 6, py: 4, position: "relative" }}>
              <Stack flexDirection="row">
                <img
                  src={logo}
                  alt="logo"
                  className={styles.responsiveImg}
                  style={{ maxWidth: "100px" }}
                />
              </Stack>
              <Stack mt={4} mb={2.5}>
                <Typography variant="h4" fontSize={30} fontWeight={600} color="#000">
                  Create account
                </Typography>
                <Typography variant="body2" fontSize={20} fontWeight={300} color="#000">
                  Get started with your account
                </Typography>
              </Stack>
              <FormikProvider value={formikFormReg}>
                <Grid container rowSpacing={1} columnSpacing={5}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="firstname">
                      {({ field, meta }: any) => (
                        <TextField
                          id="filled-basic"
                          variant="filled"
                          required
                          fullWidth
                          {...field}
                          label="First Name"
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="lastname">
                      {({ field, meta }: any) => (
                        <TextField
                          required
                          fullWidth
                          {...field}
                          id="filled-basic"
                          variant="filled"
                          label="Last Name"
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email">
                      {({ field, meta }: any) => (
                        <TextField
                          required
                          fullWidth
                          {...field}
                          id="filled-basic"
                          variant="filled"
                          label="Email"
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="mobileNumber">
                      {({ field, meta }: any) => (
                        <TextField
                          required
                          fullWidth
                          {...field}
                          id="filled-basic"
                          variant="filled"
                          onChange={handleChange}
                          label="Contact No."
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="company">
                      {({ field, meta }: any) => (
                        <TextField
                          required
                          fullWidth
                          {...field}
                          id="filled-basic"
                          variant="filled"
                          label="Company"
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="password">
                      {({ field, meta }: any) => (
                        <TextField
                          {...field}
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          id="filled-basic"
                          variant="filled"
                          autoComplete="off"
                          defaultValue=""
                          required
                          fullWidth
                          sx={style.textField}
                          {...{ error: meta.touched && meta.error }}
                          helperText={meta.touched && meta.error && meta.error}
                          InputProps={{
                            autoComplete: "new-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  sx={{
                                    p: 0,
                                    mr: 1,
                                    "&:hover": { backgroundColor: "unset" },
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                                <Box
                                  sx={{ position: "relative" }}
                                  className={styles.helptextContainer}
                                >
                                  <IconButton sx={{ p: 0 }}>
                                    <HelpIcon className={styles.helpIcon} />
                                  </IconButton>
                                  <Stack
                                    alignItems="center"
                                    flexDirection="row"
                                    className={styles.helpBox}
                                    sx={{ ...style.helpBox }}
                                  >
                                    <ArrowTip />
                                    <Typography
                                      variant="body2"
                                      sx={{ ...style.helpText }}
                                    >
                                      Good passwords are hard to guess. Use
                                      uncommon words or inside jokes, non-standard
                                      uppercasing, lowercase, creative spelling,
                                      and non-obvious numbers and symbols.
                                    </Typography>
                                  </Stack>
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  className={styles.filledBtn}
                  onClick={async () => {
                    await formikFormReg.setFieldValue(
                      "firstname",
                      camelize(formikFormReg?.values?.firstname)
                    );
                    await formikFormReg.setFieldValue(
                      "lastname",
                      camelize(formikFormReg?.values?.lastname)
                    );
                    await formikFormReg.handleSubmit();
                  }}
                  sx={{
                    mt: 5,
                    mb: 2.5,
                    boxShadow: "none",
                  }}
                >
                  Agree and Sign Up
                </Button>
                <Typography variant="body2" mb={1.5} fontSize={14} fontWeight={500}>
                  Already have an account?{" "}
                  <Link to="/listing-login" className={styles.bluelinks}>
                    Login
                  </Link>
                </Typography>
                <Typography variant="body2" fontSize={14} fontWeight={500}>
                  By signing in, you agree to our{" "}
                  <Link to="" className={styles.bluelinks}>
                    Terms & Privacy Policy.
                  </Link>
                </Typography>
              </FormikProvider>
            </Stack>
          </Stack>
        }

      </Box>
    </>
  );
};
export default RegistrationScreen;
