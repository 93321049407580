import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paypal from "../payPalIntegration/PayPalIntegration";
import React from "react";
import DashedLine from "../../../../src/assets/images/dashed-line.svg";
import { ReactComponent as CheckIcon } from "../../../assets/images/icons/check.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export const style = {
  listContainer: {
    ".MuiList-root": {
      backgroundColor: "#FAFBFC",
      ".MuiListItem-root": {
        mb: 1,
        svg: {
          mr: 1,
          width: "100%",
          maxWidth: "16px",
        },
        ".MuiListItemText-root": {
          mt: 0,
          mb: 1.5,
          "&:last-child": {
            mb: 0,
          },
        },
      },
    },
  },
  divider: {
    border: "1px solid #E0E0E0",
    opacity: 1,
    borderBottomWidth: 0,
  },
};
const SuBscriptionPaypal = (props: any) => {
  const isMobile = useMediaQuery("(max-width:576px)");
  const isTablet = useMediaQuery("(max-width:992px)");
  return (
    <Box sx={{ px: isMobile ? 0 : 5.5 }}>
      <Typography
        variant="h6"
        fontWeight={500}
        fontSize={24}
        color="#424EB5"
        textAlign="center"
        mb={5}
      >
        Start your subscription
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box>
            <Box
              sx={{
                border: "1px solid #0038BA",
                borderRadius: 0.5,
                p: 2.5,
                mb: 2,
                background: "#FAFBFC",
              }}
            >
              <Stack mb={3}>
                <Typography
                  variant="h6"
                  fontSize={18}
                  fontWeight={600}
                  color="#1E1F21"
                  mb={2.5}
                >
                  Solutions for B2B
                </Typography>
                <Typography
                  sx={{
                    background: "#C8D6F8",
                    padding: "4px 8px",
                    borderRadius: 0.5,
                    width: "100%",
                    maxWidth: "150px",
                    textAlign: "center",
                  }}
                >
                  {props.amount} per month
                </Typography>
              </Stack>
              <Box sx={style.listContainer}>
                <Typography variant="body1" color="#1A1918">
                  Plan Details
                </Typography>
                <List
                  sx={{ width: "100%", bgcolor: "background.paper", flex: 1 }}
                >
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Channel Management
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Receiving Manager
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Resale Connect
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Data Enrichment
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Dashboard Reporting
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <CheckIcon fontSize="small" />
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontSize: 14,
                            fontWeight: 300,
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Tier 1 Support
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Divider sx={{ ...style.divider, mt: 1, mb: 2 }} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">Total</Typography>
                  <Typography variant="h6" fontSize={18}>
                    {props.amount} USD
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Paypal />
        </Grid>
      </Grid>
      <img src={DashedLine} style={{ width: "100%" }} />
      <Typography
        variant="subtitle1"
        fontWeight={300}
        fontSize={16}
        sx={{ mt: 0.5 }}
      >
        You don’t have any active subscription.
        <Link
          sx={{
            fontWeight: 500,
            color: "#0038BA",
            textDecoration: "none",
            ml: 0.4,
          }}
        >
          Pay now to get started
        </Link>
      </Typography>
    </Box>
  );
};
export default SuBscriptionPaypal;
