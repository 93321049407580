import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { style } from "../../listingScreens/superAdminDashboard/index";
import CloseIcon from "@mui/icons-material/Close";
import PaymentSuccessful from "../../../assets/images/payment-successful-card.jpg";
import PaymentFailed from '../../../assets/images/payment-failed-card.jpg'
const PaymentSuccessPopup = (props: any) => {

  return (
    <Dialog
      open={props.paymentSuccessShow}
      onClose={() => props.setPaymentSuccessShow(false)}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ ...style.dialogContainer, ".MuiPaper-root": { maxWidth: "525px" } }}
      onBackdropClick={() => window.location.reload()}
    >
      <DialogTitle sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}>
        <IconButton
          aria-label="close"
          onClick={async () => {
            props.setPaymentSuccessShow(false);
            window.location.reload();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* ) : null} */}
      </DialogTitle>

      <DialogContent sx={{ p: 2.5 }}>
        {props.respMessage == "Payment Successful" ?
          <Typography
            variant="h6"
            fontSize={20}
            fontWeight={600}
            sx={{ color: "#469C61", textAlign: "center", mb: 3 }}
          >
            Payment successful{" "}
          </Typography>
          :
          <Typography
            variant="h6"
            fontSize={20}
            fontWeight={600}
            sx={{ color: "#FF4D4D", textAlign: "center", mb: 3 }}
          >
            Sorry, Payment Failed{" "}
          </Typography>
        }


        <Box textAlign="center" mb={2}>
          <img

            src={props.respMessage == "Payment Successful" ? PaymentSuccessful : PaymentFailed}
            style={{ width: "100%", maxWidth: "230px" }}
          />
        </Box>
        <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="end"
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                Mobile No.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                {" "}
                {localStorage.getItem("mobile")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                {localStorage.getItem("email")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="body1" fontSize={18} fontWeight={500}>
                Amount Paid
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body1" fontSize={18} fontWeight={500}>
                {props?.amount}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                Transaction Id
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="body1" fontSize={18} fontWeight={300}>
                {props?.orderId}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PaymentSuccessPopup;
