import {
    Box,
    Chip,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import ConnectorImg from "../../../assets/images/connector-img.svg";
import { ReactComponent as Link } from "../../../assets/images/icons/link.svg";
import { ReactComponent as CloseChipWhite } from "../../../assets/images/icons/tag-button-white.svg";
import { ReactComponent as CloseChip } from "../../../assets/images/icons/tag-button.svg";
import shopifyIntegration from "../../../assets/images/shopify-integration.svg";
import CustomBreadcrumbs from "../../../components/customBreadcrumbs";
import HeaderListing from "../../../components/headerListing";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import styles from "./index.module.scss";

export const style = {
    switch: {
        width: 34,
        height: 14,
        padding: "6px",
        overflow: "visible",
        ".MuiButtonBase-root": {
            "&.MuiSwitch-switchBase": {
                top: "-4px",
                color: "#68737D",
                "&.Mui-checked": {
                    color: "#4F18FF",
                },

                "&.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#A0A7AD",
                    opacity: 1,
                },
                "&:hover": {
                    backgroundColor: "unset",
                },
            },
        },
        ".MuiSwitch-track": {
            backgroundColor: "#A0A7AD",
            opacity: 1,
        },
        ".MuiSwitch-thumb": {
            width: 14,
            height: 14,
            boxShadow: "unset",
        },
    },
    switchBox: {
        borderRadius: 0.5,
        border: "1px solid #E0E0E0",
        px: 1.5,
        height: 40,
        ".MuiTypography-root": {
            fontSize: 14,
            fontWeight: 500,
            color: "#000",
        },
    },
    greyBox: {
        borderRadius: 0.5,
        backgroundColor: "#EFEFEF",
        p: 2.5,
        ".MuiInputLabel-root": {
            fontSize: 14,
            fontWeight: 500,
            color: "#1A1918",
            mb: 1,
        },
        ".MuiInput-root": {
            p: "10px",
            "&:before": {
                borderBottom: "2px solid #E0E0E0",
            },
            "&::placeholder": {
                color: "red",
            },
            '&.Mui-disabled:before': {
                borderBottomStyle: 'solid',
            }
        },
    },
    saveCancelBtn: {
        width: "100%",

        px: 2,
        py: 1.5,
        borderRadius: 0.5,
        boxShadow: "none",
        fontSize: 14,
        fontWeight: 300,
        textTransform: "capitalize",
        height: 34,
    },
    chipBox: {
        height: 30,
        border: "1px solid #B2B7BC",
        color: "#68737D",
    },
};

const Index = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const isDesktop = useMediaQuery(theme.breakpoints.up(1280));
    const isTablet = !isMobile && !isDesktop;
    const location = useLocation();
    const navigate = useNavigate();
    const { name } = useParams();
    const { getIndividualData } = useIntegrationsSAAS();
    const [data, setData] = useState<any>(null)

    const getData = async () => {
        try {
            const res: any = await getIndividualData(location?.state?.id);
            if (res.status === 200) {
                setData(res.data?.result)
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        getData();
    }, [])

    // useEffect(() => {
    //     if (location?.state?.isFirstTime) {
    //         setIsFirstTime(location?.state?.isFirstTime)
    //     }
    // }, [location?.state?.isFirstTime])

    return (
        <>
            <HeaderListing />
            <Box
                className={styles.contentMain}
                component="main"
                sx={{
                    flexGrow: 1,
                    backgroundColor: "#fff",
                }}
            >
                <Stack
                    px={1.5}
                    py={2}
                    justifyContent="center"
                    sx={{
                        backgroundColor: "#FAFBFC",
                        borderBottom: "1px solid #E0E0E0",
                        height: 44,
                    }}
                >
                    <CustomBreadcrumbs
                        firstName={"Integrations"}
                        firstFunction={-2}
                        secondName={name}
                    />
                </Stack>
                <Box
                    p={isMobile ? 2 : isTablet ? 3 : 0}
                // height={isDesktop ? "calc(100vh - 104px)" : "unset"}
                >
                    <Stack
                        direction={isMobile ? "column" : "row"}
                        border={isTablet ? "1px solid #E0E0E0" : "unset"}
                    // height={isDesktop ? "calc(100vh - 104px)" : "unset"}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: isMobile ? "unset" : 240,
                                px: 2.5,
                                py: isDesktop ? 5 : 2.5,
                                mb: isMobile ? 2.5 : 0,
                                border: isMobile ? "1px solid #E0E0E0" : "unset",
                            }}
                        >
                            <Box
                                display={isMobile ? "flex" : "unset"}
                                justifyContent="space-between"
                            >
                                <Stack mr={isMobile ? "30px" : 0} alignItems="center">
                                    <img
                                        src={shopifyIntegration}
                                        style={{ width: "100%", maxWidth: isMobile ? 100 : 150 }}
                                    />
                                </Stack>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontSize={18}
                                        fontWeight={500}
                                        color="#000"
                                        mt={isMobile ? 0 : 5}
                                        mb={2.5}
                                    >
                                        Integration Capability
                                    </Typography>
                                    <Stack rowGap={4}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Orders
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={data?.enableGetOrders}
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Products
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={data?.enableGetProducts}
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Shipment
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={data?.enableShipmentInfo}
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={style.switchBox}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#000"
                                            >
                                                Inventory
                                            </Typography>
                                            <Switch
                                                sx={style.switch}
                                                size="small"
                                                checked={data?.enableInventoryInfo}
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>

                        {isMobile ? <Divider></Divider> : <></>}
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                backgroundColor: "#fff",
                                py: isMobile ? 0 : 2.5,
                                px: isMobile ? 0 : isTablet ? 2.5 : 5,
                                borderLeft: isMobile ? "none" : "1px solid #E0E0E0",
                            }}
                        >
                            <Stack direction="row" alignItems="center" mb={2} justifyContent='space-between'>
                                <Typography
                                    ml={1.5}
                                    fontSize={18}
                                    fontWeight={500}
                                    color="#000"
                                >
                                    <Accept /> Configured
                                </Typography>
                                <Typography
                                    ml={1.5}
                                    fontSize={14}
                                    fontWeight={300}
                                    color="#1A1918"
                                >
                                    <IconButton aria-label="edit" sx={{ mr: 0.2 }}>
                                        <Edit onClick={() => navigate(`/listing-add-new-integration/${name}`, { state: { id: location?.state?.id } })} /></IconButton> Edit
                                </Typography>
                            </Stack>
                            <Box sx={{ ...style.greyBox, backgroundColor: 'unset' }}>
                                <Grid container rowGap="30px" columnSpacing={5}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required> Integration Name</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            fullWidth
                                            disabled
                                            placeholder="Enter integration name"
                                            value={data?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required>Shopify Id</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            disabled
                                            fullWidth
                                            placeholder="Shopify Id"
                                            value={data?.shopifyId}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required> Admin Token</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            disabled
                                            fullWidth
                                            placeholder="Paste Admin Token here"
                                            value={data?.token}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required>API Key</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            disabled
                                            fullWidth
                                            placeholder="Enter API Key here"
                                            value={data?.apiKey}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required> API Secret</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            disabled
                                            fullWidth
                                            placeholder="apiSecret"
                                            value={data?.apiSecret}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel required>URL</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            fullWidth
                                            disabled
                                            placeholder="domain"
                                            value={data?.url}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Link />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{ ".MuiInput-root": { pl: 0 } }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt="30px">
                                <Stack direction="row" alignItems="baseline" mb={2}>
                                    <Chip
                                        label="2"
                                        sx={{
                                            fontSize: 16,
                                            width: 22,
                                            height: 22,
                                            ".MuiChip-label": { px: 0.5 },
                                        }}
                                    />
                                    <Stack ml={1.5}>
                                        <Typography fontSize={18} fontWeight={500} color="#000">
                                            Create Product Tag
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            fontSize={14}
                                            fontWeight={500}
                                            color="#68737D"
                                        >
                                            Product tags are automatically assigned to products.
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={isDesktop ? "row" : "column"}>
                                    <Box sx={{ ...style.greyBox, flex: 1, backgroundColor: 'unset' }}>
                                        <InputLabel required>Product Tags</InputLabel>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            required
                                            disabled
                                            fullWidth
                                            placeholder="Type product tag and press enter"
                                        />
                                    </Box>
                                    {isDesktop ? (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                mx: 1,
                                                borderWidth: 1,
                                                borderColor: "#E0E0E0",
                                                opacity: 1,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <Box sx={{ ...style.greyBox, flex: 1, backgroundColor: 'unset' }}>
                                        {data?.tags?.length > 0 ? (
                                            <Chip
                                                sx={{
                                                    ...style.chipBox,
                                                    background:
                                                        "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                                                    color: "#fff",
                                                    borderColor: "#0038BA",
                                                    mr: isDesktop ? 2.5 : 0.5,
                                                    mb: isDesktop ? 1 : 0.5,
                                                }}
                                                label={"all"}
                                                variant="outlined"
                                                disabled
                                                // onDelete={() => setTags([])}
                                                style={{ width: "fit-content" }}
                                                deleteIcon={<CloseChipWhite />}
                                            />
                                        ) : (
                                            <></>
                                        )}

                                        {data?.tags?.split(",")?.map((tag: any) => (
                                            <Chip
                                                sx={{
                                                    ...style.chipBox,
                                                    mr: isDesktop ? 2.5 : 0.5,
                                                    mb: isDesktop ? 1 : 0.5,
                                                }}
                                                key={tag}
                                                label={tag}
                                                variant="outlined"
                                                // onDelete={() => handleTagClose(tag)}
                                                style={{ width: "fit-content" }}
                                                deleteIcon={<CloseChip />}
                                            />
                                        ))}
                                    </Box>
                                </Stack>
                            </Box>

                            {location?.state?.isFirstTime && <Box
                                sx={{
                                    alignItems: 'center',
                                    px: '30px',
                                    py: 1.5,
                                    backgroundColor: "#C8D6F8",
                                    mt: "30px",
                                    display: "flex",
                                }}
                            >
                                <figure style={{ marginBottom: 0 }}>
                                    <img src={ConnectorImg} />
                                </figure>
                                <Stack ml={3}>
                                    <Typography
                                        variant="body1"
                                        fontSize={16}
                                        fontWeight={500}
                                        color="#004EFF"
                                        mb={1.5}
                                    >
                                        You’re almost ready to set up your destination!
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontSize={14}
                                        fontWeight={500}
                                        color="#004EFF"
                                    >
                                        Select your second connector.
                                    </Typography>
                                </Stack>
                            </Box>}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
export default Index;
