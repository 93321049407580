import { Box, Stack, Typography, Link } from "@mui/material";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import ellipse from "../../../assets/images/ellipse.jpg";
import { ReactComponent as Inbox } from "../../../assets/images/icons/inbox.svg";
import { ReactComponent as Left } from "../../../assets/images/icons/left.svg";
import { style } from "../RegistrationScreen/index";
import { Link as Linknew } from "react-router-dom";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";

const OpenEmailApp = () => {
  const location = useLocation();
  const { forgotPasswordListingUser } = useClientAPI();
  const navigate = useNavigate();
  const submitDataLogin = async () => {
    await forgotPasswordListingUser(location?.state)
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.formWrapper}
      >
        <Stack
          justifyContent="center"
          sx={{ background: "#fff" }}
          className={styles.formContainer}
        >
          <img
            src={ellipse}
            alt="ellipse"
            className={`${styles.responsiveImg} ${styles.formBgImg}`}
          />
          <Stack sx={{ px: 6, py: 3, position: "relative" }}>
            <Stack flexDirection="row" justifyContent="center">
              <Inbox className={styles.keyIcon} />
            </Stack>
            <Stack mt={4} alignItems="center">
              <Typography variant="h4" fontSize={30} fontWeight={600} color='#000'>
                Check your inbox
              </Typography>
              <Typography variant="body2" fontSize={20} fontWeight={300} color='#000' textAlign='center'>
                We have sent a password reset link to
                <br />
                <span className={styles.email}>{location?.state.email}</span>
              </Typography>
            </Stack>

            <Linknew
              className={`${styles.filledBtn} ${styles.linkBtn}`}
              to="https://mail.google.com/mail/u/1/?ogbl#inbox"
              target="_blank"
            >
              Open email app
            </Linknew>

            <Typography variant="body2" mb={2.5} fontSize={14} fontWeight={500}>
              Don’t receive the email?{" "}
              <button className={styles.bluelinks} style={{ border: "none", backgroundColor: 'unset' }} onClick={() => submitDataLogin()}>Click to resend</button>
            </Typography>
            <Link
              className={styles.textLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                navigate("/listing-login");
              }}
            >
              <Left className={styles.backArrow} /> Back to log in
            </Link>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default OpenEmailApp;
