import { Button, Typography, Dialog, DialogTitle, IconButton, DialogContent, Box, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export const style = {
    tabContainer: {
        ".MuiTabs-indicator": {
            backgroundColor: "#000000",
            height: "1px",
        },
    },
    tabList: {
        ".MuiButtonBase-root": {
            color: "#68737D",
            fontWeight: 500,
            fontSize: 14,
            textTransform: "capitalize",
            "&.Mui-selected": {
                color: "#1E1F21",
            },
        },
    },
    borderStack: {
        border: "1px solid #E0E0E0",
        borderRadius: 0.5,
        height: 32,
    },

    fiiledBtn: {
        background: "linear-gradient(138deg, #0038BA 0%, #4F18FF 100%)",
        borderRadius: 0.5,
        textTransform: "capitalize",
        height: 40,
        ml: 2,
        boxShadow: "unset",
        fontSize: 14,
        '&:hover': {
            boxShadow: 'unset'
        }
    },
    outlineNewBtn: {
        background: '#fff',
        border: '1px solid #E0E0E0',
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'unset'
        }
    },

    dialogContainer: {
        ".MuiInputLabel-root": {
            color: "#1A1918",
            fontSize: 14,
            fontWeight: 500,
            pl: 1.5,
        },
        ".MuiInputBase-root": {
            pl: 1.5,
            "&:before": {
                borderBottom: "1px solid #E0E0E0",
            },
        },
        ".Mui-error": {
            pl: 1.5,
        },
    },
    modalBtn: {
        width: "100%",
        maxWidth: "100px",
        borderRadius: 0.5,
        textTransform: "capitalize",
        fontSize: 14,
        height: 34,
    },
};

const Index = (props: any) => {
    const { open, setOpen, handleDeleteUser, header } = props;
    return (<>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ ...style.dialogContainer, ".MuiPaper-root": { minWidth: 400, } }}
        >
            <DialogTitle sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}            >
                Delete {header}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
                <Box sx={{ borderRadius: 0.5, p: 1.5 }}>

                    <Typography variant="h6">Are you sure to delete ?</Typography>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "center",
                    pb: 3,
                    "&>:not(:first-of-type)": { ml: 2.5 },
                }}
            >
                <Button
                    onClick={() => handleDeleteUser()}
                    sx={{
                        ...style.modalBtn,
                        background:
                            "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                        color: "#fff",
                    }}
                >
                    Delete
                </Button>
                <Button
                    onClick={() => setOpen(false)}
                    sx={{
                        ...style.modalBtn,
                        ml: 2,
                        border: "1px solid rgba(104, 115, 125, 0.48)",
                        color: "#68737D",
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}
export default Index;