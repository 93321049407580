import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import "rsuite/dist/rsuite.css";
import { ReactComponent as Calendar } from "../../../assets/images/icons/calendar.svg";
import HeaderListing from "../../../components/headerListing";
import { useAnalyticsDashboardSAAS } from "../../../shared/api/useAnalyticsDashboardSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style as styless } from "../../listingScreens/superAdminDashboard/index";
import DetailedReportTable from "../PayoutReports/PayoutReportTable/DetailedReportTable";
import SummaryPayout from "../PayoutReports/summaryPayout";
import Pagination from "../paginationListing";
import styles from "./index.module.scss";

const Index = () => {
  const { setSelectedIndex, tabsSubData, tabsAdded } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const date = new Date();
  const [monthoptions, setMonthoptions] = useState<any>([]);
  let [dateFilter, setDateFilter] = useState<any>();
  const [page, setPage] = useState<any>(0);
  const [value, setValue] = useState("1");
  const [salesData, setSalesData] = useState<any>();
  const [summaryData, setSummaryData] = useState<any>();
  const [status, setstatus] = useState("");
  const [billingCycle, setBillingCycle] = useState<any>(0);

  const initialPayload = {
    startDate: dateFilter?.length && dateFilter[0],
    endDate: dateFilter?.length && dateFilter[1],
    limit: 50,
    status: status,
    offset: page,
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const {
    getDetailedPayoutReport,
    ExportPayoutReport,
    getSummarydataAPI,
    ExportSummeryPayoutReport,
  } = useAnalyticsDashboardSAAS();

  const salesReturnsTableColumns = [
    { header: "Date", apiKey: "orderDate" },
    { header: "Order Id", apiKey: "siteOrderID" },
    { header: "Invoice Id", apiKey: "invoiceID" },
    { header: "SKU", apiKey: "sku" },
    { header: "Title", apiKey: "title" },
    { header: "Qty Sold", apiKey: "quantity" },
    { header: "GMV($)", apiKey: "gmv", symbol: "$" },
    { header: "Marketplace Fees($)", apiKey: "discount", symbol: "$" },
    { header: "Resale Fees($)", apiKey: "resaleFee", symbol: "$" },
    { header: "Gross Proceeds($)", apiKey: "netProceeds", symbol: "$" },
  ];
  const salesReturnsTableColumnsConvetional = [
    { header: "Date", apiKey: "createdDate" },
    { header: "Seller Name", apiKey: "sellerName" },
    { header: "Seller Order Code", apiKey: "sellerOrderCode" },
    { header: "Seller Order Number", apiKey: "sellerOrderNumber" },
    { header: "LineItem ID", apiKey: "lineItemID" },
    { header: "LineItem Title", apiKey: "lineItemTitle" },
    { header: "LineItem SKU", apiKey: "lineItemSKU" },
    { header: "LineItem Quantity", apiKey: "lineItemQuantity" },
    { header: "LineItem Retail Price", apiKey: "lineItemRetailPrice" },
    { header: "LineItem Price", apiKey: "lineItemPrice" },
  ];
  const MonthlyList = () => {
    const months = [];

    for (let i = 0; i < 5; i++) {
      const currentDate = moment().subtract(i, "months");
      const startOfMonth = currentDate.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      months.push(monthObject);
    }
    setMonthoptions(months);
    setDateFilter(months[0]?.value.split("/"));
  };

  const BiMonthlyList = () => {
    const months = [];

    for (let i = 0; i < 5; i += 2) {
      const currentDate = moment().subtract(i, "months");
      const prevMonth = moment().subtract(i + 1, "months");
      const startOfMonth = prevMonth.clone().startOf("month");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      months.push(monthObject);
    }
    setMonthoptions(months);
    setDateFilter(months[0]?.value.split("/"));
  };

  const BiweeklyList = () => {
    const months = [];

    for (let i = 0; i < 3; i++) {
      const currentDate = moment().subtract(i, "months");
      const startOfMonth = currentDate.clone().startOf("month");
      const midmonth = moment(startOfMonth).add(14, "days");
      const midmonth2 = moment(startOfMonth).add(15, "days");
      const endOfMonth = currentDate.clone().endOf("month");

      const monthObject = {
        label: `${startOfMonth.format("MMM Do YY")} To ${midmonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(startOfMonth).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(midmonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      const monthObject2 = {
        label: `${midmonth2.format("MMM Do YY")} To ${endOfMonth.format(
          "MMM Do YY"
        )}`,
        value: `${moment(midmonth2).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(endOfMonth).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };
      if (i === 0 && parseInt(moment().format("DD")) < 16) {
        months.push(monthObject);
      } else {
        months.push(monthObject2);
        months.push(monthObject);
      }
    }
    setMonthoptions(months);
    setDateFilter(months[0]?.value.split("/"));
  };

  const weeklyList = () => {
    let dates: any = [];
    let date = new Date();
    const dayOfWeek = date.getDay();
    let startDate: any = new Date(date);
    startDate.setDate(date.getDate() - dayOfWeek + 7);
    const addDays = () => {
      const prevDate = startDate;
      startDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      const dateObject = {
        label: `${moment(startDate).format("MMM Do YY")} To ${moment(
          prevDate
        ).format("MMM Do YY")}`,
        value: `${moment(startDate).format(
          "YYYY-MM-DDT00:00:00.000[Z]"
        )}/${moment(prevDate).format("YYYY-MM-DDT00:00:00.000[Z]")}`,
      };

      dates.push(dateObject);
    };

    for (let i = 0; i < 5; i++) {
      addDays();
    }
    setMonthoptions(dates);
    setDateFilter(dates[0]?.value.split("/"));
  };
  const isConvictionalClient = localStorage.getItem("isConvictional");
  const getSummaryData = async () => {
    const { billingCycle, items } = await getSummarydataAPI();
    setBillingCycle(billingCycle);
    console.log(items, "items");
    setSummaryData(items);
  };

  const getSalesData = async () => {
    const res = await getDetailedPayoutReport(initialPayload);
    setSalesData(res);
    console.log("DataSet", res?.results)
  };

  const exportPayoutReport = async () => {
    const payload = {
      startDate: dateFilter?.length && dateFilter[0],
      endDate: dateFilter?.length && dateFilter[1],
    };
    const res = await ExportPayoutReport(payload);
    if (res.status === 200) {
      var data = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });
      var csvURL = window.URL.createObjectURL(data);
      let tempLink;
      tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "OrderPayoutReports.xlsx");
      tempLink.click();
    }
  };
  const exportSummeryReport = async () => {
    const res = await ExportSummeryPayoutReport(initialPayload);
    if (res.status === 200) {
      var data = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });
      var csvURL = window.URL.createObjectURL(data);
      let tempLink;
      tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "OrderPayoutReports.xlsx");
      tempLink.click();
    }
  };
  useEffect(() => {
    if (billingCycle === 0) weeklyList();
    else if (billingCycle === 1) BiweeklyList();
    else if (billingCycle === 2) MonthlyList();
    else if (billingCycle === 3) BiMonthlyList();
  }, [billingCycle === 0 || billingCycle]);

  useEffect(() => {
    if (page === 0 || page) {
      dateFilter?.length > 0 && getSalesData();
    }
  }, [page, dateFilter, status]);

  useEffect(() => {
    setPage(0);
  }, [dateFilter]);

  useEffect(() => {
    setPage(0);
    console.log(value, "valueeee");
    if (value === "2") setstatus("Shipped");
    else if (value === "3") setstatus("Returned");
    else setstatus("");
  }, [value]);

  useEffect(() => {
    getSummaryData();
    setSelectedIndex(11);
  }, []);

  const handleChangeDate = (e: any) => {
    let stringArray = e.target.value.split("/");
    setDateFilter(stringArray);
  };
  return (
    <>
      <HeaderListing />
      {tabsSubData.length && tabsAdded ? (
        <></>
      ) : (
        <Box
          className={styles.contentMain}
          component="main"
          sx={{
            flexGrow: 1,
            py: 2.5,
            px: isMobile ? 2 : 5,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb={1.5}
            alignItems={isMobile ? "flex-start" : "center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Stack
              mb={2}
              alignItems="center"
              width={isMobile ? "100%" : "none"}
              justifyContent={isMobile ? "space-between" : "none"}
              direction="row"
            >
              <Typography
                variant="h4"
                fontSize={20}
                fontWeight={500}
                color="#000"
              >
                Payout Report
              </Typography>

              <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  display: isMobile ? "flex" : "none",
                }}
                onClick={() => exportPayoutReport()}
              >
                <FileUploadOutlinedIcon style={{ marginRight: "10px" }} />
                Export
              </Button>
            </Stack>
            <Stack direction="row">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                gap={"10px"}
                sx={{ px: isMobile ? "0px" : "10px" }}
              >
                {/* <DateRangePicker placement="autoVerticalEnd" size="md" cleanable={false} format="MM-dd-yyyy" placeholder={["Start Date", "-", "End Date"]} onChange={(e) => setDateFilter(e)} className={styles.dateRangePicker} /> */}
                <Typography
                  variant="body2"
                  fontSize={16}
                  fontWeight={500}
                  color="#1A1918"
                >
                  {billingCycle === 0 && "Weekly"}
                  {billingCycle === 1 && "Bi-Weekly"}
                  {billingCycle === 2 && "Monthly"}
                  {billingCycle === 3 && "Bi-Monthly"}
                </Typography>
                <Calendar />

                <FormControl
                  variant="standard"
                  sx={{
                    maxWidth: 280,
                    ".MuiInputBase-root": {
                      fontSize: 14,
                      "&:before": {
                        borderBottom: "unset",
                      },
                      "&:after": {
                        borderBottom: "unset",
                      },
                      "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "unset",
                      },
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={handleChangeDate}
                    inputProps={{ "aria-label": "Without label" }}
                    value={dateFilter?.length ? dateFilter.join("/") : ""}
                    sx={{
                      ".MuiSelect-select": {
                        "&.MuiInputBase-input": {
                          "&.MuiInput-input": {
                            "&:focus": {
                              backgroundColor: "unset",
                            },
                          },
                        },
                      },
                    }}
                  >
                    {monthoptions &&
                      monthoptions.map((month: any, index: any) => {
                        return (
                          <MenuItem value={month.value}>
                            {month.label}

                            {index === 0 && "(Open)"}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  display: isMobile ? "none" : "flex",
                }}
                onClick={() => exportPayoutReport()}
              >
                <FileUploadOutlinedIcon style={{ marginRight: "10px" }} />
                Export
              </Button>
            </Stack>
          </Box>
          <TabContext value={value}>
            <Box sx={styless.tabContainer}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                sx={styless.tabList}
              >
                <Tab label="Summary" value="1" />
                <Tab label="Sales" value="2" />
                <Tab label="Returns" value="3" />
              </TabList>
            </Box>
            <Divider
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#E0E0E0",
                borderBottomWidth: "inherit",
                opacity: 1,
              }}
            />
            <TabPanel value="1" sx={{ px: 0 }}>
              <Box>
                <SummaryPayout data={summaryData} />
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <Box className={styles.reportTableContainer}>
                <Stack direction="row" alignItems="center" justifyContent="end">
                  <Pagination
                    page={page}
                    totalCount={
                      salesData?.results?.count ? salesData.results.count : 0
                    }
                    setPage={setPage}
                  />
                </Stack>
                {isConvictionalClient == "1" ?
                  <DetailedReportTable
                    page={page}
                    data={salesData?.results?.itemsConvictional}
                    columns={salesReturnsTableColumnsConvetional}
                  />
                  :
                  <DetailedReportTable
                    page={page}
                    data={salesData?.results?.items}
                    columns={salesReturnsTableColumns}
                  />
                }
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ px: 0 }}>
              <Box>
                <Stack direction="row" alignItems="center" justifyContent="end">
                  <Pagination
                    page={page}
                    totalCount={
                      salesData?.results?.count ? salesData.results.count : 0
                    }
                    setPage={setPage}
                  />
                </Stack>
                {isConvictionalClient == "1" ?
                  <DetailedReportTable
                    page={page}
                    data={salesData?.results?.itemsConvictional}
                    columns={salesReturnsTableColumnsConvetional}
                  />
                  :
                  <DetailedReportTable
                    page={page}
                    data={salesData?.results.items}
                    columns={salesReturnsTableColumns}
                  />
                }
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
};

export default Index;
