import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { style } from ".";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useAuth } from "../../../shared/provider/authprovider";


const Index = (props: any) => {
    const { show, modalTitle, setShow, userData, isView, fetchListData } = props;
    const {setMessageText, setOpen} = useAuth()
    const [type, setType] = useState(null);
    const { fetchUserData, editUserData } = useClientAPI();

    const approveUserSchemaForDirectPercentageShare = Yup.object().shape({
        dcid: Yup.string().required("Required").nullable(),
        classificationCode: Yup.string().required("Required").nullable(),
        resaleFee: Yup.string().required("Required").nullable(),
        revShareDropShipper: Yup.string().required("Required").nullable(),
        directPercentageShare: Yup.string().required("Required").nullable(),
        payoutReport: Yup.string().required("Required").nullable(),
    });

    const approveUserSchemaFixedDollarAmount = Yup.object().shape({
        dcid: Yup.string().required("Required").nullable(),
        classificationCode: Yup.string().required("Required").nullable(),
        resaleFee: Yup.string().required("Required").nullable(),
        revShareDropShipper: Yup.string().required("Required").nullable(),
        directPercentageShare: Yup.string().required("Required").nullable(),
        payoutReport: Yup.string().required("Required").nullable(),
    });

    const approveUserSchemaPercentageofGMV = Yup.object().shape({
        dcid: Yup.string().required("Required").nullable(),
        classificationCode: Yup.string().required("Required").nullable(),
        resaleFee: Yup.string().required("Required").nullable(),
        marketPlaceFee: Yup.string().required("Required").nullable(),
        fixedFee: Yup.string().required("Required").nullable(),
        percentage: Yup.string().required("Required").nullable(),
        revShareDropShipper: Yup.string().required("Required").nullable(),
        payoutReport: Yup.string().required("Required").nullable(),
    });

    const formik = useFormik({
        initialValues: {
            id: null,
            dcid: null,
            classificationCode: null,
            resaleFee: null,
            marketPlaceFee: null,
            fixedFee: null,
            percentage: null,
            higherValue: null,
            revShareDropShipper: null,
            directPercentageShare: null,
            payoutReport: '',
            isHigher: false
        },
        validationSchema: type === 0 ? approveUserSchemaForDirectPercentageShare : type === 1 ? approveUserSchemaFixedDollarAmount : approveUserSchemaPercentageofGMV,
        onSubmit: () => submitData(),
    });

    const submitData = async () => {
        try {
            const payload = {
                ...formik.values,
            }
            const res = await editUserData(payload);
            if (res.status === 200) {
                setShow(false);
                await fetchListData();
                setMessageText("User Edited");
                setOpen(true)                
            }
        } catch (err) {
        }
    }

    const fetchData = async (id: any) => {
        const res: any = await fetchUserData(id)
        formik.setValues(res.getAccountDto);
        formik.setFieldValue('payoutReport', res.getAccountDto.payoutReport);
        console.log(res.getAccountDto.payoutReport)
    }

    useEffect(() => {
        if (userData.id) {
            fetchData(userData.id);
        }
    }, [userData.id])

    return (<>
        <Dialog
            open={show}
            onClose={() => { formik.resetForm(); setShow(false) }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
        >
            <DialogTitle sx={{ m: 0, p: 2.5, fontSize: 18, fontWeight: 300 }}>
                <Typography variant="body2" fontWeight={300} fontSize={14}>
                    {modalTitle}{" "} user
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => { formik.resetForm(); setShow(false) }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
                <FormikProvider value={formik}>
                    <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputLabel>DCID*</InputLabel>
                                <Field name="dcid">
                                    {({ field, meta, form }: any) => (
                                        <>
                                            <TextField
                                                {...field}
                                                id="standard-required"
                                                autoComplete="off"
                                                variant="standard"
                                                type="number"
                                                fullWidth
                                                disabled={isView}
                                                placeholder="Enter DCID"
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={
                                                    meta.touched && meta.error && meta.error
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputLabel>DCID Code</InputLabel>
                                <Field name="classificationCode">
                                    {({ field, meta, form }: any) => (
                                        <>
                                            <TextField
                                                {...field}
                                                id="standard-required"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                disabled={isView}
                                                placeholder="Enter DCID Code"
                                                {...{ error: meta.touched && meta.error }}
                                                helperText={
                                                    meta.touched && meta.error && meta.error
                                                }
                                            />
                                        </>
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box>
                                    <InputLabel required>Resale Fee</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={formik.values.resaleFee}
                                        fullWidth
                                        disabled={isView}
                                        onChange={(e: any) => { setType(e.target.value); formik.setFieldValue("resaleFee", e.target.value) }}
                                        variant="standard"
                                        sx={{
                                            'MuiSelect-select': {
                                                '&.MuiInputBase-input': {
                                                    '&.MuiInput-input:focus': {
                                                        backgroundColor: '#fff',
                                                    }
                                                }

                                            }
                                        }}
                                    >
                                        <MenuItem value={0}>Direct Percentage Share</MenuItem>
                                        <MenuItem value={1}>Fixed Dollar Amount</MenuItem>
                                        <MenuItem value={2}>Percentage of GMV</MenuItem>
                                    </Select>
                                    <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formik.errors.resaleFee} </FormHelperText>
                                </Box>
                            </Grid>

                            {formik.values.resaleFee === 2 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Marketplace Fee</InputLabel>
                                            <Field name="marketPlaceFee">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Marketplace Fee"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Fixed Dollar Amount</InputLabel>
                                            <Field name="fixedFee">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Fixed Dollar Amount"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel>Percentage of GMV*</InputLabel>
                                            <Field name="percentage">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Percentage of GMV"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <InputLabel>Higher Value (will be used)*</InputLabel>
                                        <Switch checked={formik.values.isHigher}
                                            disabled={isView}
                                            onChange={() => formik.setFieldValue("isHigher", !formik.values.isHigher)}
                                        />
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee === 1 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel>Direct Percentage Share*</InputLabel>
                                            <Field name="directPercentageShare">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Direct Percentage Share"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee === 0 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Direct Percentage Share</InputLabel>
                                            <Field name="directPercentageShare">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            type="number"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Direct Percentage Share"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                </>)}

                            {formik.values.resaleFee !== "" &&
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Share In NetProceeds</InputLabel>
                                            <Field name="revShareDropShipper">
                                                {({ field, meta, form }: any) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            id="standard-required"
                                                            autoComplete="off"
                                                            variant="standard"
                                                            fullWidth
                                                            disabled={isView}
                                                            placeholder="Enter Share In NetProceeds"
                                                            {...{ error: meta.touched && meta.error }}
                                                            helperText={
                                                                meta.touched && meta.error && meta.error
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box>
                                            <InputLabel required>Payroll Period</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={formik.values.payoutReport}
                                                placeholder="Select Payroll Period"
                                                fullWidth
                                                disabled={isView}
                                                onChange={(e) => formik.setFieldValue("payoutReport", e.target.value)}
                                                variant="standard"
                                            >
                                                <MenuItem value={0}>Weekly</MenuItem>
                                                <MenuItem value={1}>Bi-Weekly</MenuItem>
                                                <MenuItem value={2}>Monthly</MenuItem>
                                                <MenuItem value={3}>Bi-Monthly</MenuItem>
                                            </Select>
                                            <FormHelperText sx={{ pl: 1.5, color: '#d32f2f' }}>{formik.errors.payoutReport} </FormHelperText>
                                        </Box>
                                    </Grid>
                                </>}
                        </Grid>

                    </Box>
                </FormikProvider>
            </DialogContent>

            <DialogActions
                sx={{ justifyContent: "center", pb: 3, "&>:not(:first-of-type)": { ml: 2.5 }, }}
            >
                {!isView && <Button
                    onClick={() => { formik.handleSubmit() }}
                    sx={{ ...style.modalBtn, background: "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)", color: "#fff", }}
                >
                    Save
                </Button>}
                <Button
                    sx={{ ...style.modalBtn, ml: 2, border: "1px solid rgba(104, 115, 125, 0.48)", color: "#68737D", }}
                    onClick={() => { setShow(false) }}
                >
                    {isView ? "Close" : "Cancel"}
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}
export default Index;