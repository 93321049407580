import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./index.module.scss";

export const style = {
  tableData: {
    fontSize: 14,
    fontWeight: 300,
  },
};
interface IProps {
  data: Array<object>;
  page?: any;
  columns?: any;
  reportType?: any;
}

const Index = (props: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  console.log(props, "props====")
  const { data, reportType } = props;
  const summaryTableColumns = [
    { header: "GMV($)", apiKey: "gmv" },
    { header: "Vendor Rev Share($)", apiKey: "vendorRevShare" },
    { header: reportType === 1 ? "Order Fee($)" : "Returns($)", apiKey: reportType === 1 ? "orderFee" : "returns" },
    { header: "Total Amount to Pay(100%)($)", apiKey: "totalAmount" },
  ];
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <>
      {data?.map((ele: any, index: number) => (
        <Box mb={5}>
          <Typography
            variant="h6"
            color="#1A1918"
            fontWeight={500}
            fontSize={16}
            mb={1.5}
          >
            <span style={{ marginLeft: "10px" }}>{ele.period}</span>
          </Typography>
          <div className={`${styles.heightG} ${styles.summaryTable}`}>
            <table className={styles.tableH}>
              <thead className={`${styles.thead} ${styles.unstyledthead}`}>
                <tr>
                  {summaryTableColumns.map((item: any) => (
                    <td
                      style={{
                        verticalAlign: 'top',
                        textAlign: 'right'
                      }}
                    >{item.header}</td>
                  ))}
                </tr>
              </thead>
              <tbody className={`${styles.tbody} ${styles.unstyledtbody}`}>
                <tr>
                  {summaryTableColumns.map((item: any) => (
                    <td align="right">{formatter.format(ele[item.apiKey])}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </Box>
      ))}
    </>
  );
};

export default Index;
