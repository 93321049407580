import { Box, Divider, IconButton, ListItemButton, ListItemText, Menu, Paper, Stack, Typography } from "@mui/material";
import { ReactComponent as Reset } from "../../../../assets/images/icons/reset.svg";


export const style = {
  filterContainer: {
    ".MuiMenu-paper": {
      border: "1px solid red",
      borderRadius: 0.5,
      borderColor: "#E0E0E0",
      top: "218px !important",
      ".MuiList-root": {
        px: 1.5,
        py:1.5,
      },
      ".MuiAccordionSummary-root": {
        minHeight: 0,
        py: 1,
        px: 1.5,
        ".MuiAccordionSummary-content": {
          m: 0,
        },
      },
      ".MuiAccordionDetails-root": {
        ".MuiTypography-body1": {
          px: 1.5,
          py: 1,
          fontSize: 14,
          fontWeight: 300,
          ".MuiCheckbox-root": {
            p: 0,
          },
          "&:hover": {
            backgroundColor: "#EFEFEF",
            borderLeft: "2px solid #4079FC",
          },
        },
      },
    },
  },
};

const Index = (props: any) => {
  const { handleClose, anchorEl, open, changeStatus, setPage, setStatus, status, listingDashboardData } = props;

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={style.filterContainer}
    >
      <Paper elevation={0} sx={{ width: "100%", minWidth: 240 }}>
        <Box>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="space-between"
            alignItems="center"
            mt={0.5}
          >
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={500}
              color="#1A1918"
            >
              Status
            </Typography>
            <Stack direction="row" spacing={1.5}>
              <IconButton
                aria-label="reset"
                sx={{ width: 34, height: 34 }}
                onClick={() => { setStatus(8); handleClose() }}
              >
                <Reset />
              </IconButton>
            </Stack>
          </Box>
          <Divider sx={{ mt: 1, mb: 2, opacity: 1 }} />
          <ListItemButton
            sx={{
              py: 0,
              px: 1,
              minHeight: 32,
              justifyContent: "space-between",
              backgroundColor:
                status === 8 ? "rgba(0, 0, 0, 0.04)" : "",
            }}
            onClick={() => {
              setPage(0);
              changeStatus(8);
              handleClose();
            }}
          >
            <ListItemText
              sx={{ flexGrow: 0 }}
              primary="All Scanned"
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
            <ListItemText
              sx={{ flexGrow: 0 }}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            >
              {listingDashboardData.totalCount}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            sx={{
              py: 0,
              px: 1,
              minHeight: 32,
              justifyContent: "space-between",
              backgroundColor:
                status === 1 ? "rgba(0, 0, 0, 0.04)" : "",
            }}
            onClick={() => {
              setPage(0);
              changeStatus(1);
              handleClose();
            }}
          >
            <ListItemText
              sx={{ flexGrow: 0 }}
              primary="Pending "
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
            <ListItemText
              sx={{ flexGrow: 0 }}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            >
              {listingDashboardData.totalPending}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            sx={{
              py: 0,
              px: 1,
              minHeight: 32,
              justifyContent: "space-between",
              backgroundColor:
                status === 2 ? "rgba(0, 0, 0, 0.04)" : "",
            }}
            onClick={() => {
              setPage(0);
              changeStatus(2);
              handleClose();
            }}
          >
            <ListItemText
              sx={{ flexGrow: 0 }}
              primary="Processing"
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
            <ListItemText
              sx={{ flexGrow: 0 }}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            >
              {listingDashboardData.totalProcessing}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            sx={{
              py: 0,
              px: 1,
              minHeight: 32,
              justifyContent: "space-between",
              backgroundColor:
                status === 3 ? "rgba(0, 0, 0, 0.04)" : "",
            }}
            onClick={() => {
              setPage(0);
              changeStatus(3);
              handleClose();
            }}
          >
            <ListItemText
              sx={{ flexGrow: 0 }}
              primary="Listed"
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
            <ListItemText
              sx={{ flexGrow: 0 }}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            >
              {listingDashboardData.totalListing}
            </ListItemText>
          </ListItemButton>
        </Box>
      </Paper>
    </Menu>
  );
};
export default Index;
