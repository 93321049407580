import { IconButton, Stack } from "@mui/material";
import { ReactComponent as Arrowright } from "../../../assets/images/icons/arrow-right.svg";
import { ReactComponent as Arrowleft } from "../../../assets/images/icons/arrow-left.svg";

const index = (props: any) => {
  const { page, setPage, totalCount } = props;

  const handleIncreaseOnPageChange = () => {
    if (totalCount) {
      if (totalCount > page + 1 * 50) {
        setPage(page + 50);
      }
    }
  };
  const handleDecreaseOnPageChange = () => {
    if (page > 0) {
      setPage(page - 50);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Stack>
          {totalCount ? (page / 50 + 1) * 50 - 49 : 0} -{" "}
          {totalCount > (page / 50 + 1) * 50
            ? (page / 50 + 1) * 50
            : totalCount}{" "}
          of {totalCount}
        </Stack>
        <Stack direction="row" ml={3}>
          <IconButton onClick={handleDecreaseOnPageChange}>
            <Arrowright />
          </IconButton>
          <IconButton onClick={handleIncreaseOnPageChange}>
            <Arrowleft />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};
export default index;
