import { useState } from "react";
import { useAuth } from "../provider/authprovider";
import { useClientAPI } from "./userManagementSAAS"

export const useIntegrationsSAAS = () => {
    const { apinew } = useAuth();
    const { handleErrorSaas, roles } = useClientAPI();
    const [integrationsAccountsList, setIntegrationsAccountsList] = useState<any>(null);

    const getIntegrationsAccountsList = async () => {
        try {
            const result = await apinew?.get(`/api/Integration/Accounts`);
            setIntegrationsAccountsList(result?.data?.result)
            return result
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const getImageist = async () => {
        try {
            const result = await apinew?.get(`/api/Integration/AccountType`);
            return result;
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const destinatonDashData = async (payload:any) => {
        try {
            const requestPayload = new URLSearchParams(payload).toString();
            const result = await apinew?.get(`/api/Integration/SourceDestination?${requestPayload}`);
            return result;
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const sourceDashData = async (payload:any) => {
        try {
            const requestPayload = new URLSearchParams(payload).toString();
            const result = await apinew?.get(`/api/Integration/SourceDestination?${requestPayload}`);
            return result;
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const getIndividualData = async (id:any) => {
        try {
            const result = await apinew?.get(`/api/Integration/${id}`);
            return result;
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const deleteIndividualEntry = async (id:any) => {
        try {
            const result = await apinew?.delete(`/api/Integration/${id}`);
            return result;
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const postNewIntegrationData = async (values:any) => {
        try {
            const result = await apinew?.post(`/api/Integration/Setup`, values);
            return result
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const postNewDestinationIntegration = async (payload:any) => {
        try {
            const result = await apinew?.post(`/api/Integration/OrderDestination`, payload);
            return result
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const putDestinationIntegration = async (payload:any) => {
        try {
            const result = await apinew?.put(`/api/Integration/OrderDestination`, payload);
            return result
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };

    const putSourceIntegration = async (payload:any) => {
        try {
            const result = await apinew?.put(`/api/Integration`, payload);
            return result
        } catch (error: any) {
            handleErrorSaas(error)
        }
    };
    return { putDestinationIntegration, putSourceIntegration, getIndividualData, getIntegrationsAccountsList, integrationsAccountsList, postNewIntegrationData, postNewDestinationIntegration, sourceDashData, destinatonDashData, getImageist, deleteIndividualEntry } as const
}