import { useState } from "react";
import { useAuth } from "../provider/authprovider";
import axios from "axios";

export interface IUseAuth {
  token: any | null;
}

export interface IPayoutReport {
  status: string;
  startDate: string;
  endDate: string;
  offset: number;
  limit: number;
}

export const useAnalyticsDashboardSAAS: any = () => {
  const { apinew, setMessageText, setOpen, messageText } = useAuth();
  const [topSellingBrands, setTopSellingBrandsData] = useState<any>([
    { label: "Nike", value: 10 },
    { label: "Calvin Klein", value: 20 },
    { label: "Boeing ", value: 15 },
    { label: "Dove", value: 40 },
    { label: "H&M", value: 35 },
  ]);
  const [topSellingCategory, setTopSellingCategoryData] = useState<any>([
    { label: "Automotive Parts", value: 10 },
    { label: "Electronics", value: 15 },
    { label: "Industrial goods ", value: 17 },
    { label: "Dove", value: 12 },
    { label: "H&M", value: 11 },
  ]);
  const [clientData, setClientData] = useState<any>();

  const getDetailedPayoutReportbyDCID = async (dcid: any, payload: any) => {
    try {
      const res = await apinew.post(
        `api/OrderPayout/Reports/Admin?dcid=${dcid}`,
        payload
      );
      if (res?.status == 200) {
        return res?.data;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const getDetailedPayoutReport = async (payload: any) => {
    const res = await apinew.post(`/api/OrderPayout/Reports`, payload);

    if (res.status === 200) {
      return res.data;
    }
  };
  const getClientsListForPayoutReport = async () => {
    try {
      const res = await apinew.get("/api/Admin/Account");
      if (res?.status == 200) {
        console.log(res.data, "llllll");
        setClientData(res.data);
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const ExportPayoutReport = async (payload: any) => {
    try {
      const res = await apinew.post(`/api/OrderPayout/GenerateExcel`, payload, {
        responseType: "blob",
      });
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const ExportPayoutReportbyDCID = async (dcid: any, payload: any) => {
    try {
      const res = await apinew.post(
        `api/OrderPayout/GenerateExcel/Admin?dcid=${dcid}`,
        payload,
        { responseType: "blob" }
      );
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const ExportSummeryPayoutReport = async (payload: any) => {
    try {
      const res = await apinew.post(
        `/api/OrderPayout/Reports/Summary/Export`,
        payload,
        { responseType: "blob" }
      );
      if (res?.status == 200) {
        return res;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const getSummarydatabyDCIDAPI = async (dcid: any) => {
    try {
      const res = await apinew.get(
        `api/OrderPayout/Reports/Admin/Summary?dcid=${dcid}`
      );
      if (res?.status == 200) {
        return res?.data.results;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const getSummarydataAPI = async () => {
    try {
      const res = await apinew.get(`/api/OrderPayout/Reports/Summary`);
      if (res?.status == 200) {
        return res?.data.results;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const uploadSummaryCSVAPI = async (dcid: any, file: any) => {
    try {
      const res = await apinew.post(
        `/api/OrderPayout/Upload/PayoutReport?dcid=${dcid}`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.status == 200) {
        return res;
      }
      return res;
    } catch (error: any) {
      setMessageText(error?.response?.data?.exception);
      return error?.response?.data;
    }
  };
  const uploadSalesCSVAPI = async (dcid: any, file: any) => {
    try {
      const res = await apinew.post(
        `/api/OrderPayout/Upload/PayoutReport?dcid=${dcid}`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.status == 200) {
        setMessageText("File Uploaded Successfully");
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
        }, 5000);
        return res;
      }
      return res;
    } catch (error: any) {
      setMessageText(error?.response?.data?.exception);
      return error?.response?.data;
    }
  };
  const uploadReturnsCSVAPI = async (dcid: any, file: any) => {
    try {
      const res = await apinew.post(
        `/api/OrderPayout/Upload/PayoutReport?dcid=${dcid}`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.status == 200) {
        setMessageText("File Uploaded Successfully");
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
        }, 5000);
        return res;
      }
      return res;
    } catch (error: any) {
      setMessageText(error?.response?.data?.exception);
      return error?.response?.data;
    }
  };
  const getTotalSalesData = async () => {
    const res: any = await axios.get(
      "https://api-generator.retool.com/9RbhRO/data"
    );
    if (res) {
      let newData = {
        results: {
          count: res.data?.length,
          items: res?.data,
        },
        status: 200,
      };

      return res.data;
    }
    console.log(res);
  };
  const getDetailedPayoutReportbyDCIDConvictional = async (dcid: any, payload: any) => {
    try {
      const res = await apinew.post(`/api/OrderPayout/Reports/Admin/Convictional?AccountId=${dcid}`, payload);
      if (res?.status == 200) {
        return res?.data;
      }
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
    }
  };
  const uploadSummaryCSVAPIConvictional = async (accId: any, file: any) => {
    try {
      const res = await apinew.post(`/api/OrderPayout/Upload/PayoutReport/Convictional?accountId=${accId}`, file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.status == 200) {
        return res;
      }
      return res;
    } catch (error: any) {
      setMessageText(error?.response?.data?.exception);
      return error?.response?.data;
    }
  };
  // const ExportPayoutReportbyDCIDConvictional = async (id: any, payload: any) => {
  //   try {
  //     const res = await apinew.post(
  //       `api/OrderPayout/Reports/Admin/Convictional?AccountId=${id}`,
        
  //       payload,
  //       { responseType: "blob" }
  //     );
  //     if (res?.status == 200) {
  //       return res;
  //     }
  //   } catch (error: any) {
  //     setMessageText(error?.response?.data?.result);
  //   }
  // };
  // const ExportPayoutReportbyDCIDConvictionalAdmin = async (id: any, payload: any) => {
  //   try {
  //     const res = await apinew.post(
  //       `/api/OrderPayout/Reports/Admin/Convictional/Export?AccountId=${id}`,        
  //       payload,
  //       { responseType: "blob" }
  //     );
  //     if (res?.status == 200) {
  //       return res;
  //     }
  //   } catch (error: any) {
  //     setMessageText(error?.response?.data?.result);
  //   }
  // };
  return {
    messageText,
    setMessageText,
    topSellingCategory,
    topSellingBrands,
    setClientData,
    clientData,
    getSummarydatabyDCIDAPI,
    getDetailedPayoutReport,
    ExportPayoutReport,
    getSummarydataAPI,
    getClientsListForPayoutReport,
    getDetailedPayoutReportbyDCID,
    ExportSummeryPayoutReport,
    getTotalSalesData,
    ExportPayoutReportbyDCID,
    uploadSummaryCSVAPI,
    uploadSalesCSVAPI,
    uploadReturnsCSVAPI,
    getDetailedPayoutReportbyDCIDConvictional,
    uploadSummaryCSVAPIConvictional,
    // ExportPayoutReportbyDCIDConvictional,
    // ExportPayoutReportbyDCIDConvictionalAdmin
  } as const;
};
