import { Box, Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"
import HeaderListing from "../../../components/headerListing"
import styles from "./index.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import AmazonImg from "../../../assets/images/form/amazon.png";
import ProdIdentifier from "../../../assets/images/form/product-identifier.png";
import Barcode from "../../../assets/images/form/barcode.png";
import Description from "../../../assets/images/form/prod-description.png";
import ProductDetails from "../../../assets/images/form/prod-details.png";
import Color from "../../../assets/images/form/color.png";
import Size from "../../../assets/images/form/size.png";

const SetUpTool = () => {
    const isMobile = useMediaQuery("(max-width:576px)");

    
    let navigate = useNavigate()
    return (
        <>
            <HeaderListing />
            <Stack flexGrow={1}>
                <Box
                    className={styles.contentMain}
                    component="main"
                    sx={{
                        flexGrow: 1,
                        backgroundColor: "#fff",
                        pt: 2.5,
                        pb:5,
                        px: isMobile? 2 :5,
                        height: 'unset'
                    }}
                >
                    <Box display="flex" justifyContent="space-between" mb={1.5} alignItems='center'>

                        <Typography
                            variant="h4"
                            fontSize={20}
                            fontWeight={500}
                            color="#000"
                        >
                            Set Up Form - FAQ
                        </Typography>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => navigate(-1)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Stack sx={{ background: '#C8D6F8', p: 1, width: 'max-width' }}>
                            <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} >Listing Title. Best practices: Brand, Product, Specs, Color or Size, Packs (if applicable)</Typography>
                        </Stack>
                        <img src={AmazonImg} style={{ width: '100%' }} />
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <Box>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>SKU: Product Identifier</Typography>
                        <img src={ProdIdentifier} style={{ width: '100%',maxWidth:193 }}/>
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <Box>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>UPC (Barcode):</Typography>
                        <img src={Barcode} style={{ width: '100%',maxWidth:120 }}/>
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <Box sx={{border: "1px solid #E0E0E0",px:2.5,py:2.5,background:"#FAFBFC",boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.05)"}}>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>Bullet Points: Best practices: 5 bullet points with clear description and details of the product.</Typography>
                       
                        <img src={ProductDetails} style={{ width: '100%',maxWidth: 310 }}/>
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <Box sx={{border: "1px solid #E0E0E0",px:2.5,py:2.5,background:"#FAFBFC",boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.05)"}}>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>Description</Typography>
                       <img src={Description} style={{width:'100%',maxWidth:980}}/>    
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <Box sx={{border: "1px solid #E0E0E0",px:2.5,py:2.5,background:"#FAFBFC",boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.05)"}}>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>Does it vary? Please specify</Typography>
                        <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>Color</Typography>
                       <img src={Color} style={{width:'100%',maxWidth:200}}/>    
                       <Typography variant="h6" fontSize= {isMobile? 16 : 20} fontWeight={500} color='#000000'>Size</Typography>
                       <img src={Size} style={{ width: '100%',maxWidth:360}}/>
                    </Box>
                </Box>
            </Stack>
            

        </>
    )
}
export default SetUpTool