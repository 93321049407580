import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import DateRangePicker from "rsuite/DateRangePicker";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as ThreeDot } from "../../../assets/images/icons/threeDot.svg";
import { ReactComponent as ImportIcon } from "../../../assets/images/icons/importIcon.svg";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import ErrorModal from "../../../components/errorModal/ErrorModal";
import HeaderListing from "../../../components/headerListing";
import { useSKuFormAPI } from "../../../shared/api/useSkuFormSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import {
  style,
  style as styless,
} from "../../listingScreens/superAdminDashboard/index";
import Pagination from "../paginationListing";
import EditSkuForm from "./EditSkuForm";
import FormDetails from "./FormDetails";
import ImportExcel from "./ImportExcel";
import ScanUPC from "./ScanUPC";
import styles from "./index.module.scss";
import DeletePopUp from "../IntegraionsDashbaord/deletePopUp";
type Anchor = "right";

export const moreIconStyle = {
  filterContainer: {
    top: 8,
    pt: "0px",
    ".MuiMenu-paper": {
      border: "1px solid red",
      borderRadius: 0.5,
      borderColor: "#E0E0E0",

      // left: "272px !important",

      width: 175,
      // ".MuiList-root": {
      //   py: 0,
      //   ".MuiListItem-root":{

      ".MuiListItemButton-root": {
        borderLeft: "5px solid transparent",
        "&:hover": {
          backgroundColor: "#C8D6F8",
          borderLeft: "5px solid #4079FC",
        }
      },
      // },

      // },
      ".MuiAccordionSummary-root": {
        minHeight: 0,
        py: 1,
        px: 1.5,
        ".MuiAccordionSummary-content": {
          m: 0,
        },
      },
      ".MuiAccordionDetails-root": {
        ".MuiTypography-body1": {
          px: 1.5,
          py: 1,
          fontSize: 14,
          fontWeight: 300,
          ".MuiCheckbox-root": {
            p: 0,
          },
          "&:hover": {
            backgroundColor: "#EFEFEF",
            borderLeft: "2px solid #4079FC",
          },
        },
      },
    },
  },
};

const Index = () => {
  const { skuDashboardData, exportSkuFormDetails, exportSkuFormDetailsPoshmark, deleteSkuFormRecord,
    exportSkuFormDetailstoCSV, categoryList, getSizeList } = useSKuFormAPI();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isTablet = useMediaQuery("(max-width:992px)");
  const isMedium = useMediaQuery("(min-width:768px) and (max-width: 785px)");
  const [page, setPage] = useState<any>(0);
  const [formModal, setFormModal] = useState<any>(false);
  const [id, setId] = useState<any>("");

  const [scanUPCFormModal, setScanUPCFormModal] = useState<any>(false);
  const [showImportModal, setShowImportModal] = useState<any>(false);
  let navigate = useNavigate();
  const [prefix, setPrefix] = useState("");
  const [tableData, setTableData] = useState<any>({
    count: 0,
    items: [],
  });
  const [dateFilter, setDateFilter] = useState<any>([]);


  const subtractMonths = (date: any, months: any) => {
    date.setMonth(date.getMonth() - months);
    return date;
  };
  const date = new Date();
  const newDate = subtractMonths(date, 1);

  const initialPayload = {
    fromDate: dateFilter?.length ? moment(dateFilter[0]).format("MM-DD-YYYY") : moment(newDate).format("MM-DD-YYYY"),
    toDate: dateFilter?.length ? moment(dateFilter[1]).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY"),
    limit: 50,
    offset: page,
  };

  const getTableData = async (prefix?: any) => {
    try {
      const payload = {
        ...initialPayload,
        prefix: prefix ? prefix : "",
      };
      const res: any = await skuDashboardData(payload);
      if (res.status === 200) {
        setTableData(res.data.result);
      }
    } catch (err) { }
  };
  const [categoryListData, setCategoryListData] = useState<any>([])
  const getCategoryList = async () => {
    const result = await categoryList()
    setCategoryListData(result?.data?.result)
  }
  const [sizeListData, setSizeListData] = useState<any>([])
  const getSizeListCall = async () => {
    const list = await getSizeList()
    console.log("Size", list?.data?.result)
    setSizeListData(list?.data?.result)
  }
  const uniqueDepartments: any = Array.from(new Set(categoryListData?.map((item: any) => item.department)));
  useEffect(() => {
    getTableData();
    setSelectedIndex(15);
    getCategoryList()
    getSizeListCall()
  }, [page, dateFilter]);

  const { errorModalShow, setErrorModalShow, setSelectedIndex, } = useAuth();

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    );
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      getTableData(prefix);
    }
  };

  const list = () => {
    return (
      <EditSkuForm
        id={id}
        toggleDrawer={toggleDrawer}
        getTableData={getTableData}
        categoryListData={categoryListData}
        uniqueDepartments={uniqueDepartments}
        setSizeListData={setSizeListData}
        sizeListData={sizeListData}
      />
    );
  };

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setState({ ...state, [anchor]: open });
  };
  const DescriptionTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#1A1918",
      minWidth: 230,
      maxWidth: 230,
      fontSize: 14,
      fontWeight: 300,
      border: "1px solid #e0e0e0",
      letterSpacing: "normal",
    },
  }));
  const TitleTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#1A1918",
      minWidth: 140,
      maxWidth: 140,
      fontSize: 14,
      fontWeight: 300,
      border: "1px solid #e0e0e0",
      letterSpacing: "normal",
    },
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const openFilter1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const dcid = localStorage.getItem("dcid")
  const exportExcel = async () => {
    console.log("Export Channel")
    const res = await exportSkuFormDetails(dcid, []);
    if (res?.status === 200) {
      var csvdata = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });
      var csvURL = window.URL.createObjectURL(csvdata);
      let tempLink;
      tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", `Channel_File.xlsx`);
      tempLink.click();
      handleClose1();
    }
  }
  const exportPoshmark = async () => {
    const res = await exportSkuFormDetailsPoshmark(dcid, []);
    if (res?.status === 200) {
      var csvdata = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      });
      var csvURL = window.URL.createObjectURL(csvdata);
      let tempLink;
      tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "Poshmark_File.xlsx");
      tempLink.click();
      handleClose1();
    }
  }
  const exportExcelCSV = async () => {
    const res = await exportSkuFormDetailstoCSV([]);
    if (res?.status === 200) {
      const blob = new Blob([res?.data], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'SkuFormDetails_File.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [deleteRecordId, seteleteRecordId] = useState<any>(null);
  const handleOpenPopUp = (id: any) => {
    seteleteRecordId(id);
    setOpenDeletePopUp(true);
  }
  const deleteRecord = async () => {
    const res: any = await deleteSkuFormRecord(deleteRecordId);
    if (res.status === 200) {
      getTableData();
      setOpenDeletePopUp(false);
    }
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <>
      {openDeletePopUp && <DeletePopUp header={"Record"} open={openDeletePopUp} setOpen={setOpenDeletePopUp} handleDeleteUser={deleteRecord} />}
      {(openFilter && isMobile) &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFilter}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={moreIconStyle.filterContainer}
        >
          <Paper elevation={0} sx={{ width: "100%", minWidth: 240, top: 158, pt: 0 }}>
            <ListItemButton
              sx={{
                pl: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
              onClick={() => {
                navigate("/sku-setup-tool")
              }}
            >
              <ListItemText
                sx={{ flexGrow: 0, }}
                primary="Form Set Up Tool"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                py: 0,
                pl: 1.5,
                minHeight: 32,
                justifyContent: "space-between",

              }}
              onClick={() => {
                setShowImportModal(true)
              }}
            >
              <ListItemText
                sx={{ flexGrow: 0, py: 1 }}
                primary="Import Excel"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>
          </Paper>
        </Menu >
      }

      {(openFilter1) &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl1}
          open={openFilter1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ ...moreIconStyle.filterContainer, ".MuiList-root": { p: 0 }, }}
        >
          <Paper elevation={0} sx={{ width: "100%" }}>
            <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <Stack direction="row" alignItems="center" gap={1.5}>
                <FileUploadOutlinedIcon />

                <ListItemText
                  sx={{ flexGrow: 0, }}
                  primary="Export"
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: "medium",
                  }}
                />
              </Stack>
            </ListItemButton>
            <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <ListItemText
                sx={{ flexGrow: 0, }}
                primary="Export As"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>

            <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
              onClick={() => exportPoshmark()}
            >
              <ListItemText
                sx={{ flexGrow: 0, }}
                primary="Poshmark"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>

            <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
              onClick={() => exportExcel()}
            >
              <ListItemText
                sx={{ flexGrow: 0, }}
                primary="ChannelAdvisor"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
              }}
              onClick={() => exportExcelCSV()}
            >
              <ListItemText
                sx={{ flexGrow: 0, }}
                primary="Export CSV"
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>
            {/* <ListItemButton
              sx={{
                p: 1.5,
                minHeight: 32,
                justifyContent: "space-between",

              }}
              onClick={() => {
                setShowImportModal(true);
                handleClose1();
              }}
            >
              <Stack direction="row" alignItems="center" gap={1.5}>
                <ImportIcon />
                <ListItemText
                  sx={{ flexGrow: 0, ".MuiListItemText-root": { padding: 0 } }}
                  primary="Import Excel"
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: "medium",
                  }}
                />
              </Stack>
            </ListItemButton> */}
          </Paper>
        </Menu >
      }

      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        sx={{
          ".MuiModal-backdrop": { left: 60 },
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: 936,
            pt: 4,
            pb: 6,
            zIndex: 1,
          },
        }}
      >
        {list()}
      </Drawer>

      {
        formModal && (
          <FormDetails
            setFormModal={setFormModal}
            formModal={formModal}
            updateTableData={getTableData}
            categoryListData={categoryListData}
            uniqueDepartments={uniqueDepartments}
            sizeListData={sizeListData}
            setSizeListData={setSizeListData}
          />
        )
      }
      <ScanUPC
        setScanUPCFormModal={setScanUPCFormModal}
        scanUPCFormModal={scanUPCFormModal}
      ></ScanUPC>
      {
        showImportModal && (
          <ImportExcel
            showImportModal={showImportModal}
            setShowImportModal={setShowImportModal}
            fetchData={getTableData}
          />
        )
      }
      {errorHandle()}

      <HeaderListing />

      <Box
        className={styles.contentMain}
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
          // py: 2.5,
          // px: 5,
          height: "unset",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            py: 2.5,
            px: 2.5,
          }}
        >
          <Stack display={isTablet ? "flex" : "none"} mb={2}>
            <Typography
              variant="h4"
              fontSize={20}
              fontWeight={500}
              color="#000"

            >
              Product Details
            </Typography>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"

          >
            <Stack direction={isMobile ? "column" : "row"} alignItems="center">
              <Typography
                variant="h4"
                fontSize={20}
                fontWeight={500}
                color="#000"
                display={isTablet ? "none" : "flex"}
              >
                Product Details
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                className={`${styles.searchInput}`}
                sx={{ height: 40, display: isMobile ? "none" : isTablet ? "flex" : "none", mr: 2, width: 120 }}

              >
                <Search className={styles.searchIcon} />
                <input
                  type="search"
                  placeholder="Search"
                  value={prefix}
                  onKeyDown={(event) => handleKeypress(event)}
                  onChange={(event) => setPrefix(event.target.value)}
                  style={{ width: "100%" }}
                  className={styles.customSearchInput}
                />
                {prefix && (
                  <CloseIcon
                    onClick={async () => {
                      setPrefix("");
                      getTableData("");
                    }}
                  />
                )}
              </Stack>
              <Stack
                justifyContent="center"
                className={styles.datepickerWrapper}
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: 0.5,
                  height: 40,
                  px: "10px",

                  alignItems: "center",
                  ml: isTablet || isMobile ? 0 : 2,
                  mr: isTablet || isMobile ? 2 : 0,


                }}
              >
                <DateRangePicker
                  size="md"
                  cleanable={false}
                  format="MM-dd-yyyy"
                  value={dateFilter}
                  placeholder={["Start Date", "-", "End Date"]}
                  onChange={(e) => setDateFilter(e)}
                  className={styles.dateRangePicker}
                  showOneCalendar={isMobile ? true : false}
                />

              </Stack>
            </Stack>
            <Box display="flex">
              <Stack
                display={isTablet ? "none" : "flex"}
                direction="row"
                alignItems="center"
                className={`${styles.searchInput}`}
                sx={{ height: 40 }}
              >
                <Search className={styles.searchIcon} />
                <input
                  type="search"
                  placeholder="Search"
                  value={prefix}
                  onKeyDown={(event) => handleKeypress(event)}
                  onChange={(event) => setPrefix(event.target.value)}
                  style={{ width: "100%" }}
                  className={styles.customSearchInput}
                />
                {prefix && (
                  <CloseIcon
                    onClick={async () => {
                      setPrefix("");
                      getTableData("");
                    }}
                  />
                )}
              </Stack>
              {/* <Button
                            variant="contained"
                            sx={{ ...style.fiiledBtn }}
                            onClick={() => setScanUPCFormModal(true)}
                        >
                            Scan UPC
                        </Button> */}
              <Button
                variant="contained"
                sx={{ ...styless.fiiledBtn, ml: isTablet ? 0 : 2, height: 40, textWrap: "nowrap", px: isMedium ? 1 : 2 }}
                onClick={() => setFormModal(true)}
              >
                <AddIcon sx={{ mr: "6px" }} />
                Add New
              </Button>
              <IconButton id="basic-menu" aria-label="threeDot" aria-controls={openFilter ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilter ? 'true' : undefined} sx={{ display: isMobile ? "flex" : "none", height: 40 }} onClick={handleClick}>
                <ThreeDot id="basic-menu" />
              </IconButton>

              <Button
                variant="contained"
                sx={{

                  ...styless.fiiledBtn,
                  ...style.outlineNewBtn,
                  color: "#68737D",
                  textWrap: "nowrap",
                  display: isMobile ? "none" : "flex",
                  px: isMedium ? 1 : 2,



                }}
                onClick={() => navigate("/sku-setup-tool")}
              >
                Form Set Up Tool
              </Button>


              {/* <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  display: isMobile ? "none" : "flex",
                  textWrap: "nowrap",
                  padding: "6px 8px",
                  mr: isMedium ? 2.5 : 0,
                  "&:hover": {
                    backgroundColor: "#EFEFEF",                
                  },
                }}
                onClick={() => setShowImportModal(true)}
              >
                <FileUploadOutlinedIcon sx={{ marginRight: "10px" }} />
                Import Excel
              </Button> */}

              <Button
                variant="contained"
                sx={{
                  ...styless.fiiledBtn,
                  ...styless.outlineNewBtn,
                  color: "#68737D",
                  display: isMobile ? "none" : "flex",
                  textWrap: "nowrap",
                  padding: "6px 8px",
                  mr: isMedium ? 2.5 : 0,
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
                onClick={handleClick1}
              >
                File
                <ExpandMoreIcon />
              </Button>
            </Box>

          </Box>

          <Stack
            direction="row"
            alignItems="center"
            className={`${styles.searchInput}`}
            sx={{ display: isMobile ? "flex" : "none", mt: 1, width: "100%" }}

          >
            <Search className={styles.searchIcon} />
            <input
              type="search"
              placeholder="Search"
              value={prefix}
              onKeyDown={(event) => handleKeypress(event)}
              onChange={(event) => setPrefix(event.target.value)}
              style={{ width: "100%" }}
              className={styles.customSearchInput}
            />
            {prefix && (
              <CloseIcon
                onClick={async () => {
                  setPrefix("");
                  getTableData("");
                }}
              />
            )}
          </Stack>

        </Box>

        <Divider></Divider>
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            py: 2.5,
            px: 2.5,
          }}
        >
          <Pagination
            page={page}
            totalCount={tableData?.count}
            setPage={setPage}
          />
          <div className={`${styles.heightG} ${styles.formTable}`}>
            <table className={styles.tableH}>
              <thead className={`${styles.thead} rounded`}>
                <tr>
                  <td></td>
                  <td>Listing Title</td>
                  <td>SKU</td>
                  <td>UPC</td>
                  <td>QTY</td>
                  <td>Description</td>
                  <td>Condition</td>
                  <td>Manufacturer</td>
                  <td>Brand</td>
                  <td align="right">Retail Price</td>
                  <td>Error Logs</td>
                  <td>Status</td>
                  <td>Actions</td>
                </tr>
              </thead>
              {tableData?.count ? (
                tableData.products?.map((ele: any, index: number) => (
                  <tbody className={styles.tbody}>
                    <tr>
                      <td>{page + (index + 1)}</td>
                      <td>
                        <TitleTooltip
                          title={ele.title}
                          placement="top"
                          arrow
                        >
                          <Typography
                            variant="body2"
                            fontSize={14}
                            fontWeight={300}

                            sx={{
                              whiteSpace: "nowrap",
                              width: "140px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {ele.title}
                          </Typography>
                        </TitleTooltip>
                      </td>
                      <td>{ele.sku}</td>
                      <td>{ele.upc}</td>
                      <td>{ele.quantity}</td>
                      <td>
                        <DescriptionTooltip
                          title={ele.description}
                          placement="top"
                          arrow
                          sx={{ color: "#1A1918" }}
                        >
                          <Typography
                            variant="body2"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                              whiteSpace: "nowrap",
                              width: "230px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {ele.description}
                          </Typography>
                        </DescriptionTooltip>
                      </td>
                      <td>{ele.condition}</td>
                      <td>{ele.manufacturer}</td>
                      <td>{ele.brand}</td>
                      <td align="right">{formatter.format(ele.maximumPrice)}</td>
                      <td>{ele.comments}</td>
                      <td>{ele.scanStatus}</td>
                      <td>
                        <Tooltip title="Edit" placement="top" arrow>
                          <IconButton
                            aria-label="edit"
                            sx={{ mr: 0.2 }}
                            onClick={() => {
                              setId(ele.id);
                              toggleDrawer("right", true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            aria-label="delete"
                            sx={{ mr: 0.2 }}
                            onClick={() => {
                              setId(ele.id);
                              handleOpenPopUp(ele.id)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr className={styles.noRecordRow}>
                  <td colSpan={6}>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      fontSize={14}
                      fontWeight={300}
                    >
                      No records found
                    </Typography>
                  </td>
                </tr>
              )}
            </table>
          </div>
        </Box>
      </Box>
      {/* </Stack > */}
    </>
  );
};
export default Index;

function uploadExcel(formData: FormData) {
  throw new Error("Function not implemented.");
}
