import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MessagePopup from "../MessagePopop";
import HeaderListing from "../../../components/headerListing";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as Link } from "../../../assets/images/icons/link.svg";
import { ReactComponent as CloseChip } from "../../../assets/images/icons/tag-button.svg";
import { ReactComponent as CloseChipWhite } from "../../../assets/images/icons/tag-button-white.svg";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useIntegrationsSAAS } from "../../../shared/api/useIntegrationsSAAS";
import CustomBreadcrumbs from "../../../components/customBreadcrumbs";
import shopifyIntegration from "../../../assets/images/shopify-integration.svg";
import ConnectorImg from "../../../assets/images/connector-img.svg";
import { useLocation } from "react-router-dom";
// import {style as styless} from "../../listingScreens/IntegraionsDashbaord/StepsToIntegrate";

export const style = {
  switch: {
    width: 34,
    height: 14,
    padding: "6px",
    overflow: "visible",
    ".MuiButtonBase-root": {
      "&.MuiSwitch-switchBase": {
        top: "-4px",
        color: "#68737D",
        "&.Mui-checked": {
          color: "#4F18FF",
        },

        "&.Mui-checked+.MuiSwitch-track": {
          backgroundColor: "#A0A7AD",
          opacity: 1,
        },
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
    ".MuiSwitch-track": {
      backgroundColor: "#A0A7AD",
      opacity: 1,
    },
    ".MuiSwitch-thumb": {
      width: 14,
      height: 14,
      boxShadow: "unset",
    },
  },
  switchBox: {
    borderRadius: 0.5,
    border: "1px solid #E0E0E0",
    px: 1.5,
    height: 40,
    ".MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
      color: "#000",
    },
  },
  greyBox: {
    borderRadius: 0.5,
    backgroundColor: "#EFEFEF",
    p: 2.5,
    ".MuiInputLabel-root": {
      fontSize: 14,
      fontWeight: 500,
      color: "#1A1918",
      mb: 1,
    },
    ".MuiInput-root": {
      p: "10px",
      "&:before": {
        borderBottom: "2px solid #E0E0E0",
      },
      "&::placeholder": {
        color: "red",
      },
    },
  },
  saveCancelBtn: {
    width: "100%",

    px: 2,
    py: 1.5,
    borderRadius: 0.5,
    boxShadow: "none",
    fontSize: 14,
    fontWeight: 300,
    textTransform: "capitalize",
    height: 34,
  },
  chipBox: {
    height: 30,
    border: "1px solid #B2B7BC",
    color: "#68737D",
  },
};

const AddIntegrationScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280));
  const isTablet = !isMobile && !isDesktop;
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
  const userId = localStorage.getItem("userId");
  const [tags, setTags] = useState<string[]>([]);
  const navigate = useNavigate();
  const { name } = useParams();
  const { postNewIntegrationData, getIndividualData, getIntegrationsAccountsList, integrationsAccountsList, putSourceIntegration } = useIntegrationsSAAS();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    token: Yup.string().required("Required").nullable(),
    apiKey: Yup.string().required("Required").nullable(),
    apiSecret: Yup.string().required("Required").nullable(),
    url: Yup.string().required("Required").nullable(),
    // tags: Yup.object().required("Required").nullable(),
    tags: Yup.array().test(
      "subject",
      "Required",
      function (values: any) {
        if (!tags.length) {
          return false;
        }
        return true;
      }
    ).nullable(),
    name: Yup.string().required("Required").nullable(),
    shopifyId: Yup.string().required("Required").nullable(),
  });
  const formikAddIntegration: any = useFormik({
    initialValues: {
      token: "",
      apiKey: "",
      apiSecret: "",
      storeKey: "",
      accountId: 0,
      warehouse: "",
      tenantToken: "",
      userToken: "",
      url: "", //domain
      enableGetOrders: true,
      enableGetProducts: true,
      enableShipmentInfo: true,
      enableInventoryInfo: true,
      tags: "",
      ///newly added field ask backend to add
      name: "",
      shopifyId: ""

    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitDataLogin(),
  });
  const submitDataLogin = async () => {
    try {
      const payload = {
        ...formikAddIntegration?.values,
        accountType: integrationsAccountsList?.find((ele: any) => ele.name === name)?.id,
        userId: userId
      }
      const res = location?.state?.id ? await putSourceIntegration(payload) : await postNewIntegrationData(payload);
      if (res?.status == 200) {
        if (location?.state?.id) {
          navigate(`/listing-configure-source-integration/${formikAddIntegration.values.name}`, { state: { id: location?.state?.id, isFirstTime: isFirstTime } })
        } else {
          navigate(`/listing-configure-source-integration/${formikAddIntegration.values.name}`, { state: { id: res.data.result, isFirstTime: isFirstTime } })
        }
      }
    } catch (err) {
    }
  }

  const getAccountList = async () => {
    await getIntegrationsAccountsList();
  }

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
      formikAddIntegration.setFieldValue("tags", tags);
    }
  };

  const handleTagClose = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const [checked, setChecked] = useState(true);
  const [checkedProducts, setCheckedProducts] = useState(true);
  const [checkedShipment, setCheckedShipment] = useState(true);
  const [checkedInventory, setCheckedInventory] = useState(true);
  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    if (value == "Orders") {
      setChecked(event.target.checked);
      formikAddIntegration.setFieldValue("enableGetOrders", event.target.checked);
    } else if (value == "Products") {
      setCheckedProducts(event.target.checked);
      formikAddIntegration.setFieldValue("enableGetProducts", event.target.checked);
    }
    // enableShipmentInfo
    else if (value == "Shipment") {
      setCheckedShipment(event.target.checked);
      formikAddIntegration.setFieldValue("enableShipmentInfo", event.target.checked);
    } else if (value == "Inventory") {
      setCheckedInventory(event.target.checked);
      formikAddIntegration.setFieldValue("enableInventoryInfo", event.target.checked);
    }
  };


  const location = useLocation();
  const getData = async () => {
    try {
      const res: any = await getIndividualData(location?.state?.id);
      console.log(res);
      if (res?.status === 200) {
        if (res?.status === 200) {
          formikAddIntegration.setFieldValue("name", res.data?.result.name);
          formikAddIntegration.setFieldValue("shopifyId", res.data?.result.shopifyId);
          formikAddIntegration.setFieldValue("apiKey", res.data?.result.apiKey);
          formikAddIntegration.setFieldValue("token", res.data?.result.token);
          formikAddIntegration.setFieldValue("apiSecret", res.data?.result.apiSecret);
          formikAddIntegration.setFieldValue("url", res.data?.result.url);
          formikAddIntegration.setFieldValue("tags", res.data?.result.tags ? [res.data?.result.tags?.split(",")] : null);
          setTags(res.data?.result.tags?.split(","))
          setChecked(res.data?.result.enableGetOrders);
          setCheckedInventory(res.data?.result.enableInventoryInfo);
          setCheckedShipment(res.data?.result.enableShipmentInfo);
          setCheckedProducts(res.data?.result.enableGetProducts);
          formikAddIntegration.setFieldValue("enableGetOrders", res.data?.result.enableGetOrders);
          formikAddIntegration.setFieldValue("enableShipmentInfo", res.data?.result.enableShipmentInfo);
          formikAddIntegration.setFieldValue("enableInventoryInfo", res.data?.result.enableInventoryInfo);
          formikAddIntegration.setFieldValue("enableGetProducts", res.data?.result.enableGetProducts);
        }
      }
    } catch (err) {
    }
  }

  useEffect(() => {
    if (location?.state?.id) {
      getData();
    }
  }, [location?.state?.id])

  useEffect(() => {
    if (location?.state?.isFirstTime) {
      setIsFirstTime(location?.state?.isFirstTime)
    }
  }, [location?.state?.isFirstTime])

  useEffect(() => {
    getAccountList();
  }, [])


  return (
    <>
      {/* <MessagePopup /> */}
      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#fff",
        }}
      >
        <Stack
          px={1.5}
          py={2}
          justifyContent="center"
          sx={{
            backgroundColor: "#FAFBFC",
            borderBottom: "1px solid #E0E0E0",
            height: 44,
          }}
        >
          <CustomBreadcrumbs
            firstName={"Integrations"}
            firstFunction={-2}
            secondName={name}
          />
        </Stack>
        <Box
          p={isMobile ? 2 : isTablet ? 3 : 0}
        // height={isDesktop ? "calc(100vh - 104px)" : "unset"}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            border={isTablet ? "1px solid #E0E0E0" : "unset"}
          // height={isDesktop ? "calc(100vh - 104px)" : "unset"}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: isMobile ? "unset" : 240,
                px: 2.5,
                py: isDesktop ? 5 : 2.5,
                mb: isMobile ? 2.5 : 0,
                border: isMobile ? "1px solid #E0E0E0" : "unset",
              }}
            >
              <Box
                display={isMobile ? "flex" : "unset"}
                justifyContent="space-between"
              >
                <Stack mr={isMobile ? "30px" : 0} alignItems="center">
                  <img
                    src={shopifyIntegration}
                    style={{ width: "100%", maxWidth: isMobile ? 100 : 150 }}
                  />
                </Stack>
                <Box>
                  <Typography
                    variant="h6"
                    fontSize={18}
                    fontWeight={500}
                    color="#000"
                    mt={isMobile ? 0 : 5}
                    mb={2.5}
                  >
                    Integration Capability
                  </Typography>
                  <Stack rowGap={4}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={style.switchBox}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        Orders
                      </Typography>
                      <Switch
                        sx={style.switch}
                        size="small"
                        checked={checked}
                        onChange={(e: any) => handleChangeSwitch(e, "Orders")}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={style.switchBox}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        Products
                      </Typography>
                      <Switch
                        sx={style.switch}
                        size="small"
                        checked={checkedProducts}
                        onChange={(e: any) => handleChangeSwitch(e, "Products")}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={style.switchBox}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        Shipment
                      </Typography>
                      <Switch
                        sx={style.switch}
                        size="small"
                        checked={checkedShipment}
                        onChange={(e: any) => handleChangeSwitch(e, "Shipment")}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={style.switchBox}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color="#000"
                      >
                        Inventory
                      </Typography>
                      <Switch
                        sx={style.switch}
                        size="small"
                        checked={checkedInventory}
                        onChange={(e: any) =>
                          handleChangeSwitch(e, "Inventory")
                        }
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>

            {/* {isMobile ? <Divider></Divider> : <></>} */}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                backgroundColor: "#fff",
                py: isMobile ? 0 : 2.5,
                px: isMobile ? 0 : isTablet ? 2.5 : 5,
                borderLeft: isMobile ? "none" : "1px solid #E0E0E0",
              }}
            >
              <FormikProvider value={formikAddIntegration}>
                <Stack direction="row" alignItems="center" mb={2}>
                  <Chip
                    label="1"
                    sx={{
                      fontSize: 18,
                      width: 22,
                      height: 22,
                      ".MuiChip-label": { px: 0.5 },
                    }}
                  />
                  <Typography
                    ml={1.5}
                    fontSize={18}
                    fontWeight={500}
                    color="#000"
                  >
                    Authentication
                  </Typography>
                </Stack>
                <Box sx={style.greyBox}>
                  <Grid container rowGap="30px" columnSpacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required> Integration Name</InputLabel>
                      <Field name="name">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="Integration Name"
                            {...field}
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>Shopify Id</InputLabel>
                      <Field name="shopifyId">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="Shopify Id"
                            {...field}
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required> Admin Token</InputLabel>
                      <Field name="token">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="Paste Admin Token here"
                            {...field}
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>API Key</InputLabel>
                      <Field name="apiKey">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            {...field}
                            placeholder="Enter API Key here"
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                      {formikAddIntegration?.error?.apiKey}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required> API Secret</InputLabel>
                      <Field name="apiSecret">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="apiSecret"
                            {...field}
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>URL</InputLabel>
                      <Field name="url">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="domain"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Link />
                                </InputAdornment>
                              ),
                            }}
                            sx={{ ".MuiInput-root": { pl: 0 } }}
                            {...field}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt="30px">
                  <Stack direction="row" alignItems="baseline" mb={2}>
                    <Chip
                      label="2"
                      sx={{
                        fontSize: 16,
                        width: 22,
                        height: 22,
                        ".MuiChip-label": { px: 0.5 },
                      }}
                    />
                    <Stack ml={1.5}>
                      <Typography fontSize={18} fontWeight={500} color="#000">
                        Create Product Tag
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize={14}
                        fontWeight={500}
                        color="#68737D"
                      >
                        Product tags are automatically assigned to products.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={isDesktop ? "row" : "column"}>
                    <Box sx={{ ...style.greyBox, flex: 1, pb:isMobile? 0:2.5}}>
                      <InputLabel required>Product Tags</InputLabel>
                      <Field name="tags">
                        {({ field, meta }: any) => (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            required
                            fullWidth
                            placeholder="Type product tag and press enter"
                            {...field}
                            // sx={style.textField}
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                          />
                        )}
                      </Field>
                    </Box>
                    {isDesktop ? (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          mx: 1,
                          borderWidth: 1,
                          borderColor: "#E0E0E0",
                          opacity: 1,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <Box sx={{ ...style.greyBox, flex: 1 }}>
                      {tags?.length > 0 ? (
                        <Chip
                          sx={{
                            ...style.chipBox,
                            background:
                              "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                            color: "#fff",
                            borderColor: "#0038BA",
                            mr: isDesktop ? 2.5 : 0.5,
                            mb: isDesktop ? 1 : 0.5,
                          }}
                          label={"all"}
                          variant="outlined"
                          onDelete={() => setTags([])}
                          style={{ width: "fit-content" }}
                          deleteIcon={<CloseChipWhite />}
                        />
                      ) : (
                        <></>
                      )}

                      {tags?.map((tag) => (
                        <Chip
                          sx={{
                            ...style.chipBox,
                            mr: isDesktop ? 2.5 : 0.5,
                            mb: isDesktop ? 1 : 0.5,
                          }}
                          key={tag}
                          label={tag}
                          variant="outlined"
                          onDelete={() => handleTagClose(tag)}
                          style={{ width: "fit-content" }}
                          deleteIcon={<CloseChip />}
                        />
                      ))}
                    </Box>
                  </Stack>
                  {/* </Stack> */}
                </Box>

                <Stack gap={2.5} direction={isMobile ? 'column' : "row"} justifyContent="end" mt={4}>
                  <Button
                    variant="contained"
                    sx={{
                      ...style.saveCancelBtn,
                      maxWidth: isMobile ? 'unset' : 136,
                      background:
                        "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                    }}
                    onClick={async () => {
                      await formikAddIntegration.setFieldValue("tags", tags);
                      formikAddIntegration.handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      ...style.saveCancelBtn,
                      maxWidth: isMobile ? 'unset' : 136,
                      border: "1px solid rgba(104, 115, 125, 0.48)",
                      color: "#68737D",
                    }}
                    onClick={async () => {
                      formikAddIntegration.resetForm();
                      setTags([]);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </FormikProvider>

              {/* <Box
                sx={{
                  alignItems: 'center',
                  px: '30px',
                  py: 1.5,
                  backgroundColor: "#C8D6F8",
                  mt: "30px",
                  display: "flex",
                }}
              >
                <figure style={{ marginBottom: 0 }}>
                  <img src={ConnectorImg} />
                </figure>
                <Stack ml={3}>
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={500}
                    color="#004EFF"
                    mb={1.5}
                  >
                    You’re almost ready to set up your destination!
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={500}
                    color="#004EFF"
                  >
                    Select your second connector.
                  </Typography>
                </Stack>
              </Box> */}
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default AddIntegrationScreen;
