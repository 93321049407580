import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as Cross } from '../../../assets/images/icons/cross.svg';
import { ReactComponent as Square } from '../../../assets/images/icons/square.svg';
import { ReactComponent as Check } from '../../../assets/images/icons/check.svg';
import styles from "./index.module.scss";
const Index = (props: any) => {
    const { data, setOpenDrawer } = props;

    const tableData = [
        { name: "Globex Corporation", cost: "$42/ monthly" },
        { name: "Soylent Corp", cost: "$453.6/ annually" },
        { name: "Initech", cost: "$458.2 annually" },
        { name: "Acme Corporation", cost: "$458.2 annually" },
        { name: "Vehement Capital Partners", cost: "$458.2 annually" },
    ]

    return (

        <Box>
            <Box display='flex' alignItems='center' mb={2}>
                <Typography display='flex' alignItems='center' variant="body1" fontSize={18} fontWeight={600} color='#1A1918' sx={{ lineHeight: 'normal' }}><Square style={{ marginRight: '10px' }} />Support</Typography>
                <IconButton aria-label="close" sx={{ ml: 'auto', height: 34, width: 34 }}>
                    <Cross onClick={() => setOpenDrawer(false)} />
                </IconButton>
            </Box>
            <Box sx={{ borderRadius: 0.5, backgroundColor: '#F5F5F5' }} display='grid' rowGap={1.5} p={2} mb={2}>
                <Typography display='flex' alignItems='center' variant="body2" fontSize={14} fontWeight={300}><Check style={{ marginRight: '6px' }} />Lorem ipsum dolor sit amet, consectetul. </Typography>
                <Typography display='flex' alignItems='center' variant="body2" fontSize={14} fontWeight={300}><Check style={{ marginRight: '6px' }} />Lorem ipsum dolor sit amet, consectetul. </Typography>
                <Typography display='flex' alignItems='center' variant="body2" fontSize={14} fontWeight={300}><Check style={{ marginRight: '6px' }} />Lorem ipsum dolor sit amet, consectetul. </Typography>
            </Box>
            <Box sx={{ borderRadius: 0.5, backgroundColor: '#F5F5F5' }} p={2}>
                <table className={styles.subscriptionTable}>
                    <thead className={styles.subThead}>
                        <tr>
                            <td><Typography variant="body2" fontSize={14} fontWeight={500}>Clients</Typography></td>
                            <td><Typography variant="body2" fontSize={14} fontWeight={500}>Billing</Typography></td>
                        </tr>
                    </thead>
                    <tbody className={styles.subTbody}>
                        {tableData.map((ele: any) => (<tr>
                            <td><Typography variant="body2" fontSize={14} fontWeight={300}>{ele.name}</Typography></td>
                            <td><Typography variant="body2" fontSize={14} fontWeight={500}>{ele.cost}</Typography></td>
                        </tr>))}
                    </tbody>
                </table>
            </Box>
        </Box>
    )
}
export default Index;