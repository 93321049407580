import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Stack,
  Switch,
  SwitchProps,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field, FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import * as Yup from "yup";
import { ReactComponent as Active } from "../../../assets/images/icons/active.svg";
import { ReactComponent as StartDateCalendar } from "../../../assets/images/icons/calendar-green-circle.svg";
import { ReactComponent as EndDateCalendar } from "../../../assets/images/icons/calendar-red-circle.svg";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as Due } from "../../../assets/images/icons/due.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style } from "../../listingScreens/superAdminDashboard/index";
import Pagination from "../paginationListing";
import styles from "./index.module.scss";
import SideDrawer from "./sideDrawer";

export const tableStyles = {
  tableData: {
    fontSize: 14,
    fontWeight: 300,
  },
};

const ManageSubscriptionScreen = (props: any) => {
  const [dateFilter, setDateFilter] = useState<any>([]);

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    subscriptionName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    price: Yup.number().required("Required"),
    subscriptionStartDate: Yup.string().required("Required"),
    subscriptionEndDate: Yup.string().required("Required"),
    subscriptionPlanStatusDto: Yup.string().required("Required"),
    billingCycleDto: Yup.number().required("Required"),
  });
  const { isLoading, setMessageText, setOpen } = useAuth();
  const {
    getAllCompanyList,
    getSubscriptionList,
    subscriptionList,
    getSubscriptionById,
    subscriptionByIdNew,
    addNewSubscriptionPlan,
    putSubscriptionPlan,
    deleteSubscription,
  } = useSubScriptionAPI();

  const [prefix, setPrefix] = React.useState("");
  const [limit, setLimit] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [newStartDate, setNewStartDate] = useState<any>(new Date());
  const [newEndDate, setNewEndDate] = useState<any>(
    new Date(moment().add(1, "M").format("MM-DD-YYYY"))
  );
  const [listingUserList, setListingUserList] = useState<any>([]);
  const [editData, setEditData] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [openDrawer, setOpenDrawer] = useState<any>(false);
  const [valuetoDelete, setValuetoDelete] = useState<any>("");

  const [subStatus, setSubStatus] = useState<any>(0);
  interface Item {
    id: string;
    company: string;
  }
  interface MappedItem {
    label: string;
    value: string;
  }
  const mappedData: MappedItem[] = listingUserList?.map(
    (item: Item): MappedItem => ({
      label: item?.company,
      value: item?.id,
    })
  );

  const initialPayload = {
    fromDate: dateFilter?.length
      ? moment(dateFilter[0]).format("MM-DD-YYYY")
      : null,
    toDate: dateFilter?.length
      ? moment(dateFilter[1]).format("MM-DD-YYYY")
      : null,
    limit: limit,
    offset: page,
    prefix: prefix,
    subscriptionStatusList: [subStatus],
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    await getSubscriptionList(initialPayload);
    setPage(0);
  };

  const loadInitialData = async () => {
    const data = await getSubscriptionList(initialPayload);
    const getCompaniesName = await getAllCompanyList();
    setListingUserList(getCompaniesName?.data?.result);
  };
  const initailValuesCreate = {
    userId: "",
    subscriptionName: "",
    description: "",
    price: "",
    subscriptionStartDate: newStartDate,
    subscriptionEndDate: newEndDate,
    subscriptionPlanStatusDto: 0,
    billingCycleDto: 30,
  };

  const formikSubscription: any = useFormik({
    initialValues: props.editState == true ? editData : initailValuesCreate,
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitData(),
  });
  const submitData = async () => {
    console.log("formik", formikSubscription?.values);
    if (props.editState == false) {
      const result = await addNewSubscriptionPlan(formikSubscription?.values);
      if (result.data?.status == 200) {
        props.setShowNewSubscription(false);
        await getSubscriptionList(initialPayload);
        setMessageText("Subscription Added Successfully");
        setOpen(true);
      }
    } else {
      const detailstoPut = {
        description: formikSubscription.values?.description,
        price: formikSubscription.values?.price,
        subscriptionPlanStatusDto: formikSubscription.values?.billingCycleDto,
      };
      const result = await putSubscriptionPlan(
        formikSubscription.values?.id,
        detailstoPut
      );
      if (result.data?.status == 200) {
        props.setShowNewSubscription(false);
        setMessageText("Subscription Edited Successfully");
        await getSubscriptionList(initialPayload);
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
        }, 5000);
      }
    }
    formikSubscription.resetForm();
  };

  /////////////////////////////////////////////////////////modal
  const handleSubscriptionStartDate = (value: any) => {
    formikSubscription.setFieldValue(
      "subscriptionStartDate",
      value.toISOString()
    );
    setNewStartDate(new Date(value));
  };

  const handleSubscriptionEndDate = (value: any) => {
    formikSubscription.setFieldValue(
      "subscriptionEndDate",
      value.toISOString()
    );
    setNewEndDate(new Date(value));
  };
  const [checked, setChecked] = React.useState(true);

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked == true) {
      formikSubscription.setFieldValue("subscriptionPlanStatusDto", 0);
    } else if (event.target.checked == false) {
      formikSubscription.setFieldValue("subscriptionPlanStatusDto", 1);
    }
  };
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      checked={checked}
      onChange={handleChangeSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 25,
    height: 15,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(10px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 10,
      height: 10,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    loadInitialData();
    formikSubscription.resetForm();
  }, [dateFilter, page]);

  const setEditFun = async (value: any) => {
    const result = await getSubscriptionById(value);
    console.log(result?.data?.result, "###");
    formikSubscription.setFieldValue("userId", result?.data?.result?.user?.id);
    formikSubscription.setFieldValue(
      "subscriptionName",
      result?.data?.result?.subscriptionName
    );
    formikSubscription.setFieldValue(
      "description",
      result?.data?.result?.description
    );
    formikSubscription.setFieldValue("price", result?.data?.result?.price);
    formikSubscription.setFieldValue(
      "subscriptionStartDate",
      result?.data?.result?.subscriptionStartDate
    );
    formikSubscription.setFieldValue(
      "subscriptionEndDate",
      result?.data?.result?.subscriptionEndDate
    );
    formikSubscription.setFieldValue(
      "subscriptionPlanStatusDto",
      result?.data?.result?.subscriptionPlanStatusDto
    );
    formikSubscription.setFieldValue(
      "billingCycleDto",
      result?.data?.result?.billingCycleDto
    );
    formikSubscription.setFieldValue(
      "companyName",
      result?.data?.result?.user?.company
    );
    formikSubscription.setFieldValue("id", result?.data?.result?.id);
    props.setShowNewSubscription(true);
    props.setEditState(true);
  };
  const setDeleteFun = async (value: any) => {
    setDeleteModal(true);
    setValuetoDelete(value);
  };
  const handleDeleteSubscription = async () => {
    const result = await deleteSubscription(valuetoDelete);
    if (result.data?.status == 200) {
      setDeleteModal(false);
      setMessageText("Subscription Deleted Successfully");
      await getSubscriptionList(initialPayload);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const fetchData = () => {
    setOpenDrawer(true);
  };

  const styless = {
    control: (base: any, state: any) => ({
      ...base,
      borderWidth: "0px 0px 1px",
      borderRadius: "unset",
      // This line disable the blue border
      boxShadow: "none",
      backgroundColor: "inherit",
      minHeight: 29,
      borderColor: state.isFocused ? "#1976d2" : "#E0E0E0",
    }),
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={1.5}>
        <Stack direction="row">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          </div>
          {/* new code */}
          <Stack
            alignItems="center"
            sx={{
              ...style.borderStack,
              flexDirection: "row",
              ml: 2,
              px: "10px",
            }}
          >
            <Filter style={{ marginRight: "10px" }} />
            <span className={styles.numberWithBg}>4</span>
          </Stack>
          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: 2, px: "10px" }}
          >
            <DateRangePicker
              size="md"
              cleanable={false}
              format="MM-dd-yyyy"
              value={dateFilter}
              placeholder={["Start Date", "-", "End Date"]}
              onChange={(e) => setDateFilter(e)}
              className={styles.dateRangePicker}
            />
          </Stack>
        </Stack>

        <Pagination
          page={page}
          totalCount={subscriptionList?.count ? subscriptionList?.count : 0}
          setPage={setPage}
        />

        {/* new code end*/}
      </Stack>

      <Stack direction="row">
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            pt: 1,
            // pl: isMobile ? 2 : 5,
          }}
        >
          <div className={styles.heightG}>
            <table className={styles.tableH}>
              <thead className={styles.thead}>
                <tr>
                  <td>Sr. No.</td>
                  <td>Subscription Name</td>
                  <td>Status</td>
                  <td>Price</td>
                  <td>User</td>
                  <td className={styles.actionCol}>Action</td>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {!isLoading ? (
                  <>
                    {subscriptionList?.items?.map(
                      (value: any, index: number) => {
                        return (
                          <tr>
                            <td> {page + (index + 1)} </td>
                            <td>
                              <span onClick={(e) => e.stopPropagation()}>
                                <Link
                                  onClick={() => fetchData()}
                                  variant="body2"
                                  color="#0038BA"
                                  underline="hover"
                                  sx={{
                                    ...tableStyles.tableData,
                                    cursor: "pointer",
                                    width: "max-content",
                                    "&:hover": { color: "#004EFF" },
                                  }}
                                >
                                  {value?.subscriptionName}
                                </Link>
                              </span>
                            </td>
                            <td>
                              {value?.subscriptionPlanStatusDto === 0 ? (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Active />
                                  <Typography>Active</Typography>
                                </Stack>
                              ) : (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Due />
                                  <Typography>Due</Typography>
                                </Stack>
                              )}
                            </td>
                            <td>{formatter.format(value?.price)}</td>
                            <td>
                              {value?.user?.firstName +
                                " " +
                                value?.user?.lastName}
                            </td>
                            <td>
                              <Stack direction="row" spacing={1}>
                                <IconButton aria-label="Edit">
                                  <Edit
                                    onClick={async () => {
                                      setEditFun(value?.id);
                                    }}
                                    className={styles.tBtn}
                                  />
                                </IconButton>
                                <IconButton aria-label="Edit">
                                  <Delete
                                    onClick={async () => {
                                      setDeleteFun(value?.id);
                                    }}
                                    className={styles.tBtn}
                                  />
                                </IconButton>
                              </Stack>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                ) : (
                  ""
                )}
              </tbody>
              <Dialog
                open={props.showNewSubscription}
                onClose={() => props.setShowNewSubscription(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={style.dialogContainer}
                onBackdropClick={() => {
                  formikSubscription.resetForm();
                  setNewStartDate(new Date());
                  setNewEndDate(
                    new Date(moment().add(1, "M").format("MM-DD-YYYY"))
                  );
                }}
              >
                <DialogTitle
                  sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
                >
                  {/* {children} */}
                  {/* {onClose ? ( */}
                  {props.editState == true ? "Edit" : "Create"} Subscription
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      props.setShowNewSubscription(false);
                      formikSubscription.resetForm();
                      setNewStartDate(new Date());
                      setNewEndDate(
                        new Date(moment().add(1, "M").format("MM-DD-YYYY"))
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {/* ) : null} */}
                </DialogTitle>

                <DialogContent sx={{ p: 2.5 }}>
                  <FormikProvider value={formikSubscription}>
                    <Box
                      sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}
                    >
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <InputLabel required>Comapny Name</InputLabel>
                          <Field name="userId">
                            {({ field, meta, form }: any) => (
                              <>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  name="color"
                                  isClearable
                                  onChange={(e) =>
                                    form.setFieldValue(field.name, e?.value)
                                  }
                                  options={mappedData}
                                  styles={styless}
                                />
                                <FormHelperText
                                  sx={{ pl: 1.5, color: "#d32f2f", mt: 0 }}
                                >
                                  {meta?.touched ? meta?.error : ""}
                                </FormHelperText>
                              </>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <InputLabel required>Subscription Name</InputLabel>
                          <Field name="subscriptionName">
                            {({ field, meta }: any) => (
                              <TextField
                                {...field}
                                required
                                id="standard-required"
                                autoComplete="off"
                                variant="standard"
                                fullWidth
                                placeholder="Subscription Name"
                                {...{ error: meta.touched && meta.error }}
                                helperText={
                                  meta.touched && meta.error && meta.error
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <InputLabel>Description</InputLabel>
                            <Field name="description">
                              {({ field, meta }: any) => (
                                <TextField
                                  {...field}
                                  id="standard-required"
                                  multiline
                                  placeholder="Add Features you want to be included in subscription plan"
                                  autoComplete="off"
                                  fullWidth
                                  variant="standard"
                                  rows={2}
                                  {...{ error: meta.touched && meta.error }}
                                  helperText={
                                    meta.touched && meta.error && meta.error
                                  }
                                />
                              )}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      mt={2.5}
                      sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}
                    >
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <InputLabel required>Billing Cycle</InputLabel>
                          <Field name="billingCycleDto">
                            {({ field, meta }: any) => (
                              <TextField
                                required
                                {...field}
                                select
                                fullWidth
                                {...{ error: meta.touched && meta.error }}
                                helperText={
                                  meta.touched && meta.error && meta.error
                                }
                                id="standard-required"
                                variant="standard"
                                placeholder="Billing Cycle"
                                sx={{
                                  ".MuiSelect-select:focus": {
                                    background: "none",
                                  },
                                }}
                              >
                                <MenuItem value="">
                                  Select Billing Cycle
                                </MenuItem>
                                <MenuItem value={30}>Billed Monthly</MenuItem>
                                <MenuItem value={360}>Billed Yearly</MenuItem>
                              </TextField>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <InputLabel required>Price</InputLabel>
                          <Field name="price">
                            {({ field, meta }: any) => (
                              <TextField
                                {...field}
                                required
                                autoComplete="off"
                                fullWidth
                                id="standard-required"
                                variant="standard"
                                placeholder="Price"
                                {...{ error: meta.touched && meta.error }}
                                helperText={
                                  meta.touched && meta.error && meta.error
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Box className={styles.datepickerWrapper}>
                                <InputLabel required>Start Date</InputLabel>
                                <Stack
                                  display="flex"
                                  sx={{
                                    py: 0.5,
                                    pl: 1,
                                    borderBottom: "1px solid #E0E0E0",
                                    flexDirection: "row",
                                  }}
                                >
                                  <DatePicker
                                    className={styles.dateUi}
                                    selected={new Date(newStartDate)}
                                    onChange={(selectedstart) =>
                                      handleSubscriptionStartDate(selectedstart)
                                    }
                                    dateFormat="MM-dd-yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={new Date(newEndDate)}
                                  />
                                  <StartDateCalendar />
                                </Stack>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Box className={styles.datepickerWrapper}>
                                <InputLabel required>End Date</InputLabel>
                                <Stack
                                  display="flex"
                                  sx={{
                                    py: 0.5,
                                    pl: 1,
                                    borderBottom: "1px solid #E0E0E0",
                                    flexDirection: "row",
                                  }}
                                >
                                  <DatePicker
                                    required
                                    className={styles.dateUi}
                                    selected={new Date(newEndDate)}
                                    onChange={(selectedstart) =>
                                      handleSubscriptionEndDate(selectedstart)
                                    }
                                    dateFormat="MM-dd-yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    minDate={new Date(newStartDate)}
                                  />
                                  <EndDateCalendar />
                                </Stack>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Stack>
                            <InputLabel required>
                              Subscription Status
                            </InputLabel>
                            <FormControlLabel
                              sx={{
                                mx: 0,
                                pl: 1.5,
                                mt: 0.5,
                                ".MuiTypography-root": {
                                  ml: 1.5,
                                  fontSize: 14,
                                  fontWeight: 300,
                                },
                              }}
                              control={<IOSSwitch defaultChecked />}
                              label={checked == true ? "Active" : "Inactive"}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormikProvider>
                </DialogContent>
                <DialogActions
                  sx={{
                    justifyContent: "center",
                    pb: 3,
                    "&>:not(:first-of-type)": { ml: 2.5 },
                  }}
                >
                  <Button
                    onClick={() => {
                      console.log("One");
                      formikSubscription.handleSubmit();
                    }}
                    sx={{
                      ...style.modalBtn,
                      background:
                        "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                      color: "#fff",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{
                      ...style.modalBtn,
                      ml: 2,
                      border: "1px solid rgba(104, 115, 125, 0.48)",
                      color: "#68737D",
                    }}
                    onClick={() => {
                      props.setShowNewSubscription(false);
                      formikSubscription.resetForm();
                      setNewStartDate(new Date());
                      setNewEndDate(
                        new Date(moment().add(1, "M").format("MM-DD-YYYY"))
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                  ...style.dialogContainer,
                  ".MuiPaper-root": { minWidth: 400 },
                }}
              >
                <DialogTitle
                  sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
                >
                  Delete Subscription
                  <IconButton
                    aria-label="close"
                    onClick={() => props.setShowNewSubscription(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {/* ) : null} */}
                </DialogTitle>

                <DialogContent sx={{ p: 2.5 }}>
                  <Box sx={{ p: 1.5 }}>
                    <Typography variant="h6">
                      Are you sure to delete ?
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{
                    justifyContent: "center",
                    pb: 3,
                    "&>:not(:first-of-type)": { ml: 2.5 },
                  }}
                >
                  <Button
                    onClick={() => {
                      handleDeleteSubscription();
                    }}
                    sx={{
                      ...style.modalBtn,
                      background:
                        "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                      color: "#fff",
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => setDeleteModal(false)}
                    sx={{
                      ...style.modalBtn,
                      ml: 2,
                      border: "1px solid rgba(104, 115, 125, 0.48)",
                      color: "#68737D",
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </table>
          </div>
        </Box>

        {openDrawer && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#E0E0E0", opacity: 1 }}
            ></Divider>
            <Box sx={{ width: "100%", maxWidth: 380, p: 2.5 }}>
              <SideDrawer setOpenDrawer={setOpenDrawer} data={[]} />
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};
export default ManageSubscriptionScreen;
